import axios from 'axios';
import React, { useState } from 'react'
import Button from './UI/Button';
import LoadingSpinner from './UI/LoadingSpinner';
import NumberPicker from './UI/NumberPicker';
import TextAreaField from './UI/TextAreaField';

function GiveFeedback({
	onCloseButtonClick,
}) {

	const [rating, setRating] = useState(null);
	const [message, setMessage] = useState('');
	const [processing, setProcessing] = useState(false);
	const [complete, setComplete] = useState(false);
	const [errors, setErrors] = useState({});

	const handleFormSubmit = e => {
		e.preventDefault();
		setProcessing(true);
		axios.post('/api/feedback', { rating, message })
			.then(response => {
				setProcessing(false);
				setComplete(true);
			})
			.catch(error => {
				setProcessing(false);
				setErrors(error.response.data.errors);
			});
	}

	if (processing) {
		return <div className="text__align--center"><LoadingSpinner center /></div>;
	}

	if (complete) {
		return <>
			<h4>Thank You!</h4>
			<p>Your feedback has been sent to our team.</p>
			<Button
				size="full"
				variant="secondary"
				onClick={onCloseButtonClick}
			>
				Return to dashboard
			</Button>
		</>
	}

	return (
		<form className="text__align--left" onSubmit={handleFormSubmit} method="POST" action="">
			<NumberPicker
				onChange={value => setRating(value)}
				name="feedback_rating"
			/>
			<div className="d-flex justify-between pt-1 mb-2">
				<p className="mb-0 sml">Not very</p>
				<p className="mb-0 sml">Extremely</p>
			</div>
			<TextAreaField
				name="message"
				placeholder="Tell us a little bit more..."
				value={message}
				onChange={(e) => setMessage(e.target.value)}
				errors={errors.message}
				inputProps={{
					style: { minHeight: '100px' }
				}}
			/>
			<Button
				arrow
				disabled={!rating}
				size="full"
			>
				Send feedback
			</Button>
		</form>
	)
}

export default GiveFeedback
