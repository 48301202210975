import React from 'react';
import percentages from '../../utils/tippingPercentages';

function TipFormEntry({
	barber,
	setDefaultTipValue,
	setDefaultTipFormat,
}) {
	return (
		<div className="tip-form">
			<p><strong>Add a tip for {barber.name}</strong></p>
			<div className="tip-form__percentages justify-start">
				{percentages.map(p => {
					return <label
						key={`tip_option_${p}`}
						className="tip-form__percentage-option"
					>
						<input 
							type="radio"
							name="tip_amount"
							id={`tip_amount_${p}`}
							value={p}
							defaultChecked={false}
							onChange={() => {
								setDefaultTipValue(p);
								setDefaultTipFormat('percentage');
							}}
							className="sr-only tip-form__percentage-input"
						/>
						<span className="tip-form__percentage-text">{p}%</span>
					</label>
				})}
			</div>
			<button 
				className="text-button"
				onClick={() => setDefaultTipFormat('custom')}
			>
				Enter a custom amount
			</button>
		</div>
	)
}

export default TipFormEntry
