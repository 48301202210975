import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import BillingSettings from './MerchantDashboard/BillingSettings';
import BookingRequests from './MerchantDashboard/BookingRequests';

import BookingsCalendar from './MerchantDashboard/BookingsCalendar';
import FrontPage from './MerchantDashboard/FrontPage';
import Services from './MerchantDashboard/Services';
import AccountSettings from './AccountSettings';
import AccountDetails from './MerchantDashboard/AccountDetails';
import Nav from './MerchantDashboard/Nav';
import StaffManager from './MerchantDashboard/StaffManager';

const MerchantDashboard = ({ baseRoute }) => {
	return <Router basename={baseRoute}>
		<MerchantDashboardInner />
	</Router>
};

const MerchantDashboardInner = () => {

	const history = useHistory();

	const [user, setUser] = useState({});
	const [userLoaded, setUserLoaded] = useState(false);
	const [upcomingBookings, setUpcomingBookings] = useState([]);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = () => {
		axios.get('/api/user')
			.then(response => {
				setUser(response.data);
				setUserLoaded(true);
			});
	}

	useEffect(() => {
		getUpcomingBookings();
	}, [])

	const getUpcomingBookings = () => {
		axios.get('/api/user/merchant/bookings/upcoming')
			.then(res => res.data.data)
			.then(data => setUpcomingBookings(data))
			.catch(err => setUpcomingBookings([]));
	}

	const goToBookingRequests = () => {
		history.push('/booking-requests');
	}

	return (
		<div className="pb-12">
			<Switch>
				<Route path="/services-manager">
					<Services user={user} />
				</Route>
				<Route path="/bookings-calendar">
					<BookingsCalendar />
				</Route>
				<Route path="/booking-requests">
					<BookingRequests bookings={upcomingBookings} getBookings={getUpcomingBookings} />
				</Route>
				<Route path="/account-details">
					<AccountDetails user={user} setUser={setUser} userLoaded={userLoaded} />
				</Route>
				<Route path="/staff">
					<StaffManager />
				</Route>
				<Route path="/billing">
					<BillingSettings />
				</Route>
				<Route path="/account-settings">
					<AccountSettings user={user} setUser={setUser} userLoaded={userLoaded} />
				</Route>
				<Route path="/">
					<FrontPage user={user} goToBookingRequests={goToBookingRequests} />
				</Route>
			</Switch>
			<Nav upcomingBookings={upcomingBookings} />
		</div>
	);
}

export default MerchantDashboard;
