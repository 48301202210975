import React from 'react'
import FormError from './UI/FormError';
import ImagePicker from './UI/ImagePicker';

function GalleryImagesPicker({
	images,
	setImages,
	errors,
	maxImages,
}) {

	const handleImageChange = (key, newImage) => {
		const newImages = [...images];
		newImages[key] = newImage;
		setImages(newImages);
	}

	return (
		<div className="field">
			<legend className="label">Upload images to your gallery</legend>
			<p className="muted-text muted-text--light">Show customers your work (max 8)</p>
			<div className="gallery-images-picker row">
				{images.map((image, i) => {
					return (
						<div className="col-xs-4" key={`gallery-images-${i}`}>
							<ImagePicker
								currentImage={image}
								containerClassName="gallery-images-picker__picker"
								inputName="gallery-images[]"
								inputId={`gallery-images-${i}`}
								onChange={newFile => handleImageChange(i, newFile)}
							/>
						</div>
					)
				})}
				{images.length < maxImages &&
					<div className="col-xs-4 vvvv">
						<ImagePicker
							key={`gallery_image_picker_new_image-${images.length}`}
							currentImage={null}
							onChange={file => setImages([...images, file])}
							containerClassName="gallery-images-picker__picker"
							inputName="gallery-images[]"
							inputId={`gallery-images-${images.length}`}
						/>
					</div>
				}
			</div>
			{errors.map((e, i) => <FormError key={`${inputName}_error_${i}`} message={e} />)}
		</div>
	)
}

GalleryImagesPicker.defaultProps = {
	errors: [],
	maxImages: 8,
}

export default GalleryImagesPicker
