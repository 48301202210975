const NavMenu =  {
    register(config) {
        try {
            const menu = document.querySelector(config.menu);
            const buttons = {
                open: document.querySelector(config.buttons.open),
                close: document.querySelector(config.buttons.close)
            };

            if (!menu) return false;

            buttons.open.onclick = () => menu.classList.add('is-open');
            buttons.close.onclick = () => menu.classList.remove('is-open');
            menu.addEventListener('click', (e) => {
                if (e.target.classList.contains(config.menu.substr(1)) || e.target.tagName === 'A') {
                    menu.classList.remove('is-open');
                }
            });
        } catch (error) {
            throw 'Configuration object is invalid!'
        }
    }
};

export default NavMenu;