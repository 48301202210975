import axios from 'axios'
import React, { useState } from 'react'
import googleMapsUrl from '../../utils/googleMapsUrl'
import timestampToDate from '../../utils/timestampToDate'
import CrossIcon from '../Icons/Cross'
import GuestIcon from '../Icons/Guest'
import PhoneIcon from '../Icons/Phone'
import TickIcon from '../Icons/Tick'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import CancelBooking from './CancelBooking'
import alert from '../../utils/alert';

function BookingDetails({
	booking,
	getBookings,
}) {

	const [cancelFormOpen, setCancelFormOpen] = useState(false);

	const startTime = timestampToDate(booking.start_time);
	const endTime = timestampToDate(booking.end_time);
	
	const updateStatus = (status, reason = '') => {
		axios.patch(`/api/user/merchant/bookings/${booking.id}/${status}`, { reason })
			.then(getBookings)
			.catch(error => {
				alert(error.response.data.errors.reason[0]);
			});
	};

	return (
		<div className="single-booking-manager">
			<table className="single-booking-manager__details-table">
				<tbody>
					<tr>
						<th scope="row">Services</th>
						<td>{booking.services.map(s => <div key={s.id}>{s.name} x {s.quantity}</div>)}</td>
					</tr>
					<tr>
						<th scope="row">No of Guests</th>
						<td>
							{[...Array(booking.total_guests)].map((x, i) => <GuestIcon key={i} className="mr-1" />)} 
							{booking.total_guests}
						</td>
					</tr>
					<tr>
						<th scope="row">Customer name</th>
						<td>{booking.customer_first_name} {booking.customer_last_name}</td>
					</tr>
				</tbody>
			</table>
			<div className="mt-3">
				<Button
					element="a"
					target="_blank"
					size="full"
					className="mb-2"
					href={`tel:${booking.customer_phone.replace(/\s+/g, '')}`}
				>
					Contact customer
					<PhoneIcon className="single-booking-manager__action-icon" />
				</Button>
				<Modal
					isOpen={cancelFormOpen}
					title="Reason for cancelling this booking."
					subtitle="Choose a reason for cancelling and we will notify the customer."
					onClick={() => setCancelFormOpen(false)}
					content={<CancelBooking
						updateStatus={updateStatus}
					/>}
				>
					<Button
						onClick={() => setCancelFormOpen(true)}
						variant="error"
						size="full"
					>
						Cancel this booking
						<CrossIcon className="single-booking-manager__action-icon" />
					</Button>
				</Modal>
			</div>
		</div>
	)
}

export default BookingDetails
