import React from 'react'
import SetLocationForm from './SetLocationForm'
import Button from './UI/Button'

const SetUserLocation = () => {
	return (
		<SetLocationForm
			formFooter={showFields => {
				return <>
					{showFields && <Button
						size="full"
						type="submit"
						className="mb-2"
					>
						Confirm
					</Button>}
				</>
			}}
		/>
	)
}

export default SetUserLocation
