import React from 'react'
import useVariableInputProps from '../Hooks/useVariableInputProps';
import Field from './Field';
import FormError from './FormError';
import Icon from './Icon';

function TextAreaField({
	name,
	id,
	label,
	value,
	defaultValue,
	onChange,
	placeholder,
	required,
	hideLabel,
	helperText,
	inputProps,
	errors,
	rightIcon,
	noFixedHeight,
	characterLimit,
}) {
	const variableInputProps = useVariableInputProps(onChange, value, defaultValue, characterLimit);

	const classes = ['text-input', 'text-input--textarea'];

	if (errors.length) {
		classes.push('text-input--error');
	}
	
	if (rightIcon) {
		classes.push('text-input--has-icon-right');
	}

	if (inputProps && inputProps.onClick) {
		classes.push('text-input--clickable');
	}

	if (noFixedHeight) {
		classes.push('text-input--no-fixed-height');
	}

	return (
		<Field
			name={name}
			label={label}
			hideLabel={hideLabel}
			helperText={helperText}
			characterLimit={characterLimit}
			{...variableInputProps}
		>
			<div className="field__input-wrap">
				<textarea
					className={classes.join(' ')}
					name={name}
					id={id || name}
					placeholder={placeholder}
					required={required}
					{...inputProps}
					{...variableInputProps}
				/>
				{rightIcon && <Icon name={rightIcon} className="field__icon" />}
			</div>
			{errors.map((e, i) => <FormError key={`${name}_error_${i}`} message={e} />)}
		</Field>
	)
}

TextAreaField.defaultProps = {
	errors: [],
}

export default TextAreaField
