import React from 'react'
import Button from '../UI/Button'
import TextField from '../UI/TextField'

function CreateBookingForm({
	onSubmit,
	isProcessing,
	errors,
}) {
	return (
		<form onSubmit={onSubmit}>
			<TextField
				name="customer_first_name"
				type="text"
				label="Client First Name"
				placeholder="First name"
				required
				errors={errors.customer_first_name}
			/>
			<TextField
				name="customer_last_name"
				type="text"
				label="Client Last Name"
				placeholder="Last name"
				required
				errors={errors.customer_last_name}
			/>
			<TextField
				name="customer_email"
				type="email"
				label="Client Email"
				placeholder="Email address"
				errors={errors.customer_email}
			/>
			<TextField
				name="customer_phone"
				type="tel"
				label="Client Phone"
				placeholder="01234 567 890"
				errors={errors.customer_phone}
			/>
			<TextField
				name="start_time"
				type="datetime-local"
				label="Date / Time"
				required
				errors={errors.start_time}
			/>
			<TextField
				name="total_guests"
				type="number"
				label="Number of Guests"
				defaultValue="1"
				placeholder="1"
				inputProps={{
					min: 1,
					max: 5,
					step: 1,
				}}
				required
				errors={errors.total_guests}
			/>
			<TextField
				name="total_duration"
				type="number"
				label="Duration (mins)"
				placeholder="15m"
				inputProps={{
					min: 1,
					step: 1,
				}}
				required
				errors={errors.total_duration}
			/>
			<TextField
				name="grand_total"
				type="number"
				label="Total Price (£)"
				placeholder="20.00"
				inputProps={{
					min: 0,
					step: 0.01,
				}}
				required
				errors={errors.grand_total}
			/>
			<Button type="submit" size="full" disabled={isProcessing}>Add Booking</Button>
		</form>
	)
}

export default CreateBookingForm
