import NavMenu from './lib/nav-menu';
import ReactMounter from './lib/react-mounter';

/**
 * The heart of the theme's scripts.
 * 
 * This script enqueues and dequeues all javascript
 * behaviour, event listeners and functionality using
 * Barbashop as the transition manager.
 */

ReactMounter.mount();
   
NavMenu.register({
  buttons: {
    open: '.site-header__nav-trigger',
    close: '.primary-nav .overlay-nav__close-button'
  },
  menu: '.primary-nav'
});

if (document.body.dataset.auth > 0) {
  NavMenu.register({
    buttons: {
      open: '#accountButton',
      close: '.account-nav .overlay-nav__close-button'
    },
    menu: '.account-nav'
  });
}
