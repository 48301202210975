import React from 'react'

function Avatar({
	src,
	alt,
	className,
}) {
	const classes = ['bg', 'avatar', className].join(' ');

	return (
		<div className={classes}>
			<img src={src} alt={alt} className="bg__image" />
		</div>
	)
}

Avatar.defaultProps = {
	alt: '',
}

export default Avatar
