import React from 'react'
import InstagramIcon from './Icons/Instagram'
import Avatar from './UI/Avatar'
import BarberAvatar from './UI/BarberAvatar'

function BarberDetails({
	barber,
}) {
	return (
		<div className="section container">
			<header className="d-xs-flex justify-xs-between items-xs-center mb-2">
				<h5 className="mb-0">{barber.name}</h5>
				<BarberAvatar barber={barber} />
			</header>
			<h6 className="mb-1">Bio</h6>
			<p className="mb-3 muted-text">{barber.bio}</p>
			<hr className="mb-3" />
			<div className="barber-available-services mb-3">
				{barber.services.map(s => (
					<div key={s.id} className="barber-available-services__service">{s.name}</div>
				))}
			</div>
			{barber.instagram && <>
				<p className="mb-2">Social</p>
				<div className="barber-social-icons">
					<a 
						href={`https://www.instagram.com/${barber.instagram}`} 
						target="_blank"
						rel="noopener"
						className="barber-social-icons__link"
					>
						<span className="sr-only">Instagram</span>
						<InstagramIcon />
					</a>
				</div>
			</>}
		</div>
	)
}

export default BarberDetails
