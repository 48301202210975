import React from 'react'

function ReloadIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M4.95737 1.60005H0.799988V0.800049H6.39999V6.40005H5.59999V2.09802L3.75 3.7623C1.4142 6.10604 1.41665 9.89954 3.75735 12.2402C6.10049 14.5834 9.89948 14.5834 12.2426 12.2402C14.5858 9.89708 14.5858 6.09809 12.2426 3.75495L12.8083 3.18926C15.4639 5.84483 15.4639 10.1503 12.8083 12.8059C10.1527 15.4615 5.84723 15.4615 3.19166 12.8059C0.536097 10.1504 0.536097 5.84483 3.19166 3.18926L3.19913 3.18179L4.95737 1.60005Z" />
		</svg>
	)
}

export default ReloadIcon
