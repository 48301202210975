import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';

import BookingsByMonth from './BookingsByMonth';
import CreateBookingForm from './CreateBookingForm';
import BackButton from '../UI/BackButton';
import { format, isSameMonth } from 'date-fns';
import usePrevious from '../Hooks/usePrevious';
import Modal from '../UI/Modal';
import FAB from '../UI/FAB';
import alert from '../../utils/alert';

const defaultAvailability = {
	monday: false,
	tuesday: false,
	wednesday: false,
	thursday: false,
	friday: false,
	saturday: false,
	sunday: false
};

function BookingsCalendar() {
	const history = useHistory();

	const [bookings, setBookings] = useState([]);
	const [availability, setAvailability] = useState(defaultAvailability);

	const [currentDate, setCurrentDate] = useState(new Date());
	const [viewMode, setViewMode] = useState('monthly');
	const [showNewBookingForm, setShowNewBookingForm] = useState(false);
	const [newBookingFormProcessing, setNewBookingFormProcessing] = useState(false);
	const [newBookingFormErrors, setNewBookingFormErrors] = useState({});

	const previousDate = usePrevious(currentDate);

	useEffect(() => {
		axios.get('/api/user/merchant')
			.then(res => res.data)
			.then(data => setAvailability(data.availability || {}))
			.catch(err => setAvailability(defaultAvailability));
	}, []);

	useEffect(() => {
		if (!isSameMonth(currentDate, previousDate)) {
			getBookings();
		}
	}, [currentDate]);

	const updateBooking = (id, data) => {
		const _bookings = [...bookings];
		const index = _bookings.findIndex(booking => booking.id === id);
		_bookings[index].status = data.status;
		setBookings(_bookings);
	};

	const getBookings = () => {
		const params = new URLSearchParams();
		params.append('month', format(currentDate, 'yyyy-MM'));
		axios.get('/api/user/merchant/bookings', { params })
			.then(res => res.data.data)
			.then(data => setBookings(data))
			.catch(err => setBookings([]));
	}

	const confirmedBookings = bookings.filter(b => b.status === BOOKING_STATUS.confirmed);

	return <>
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className="section">
			<div className="container">
				<h1>Booking Calendar</h1>
			</div>
			<BookingsByMonth
				availability={availability}
				bookings={confirmedBookings}
				date={currentDate}
				setCurrentDate={setCurrentDate}
				updateBooking={updateBooking}
				getBookings={getBookings}
			/>
		</section>
	</>;
}

export default BookingsCalendar;
