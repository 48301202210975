import React from 'react'
import Button from './UI/Button'

function ActionBar({
	title,
	subTitle,
	buttonText,
	onClick,
	disabled,
}) {
	return (
		<div className="action-bar">
			<div className="container action-bar__container">
				<div className="action-bar__total">
					{title}<br />
					<small>{subTitle}</small>
				</div>
				<Button variant="ghost" onClick={onClick} arrow disabled={disabled}>
					{buttonText}
				</Button>
			</div>
		</div>
	)
}

export default ActionBar
