import React from 'react'
import CrossIcon from '../Icons/Cross'

function Modal({
	children,
	isOpen,
	title,
	subtitle,
	content,
	onClick,
	removePadding,
	className,
}) {

	const handleInnerClick = e => {
		e.stopPropagation();
	}

	const classes = ['modal', className].filter(Boolean);

	return (
		<>
			{children}
			{isOpen &&
				<div className={classes.join(' ')} onClick={onClick}>
					<div className="modal__container">
						<div className="modal__inner" onClick={handleInnerClick}>
							<header className="modal__header">
								<button className="icon-button modal__close-button" onClick={onClick}>
									<CrossIcon className="icon-button__icon" />
								</button>
								<div>
									<h4 className={!subtitle ? 'mb-0' : ''}>{title}</h4>
									{subtitle && <p><small>{subtitle}</small></p>}
								</div>
							</header>
							<div className={`modal__content ${removePadding ? 'p-0' : ''}`}>
								{content}
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default Modal
