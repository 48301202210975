import React from 'react'
import GalleryImagesPicker from '../GalleryImagesPicker';
import ProfilePicturePicker from '../ProfilePicturePicker';
import Button from '../UI/Button';

function EditBarberImages({
	user,
	profilePicture,
	setProfilePicture,
	barberAvatar,
	setBarberAvatar,
	errors,
	galleryImages,
	setGalleryImages,
	handleBarberAvatarFormSubmit,
	handleProfilePicFormSubmit,
	handleGalleryPicsFormSubmit,
	removeGalleryImage,
}) {

	const maxImages = 8 - user.barber.media.filter(m => m.collection_name === 'gallery').length;

	return <>
		<form onSubmit={handleProfilePicFormSubmit}>
			<h3>Update Profile Picture</h3>
			<ProfilePicturePicker
				currentImage={profilePicture} 
				setCurrentImage={(file) => setProfilePicture(file)}
				errors={errors.profile_picture}
				required={true}
				icon="pencil"
			/>
			<Button
				element="button"
				type="submit"
				variant="secondary"
			>Upload</Button>
		</form>
		<hr className="my-6" />
		<form onSubmit={handleBarberAvatarFormSubmit}>
			<h3>Update Barber Avatar</h3>
			<ProfilePicturePicker
				currentImage={barberAvatar} 
				setCurrentImage={(file) => setBarberAvatar(file)}
				errors={errors.barber_avatar}
				required={true}
				title="Pick your barber avatar"
			/>
			<Button
				element="button"
				type="submit"
				variant="secondary"
			>Upload</Button>
		</form>
		<hr className="my-6" />
		<form onSubmit={handleGalleryPicsFormSubmit}>
			<h3>Update Gallery Images</h3>
			<GalleryImagesPicker 
				images={galleryImages}
				setImages={setGalleryImages}
				errors={errors.gallery_images}
				maxImages={maxImages}
			/>
			<Button
				element="button"
				type="submit"
				variant="secondary"
			>Upload</Button>
		</form>
		<hr className="my-6" />
		<h3>Remove Gallery Images</h3>
		{user.barber.media.filter(m => m.collection_name === 'gallery').map(i => {
			return <div className="u-mb-6" key={i.uuid}>
				<div className="gallery-manager-row">
					<div className="gallery-manager-row__image bg">
						<img src={i.original_url} className="bg__image" />
					</div>
					<Button 
						variant="error"
						size="small"
						onClick={e => removeGalleryImage(e, i)}
					>Remove</Button>
				</div>
			</div>;
		})}
	</>
}

export default EditBarberImages;