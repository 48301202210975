import React from 'react'
import Accordion from './UI/Accordion'

function MerchantFAQs() {

	const items = [
		{
			title: "How do payments work?",
			content: <p>Barbashop only accepts online payments including Apple pay and Google pay.</p>
		},
		{
			title: "Can I tip my Barber?",
			content: <p>Barbashop allows tips after bookings and barbers keep 100%.</p>
		},
		{
			title: "What if the booking goes wrong?",
			content: <p>Barbashop holds funds to make sure disputes are resolved fairly.</p>
		},
		{
			title: "How do I change my booking?",
			content: <p>You can easily cancel your booking 48 hours in advance, in your account. Then book another time slot that works for you.</p>
		},
		{
			title: "How do I change or cancel by Barba+?",
			content: <p>Email info@barba.co.uk with your login email and requirements and we will amend within 24 hours.</p>
		},

	];

	return (
		<Accordion items={items} variant="faqs" />
	)
}

export default MerchantFAQs
