import React from 'react'

function ChevronRightIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M8.82424 4.37573L16.4485 12L8.82424 19.6243L7.97571 18.7757L14.7514 12L7.97571 5.22426L8.82424 4.37573Z"/>
		</svg>
	)
}

export default ChevronRightIcon
