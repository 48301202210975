import React from 'react'

function PhoneIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M2.55657 2.53665C1.94821 3.20424 1.39995 4.32166 1.39995 6.20001C1.39995 10.4343 4.59746 13.7005 6.44848 15.5515C8.2995 17.4025 11.5656 20.6 15.8 20.6C17.6783 20.6 18.7957 20.0518 19.4633 19.4434C20.0969 18.866 20.4157 18.1517 20.5388 17.5541L17.1126 14.9845L14.8601 17.2369C14.1348 17.9622 12.977 18.1758 12.0268 17.6021C11.0752 17.0276 9.41509 15.9122 7.75142 14.2485C6.08776 12.5849 4.97236 10.9248 4.39786 9.9732C3.82414 9.02293 4.03778 7.86512 4.76301 7.13989L7.0155 4.8874L4.44582 1.46116C3.84824 1.58427 3.13391 1.9031 2.55657 2.53665ZM4.40345 0.249395C2.71787 0.517027 0.199951 1.94219 0.199951 6.20001C0.199951 11 3.79995 14.6 5.59995 16.4C7.39995 18.2 11 21.8 15.8 21.8C20.0578 21.8 21.4829 19.2821 21.7506 17.5965C21.8098 17.2235 21.6221 16.8666 21.32 16.64L17.8326 14.0245C17.3548 13.6662 16.6863 13.7137 16.264 14.1359L14.0115 16.3884C13.6463 16.7537 13.0892 16.8418 12.647 16.5748C11.7503 16.0335 10.1751 14.9752 8.59995 13.4C7.02476 11.8248 5.9665 10.2496 5.42515 9.35298C5.15819 8.91081 5.2463 8.35365 5.61154 7.98842L7.86403 5.73593C8.2863 5.31366 8.33381 4.64515 7.9755 4.1674L5.35995 0.680005C5.13333 0.377838 4.77648 0.190166 4.40345 0.249395Z"/>
		</svg>
	)
}

export default PhoneIcon