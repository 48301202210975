import React from 'react';
import Skeleton from 'react-loading-skeleton';
import AccountActions from '../AccountActions';
import Button from '../UI/Button';
import AccountLinks from './AccountLinks';

const FrontPage = ({
	user,
	goToBookingRequests,
}) => {
	return <>
		<section className="section">
			<div className="container mb-4">
				<h1 className="h3 mb-1">{user ? `Welcome ${user.first_name}` : <Skeleton width={192} />}</h1>
				<p className="sml">Manage your account and with bookings.</p>
			</div>
			{user.barber && user.open_bookings_count > 0 &&
				<div className="booking-requests-banner mb-2">
					<div className="container">
						<p>You have:</p>
						<p className="booking-requests-banner__text">
							<span className="booking-count-badge booking-requests-banner__badge">{user.open_bookings_count}</span> 
							<strong>{user.open_bookings_count} new booking requests to accept 🎉</strong>
						</p>
						<Button 
							size="full"
							onClick={goToBookingRequests}
						>
							Manage requests
						</Button>
					</div>
				</div>
			}
			<div className="container">
				<AccountLinks />
			</div>
		</section>
		<section className="section pt-0">
			<div className="container">
				<AccountActions />
			</div>
		</section>
	</>
};

export default FrontPage;
