import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import ActionBar from '../ActionBar';
import TextField from '../UI/TextField';

function Step1({ registerToken, setName, setStep }) {
	const history = useHistory();

	const [businessName, setBusinessName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [inviteCode, setInviteCode] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [errors, setErrors] = useState({});

	const handleFormSubmit = e => {
		e.preventDefault();

		const payload = new FormData();

		payload.append('business_name', businessName);
		payload.append('first_name', firstName);
		payload.append('last_name', lastName);
		payload.append('email', email);
		payload.append('phone', phone);
		payload.append('invite_code', inviteCode);
		payload.append('password', password);
		payload.append('password_confirmation', passwordConfirm);

		axios.post('/api/barbers/register', payload)
			.then(res => {
				const { token } = res.data;

				registerToken(token);
				setName(firstName);
				setStep(2);
				history.push('/your-address');
			})
			.catch(error => {
				setErrors(error.response.data.errors);
			});
	};

	return (
		<div className="pb-12">
			<h1>About you</h1>
			<p>We can't wait to have you on board</p>
			<form onSubmit={handleFormSubmit} method="POST" action="">
				<TextField
					name="business_name"
					label="Shop Name"
					value={businessName}
					onChange={(e) => setBusinessName(e.target.value)}
					placeholder="John Doe Barbers"
					required
					errors={errors?.business_name}
				/>
				<div className="row">
					<div className="col-xs-6">
						<TextField
							name="first_name"
							label="First Name"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							placeholder="First name"
							required
							errors={errors?.first_name}
						/>
					</div>
					<div className="col-xs-6">
						<TextField
							name="last_name"
							label="Last Name"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							placeholder="Last name"
							required
							errors={errors?.last_name}
						/>
					</div>
				</div>
				<TextField
					name="email"
					type="email"
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Email Address"
					required
					errors={errors?.email}
				/>
				<TextField
					name="contact_number"
					type="tel"
					label="Contact Number"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					placeholder="07123 456 789"
					required
					errors={errors?.phone}
				/>
				<TextField
					name="invite_code"
					label="Invite Code"
					value={inviteCode}
					onChange={(e) => setInviteCode(e.target.value)}
					helperText="If you have a referral code, enter it here"
					errors={errors?.invite_code}
				/>
				<hr className="mt-3 mb-2"/>
				<TextField
					name="password"
					type="password"
					label="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="*****"
					required
					errors={errors?.password}
					inputProps={{
						autoComplete: 'new-password'
					}}
				/>
				<TextField
					name="password_confirm"
					type="password"
					label="Confirm Password"
					value={passwordConfirm}
					onChange={(e) => setPasswordConfirm(e.target.value)}
					placeholder="*****"
					required
					inputProps={{
						autoComplete: 'new-password'
					}}
				/>
				<ActionBar
					title="Step 1 of 6"
					subTitle="Create your account"
					buttonText="Next"
				/>
			</form>
		</div>
	);
}

export default Step1;
