import axios from 'axios';
import React, { useState } from 'react'
import BarberAvatar from '../UI/BarberAvatar'
import Button from '../UI/Button';
import TextAreaField from '../UI/TextAreaField'
import ratings from '../../utils/reviewRatings';

function ReviewForm({
	booking,
	afterSubmit,
}) {
	const [rating, setRating] = useState(0);
	const [message, setMessage] = useState('');
	const [processing, setProcessing] = useState(false);
	const [errors, setErrors] = useState({});
	
	const handleFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);
		const booking_id = booking.id;
		const data = { rating, message, booking_id };
		axios.post(`/api/barbers/${booking.barber.slug}/review`, data)
			.then(response => {
				afterSubmit();
			})
			.catch(error => {
				setProcessing(false)
				setErrors(error.response.data.errors);
			});
	}

	return (
		<form className="review-form" onSubmit={handleFormSubmit}>
			<header className="sheet-content__header">
				<BarberAvatar barber={booking.barber} className="sheet-content__avatar" />
				<h2 className="sheet-content__title">How was {booking.barber.name}?</h2>
			</header>
			<div className="review-form__content">
				<div className="review-form__star-rating">
					{ratings.map((r, i) => {
						const value = i + 1;
						const isFilled = rating >= value;
						return <label 
							key={`star_rating_${value}`} 
							htmlFor={`star_rating_${value}`} 
							className="review-form__star-rating-option"
						>
							<input 
								type="radio" 
								name="star_rating" 
								id={`star_rating_${value}`} 
								className="sr-only" 
								onChange={e => setRating(value)}
							/>
							<div className={`review-form__star-rating-star ${isFilled ? 'review-form__star-rating-star--filled' : '' }`}>
								{isFilled ? '★' : '☆'}
							</div>
							<div className="review-form__star-rating-text">{r}</div>
						</label>
					})}
				</div>
				<TextAreaField
					name="message"
					label="Add comment"
					placeholder="Share your feedback"
					value={message}
					required
					onChange={(e) => setMessage(e.target.value)}
					errors={errors.message}
					characterLimit="600"
					inputProps={{
						style: { minHeight: '100px' }
					}}
				/>
				<Button
					size="full"
					type="submit"
					disabled={!rating}
				>
					Continue
				</Button>
			</div>
		</form>
	)
}

export default ReviewForm
