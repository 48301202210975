import { isNull, isString } from 'lodash';
import React, { useState } from 'react'
import FormError from './FormError';
import Icon from './Icon';

function ImagePicker({
	currentImage,
	onChange,
	containerClassName,
	imageContainerClassName,
	inputName,
	inputId,
	rounded,
	errors,
	required,
	icon,
}) {
	const [src, setSrc] = useState(() => {
		if (isString(currentImage) || isNull(currentImage)) {
			return currentImage;
		}

		if (typeof currentImage === 'object') {
			return URL.createObjectURL(currentImage);
		}

		return '';
	});
	
	const handleImageChange = function(e) {
		setSrc(URL.createObjectURL(e.target.files[0]));
		onChange(e.target.files[0]);
	};

	const handleImageLoad = e => {
		URL.revokeObjectURL(e.src);
	}

	const isEmptyClass = src ? null : 'is-empty';
	const roundedClass = rounded ? 'image-picker--rounded' : '';
	const containerClass = ['image-picker', roundedClass, containerClassName, isEmptyClass].join(' ');
	const imageContainerClass = ['bg', 'image-picker__image-container', imageContainerClassName].join(' ');

	return (
		<label className={containerClass}>
			<input 
				type="file" 
				name={inputName} 
				id={inputId} 
				className="sr-only" 
				onChange={handleImageChange} 
				accept="image/*" 
				required={required || false}
			/>
			<div className={imageContainerClass}>
				{src && <img src={src} className="bg__image" alt="" onLoad={handleImageLoad} />}
			</div>
			{icon && <div className="image-picker__icon-overlay">
				<Icon name={icon} className="image-picker__icon" />
			</div>}
			{errors.map((e, i) => <FormError key={`${inputName}_error_${i}`} message={e} />)}
		</label>
	)
}

ImagePicker.defaultProps = {
	errors: [],
}

export default ImagePicker
