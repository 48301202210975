import React, { useState } from 'react'
import BarberListing from '../BarberListing';
import SearchIcon from '../Icons/Search';
import Button from '../UI/Button';
import Modal from '../UI/Modal';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import RequestAreaNotifications from '../RequestAreaNotifications';
import BarberStaffList from './BarberStaffList';

function BarberSearchResults({
	barbers,
	barberId,
	setBarberId,
	setStaffId,
	loading,
	sortingOpen,
}) {
	const history = useHistory();

	const [areaNotificationsModalOpen, setAreaNotificationsModalOpen] = useState(false);

	return <div className="barber-search-results-container pb-6">
		{sortingOpen && <div className="barber-search-results-container__overlay"></div> }
		{loading ? <ul className="barber-search-results">
			{[...new Array(6)].map((x, i) => 
				<li className="barber-search-results__item" key={i}>
					<div className="container">
						<div className="barber-search-results__button barber-listing">
							<Skeleton width={124} height={124} className="mr-2" />
							<div className="barber-listing__content">
								<Skeleton width={48} className="mb-1" />
								<h4 className="barber-listing__name">{<Skeleton width={128} />}</h4>
								<p className="barber-listing__services">{<Skeleton width={192} />}</p>
								<Skeleton height={16} width={48} />
							</div>
						</div>
					</div>
				</li>
			)}
		</ul> : <ul className="barber-search-results">
			{barbers.map(b => <li className="barber-search-results__item" key={b.id}>
				<div className="container">
					<Modal
						isOpen={barberId === b.slug}
						title="Choose a barber"
						removePadding
						className="barber-search-staff-modal"
						content={<BarberStaffList 
							staff={b.staff} 
							barberId={barberId}
							setStaffId={setStaffId}
						/>}
						onClick={() => {
							setBarberId(null);
							history.push(`/search/`);
						}}
					>
						<BarberListing barber={b} onClick={() => {
							setBarberId(b.slug);
							history.push(`/barbers/${b.slug}`)
						}} />
					</Modal>
				</div>
			</li>)}
			{barbers.length === 0 && 
				<div className="container">
					<div className="section search-no-results">
						<div className="search-no-results__icon-holder">
							<SearchIcon />
						</div>
						<h3>No results found</h3>
						<p className="muted-text muted-text--light">Please try another search.</p>
					</div>
				</div>
			}
		</ul>}
	</div>
}

export default BarberSearchResults
