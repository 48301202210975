import React from 'react'
import useVariableInputProps from '../Hooks/useVariableInputProps';
import Field from './Field';
import FormError from './FormError';
import Icon from './Icon';

function TextField({
	name,
	id,
	type,
	label,
	value,
	defaultValue,
	onChange,
	placeholder,
	required,
	hideLabel,
	helperText,
	inputProps,
	errors,
	rightIcon,
	prefix,
	buttonRight,
}) {
	const variableInputProps = useVariableInputProps(onChange, value, defaultValue);

	const classes = ['text-input'];

	if (errors.length) {
		classes.push('text-input--error');
	}
	
	if (rightIcon) {
		classes.push('text-input--has-icon-right');
	}
	
	if (prefix) {
		classes.push('text-input--has-prefix');
	}

	if (inputProps && inputProps.onClick) {
		classes.push('text-input--clickable');
	}

	return (
		<Field
			name={name}
			label={label}
			hideLabel={hideLabel}
			helperText={helperText}
		>
			<div className="field__input-wrap">
				{prefix && <span className="field__prefix">{prefix}</span>}
				<input 
					type={type} 
					className={classes.join(' ')}
					name={name} 
					id={id || name}
					placeholder={placeholder} 
					required={required}
					{...inputProps}
					{...variableInputProps}
				/>
				{rightIcon && <Icon name={rightIcon} className="field__icon" />}
				{buttonRight}
			</div>
			{errors.map((e, i) => <FormError key={`${name}_error_${i}`} message={e} />)}
		</Field>
	)
}

TextField.defaultProps = {
	type: 'text',
	errors: [],
}

export default TextField
