import React from 'react'
import PendingBooking from './PendingBooking';

function PendingBookings({
	bookings,
	getBookings,
}) {
	return <>
		<h2 className="h4 d-flex">Pending <span className="booking-count-badge ml-1">{bookings.length}</span></h2>
		<ul className="p-0">
			{bookings.map(b => <PendingBooking 
				key={`pending_booking_${b.id}`}
				booking={b} 
				getBookings={getBookings}
			/>)}
		</ul>
	</>
}

export default PendingBookings
