import React from 'react'

function UserIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="22" height="23" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M11 8.4C12.9882 8.4 14.6 6.78822 14.6 4.8C14.6 2.81177 12.9882 1.2 11 1.2C9.01173 1.2 7.39995 2.81177 7.39995 4.8C7.39995 6.78822 9.01173 8.4 11 8.4ZM14.5999 15.6L7.39995 15.6C4.08626 15.6 1.39997 18.2863 1.39996 21.6L20.6 21.6C20.6 18.2863 17.9137 15.6 14.5999 15.6ZM11 9.6C13.6509 9.6 15.8 7.45097 15.8 4.8C15.8 2.14903 13.6509 0 11 0C8.34898 0 6.19995 2.14903 6.19995 4.8C6.19995 7.45097 8.34898 9.6 11 9.6ZM7.39995 14.4C3.42352 14.4 0.199977 17.6235 0.199957 21.6L0.199951 22.8H21.8V21.6C21.8 17.6236 18.5764 14.4 14.5999 14.4L7.39995 14.4Z"/>
		</svg>
	)
}

export default UserIcon
