import React, { useState } from 'react'
import { format, differenceInMinutes } from 'date-fns';
import BookingDetails from './BookingDetails';
import Modal from '../UI/Modal';
import timestampToDate from '../../utils/timestampToDate';
import formatDate from '../../utils/formatDate';

function CalendarBookingCard({
	booking,
	updateBooking,
	startHour,
	minutesToPixelsRatio,
	hourWidth,
	bookingCardOffset,
	index,
	getBookings,
}) {
	const [bookingDetailsModalOpen, setBookingDetailsModalOpen] = useState(false);

	const startTime = timestampToDate(booking.start_time);
	const endTime = timestampToDate(booking.end_time);
	const top = differenceInMinutes(startTime, startHour) * minutesToPixelsRatio;
	const minHeight = differenceInMinutes(endTime, startTime) * minutesToPixelsRatio;
	const width = `calc(100% - ${hourWidth}px - ${bookingCardOffset * 2}px)`;
	const zIndex = index;
	const left = hourWidth + bookingCardOffset;

	return (
		<Modal
			isOpen={bookingDetailsModalOpen}
			title={<>Booking Details:<br />{booking.customer_first_name} {booking.customer_last_name}</>}
			subtitle={formatDate(startTime) + '-' + format(endTime, 'HH:mm')}
			onClick={() => setBookingDetailsModalOpen(false)}
			content={<BookingDetails
				booking={booking}
				updateBooking={updateBooking}
				getBookings={getBookings}
			/>}
		>
			<button 
				className="day-calendar__booking" 
				style={{ top, minHeight, width, zIndex, left }}
				onClick={() => setBookingDetailsModalOpen(true)}
			>
				<div><strong>{format(startTime, 'HH:mm')} - {format(endTime, 'HH:mm')}</strong></div>
				<div>
					<strong>{booking.customer_first_name} {booking.customer_last_name}</strong>
					<span>&nbsp;</span>
					{booking.services.map(s => s.name).join(', ')}
				</div>

			</button>
		</Modal>
	)
}

export default CalendarBookingCard
