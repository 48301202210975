import React from 'react'

function ClockIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="9" height="8" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.5 7.6C6.48823 7.6 8.1 5.98823 8.1 4C8.1 2.01177 6.48823 0.4 4.5 0.4C2.51177 0.4 0.9 2.01177 0.9 4C0.9 5.98823 2.51177 7.6 4.5 7.6ZM4.5 8C6.70914 8 8.5 6.20914 8.5 4C8.5 1.79086 6.70914 0 4.5 0C2.29086 0 0.5 1.79086 0.5 4C0.5 6.20914 2.29086 8 4.5 8Z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.1 1.8H4.5V3.71716L6.44142 5.65858L6.15858 5.94142L4.1 3.88284V1.8Z"/>
		</svg>
	)
}

export default ClockIcon
