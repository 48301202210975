import React from 'react'
import ChevronLeftIcon from './Icons/ChevronLeft'

function BookingHeaderBar({
	title,
	subTitle,
	showPaymentLogos,
	onClick,
}) {
	return (
		<header className="barber-panel-header">
			<div className="container">
				<div className="barber-panel-header__inner">
					<button className="icon-button barber-panel-header__back-button" onClick={onClick}>
						<ChevronLeftIcon className="icon-button__icon" />
						<span className="sr-only">Go back to the previous step</span>
					</button>
					<div className="barber-panel-header__text">
						<h4 className="mb-0">{title}</h4>
						{subTitle && <small>{subTitle}</small>}
						{showPaymentLogos && 
							<img 
								src="/images/payment-logos.svg" 
								alt="Pay with Maestro, Visa, Mastercard or Apple Pay" 
								className="barber-panel-header__payment-logos"
							/>
						}
					</div>
				</div>
			</div>
		</header>
	)
}

export default BookingHeaderBar
