const useVariableInputProps = (onChange, value, defaultValue, characterLimit) => {
	const variableProps = {};
	if (onChange) {
		variableProps.value = characterLimit ? value.slice(0, characterLimit) : value;
		variableProps.onChange = onChange;
	} else {
		variableProps.defaultValue = characterLimit ? defaultValue.slice(0, characterLimit) : defaultValue;
	}
	return variableProps;
}

export default useVariableInputProps