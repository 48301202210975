import React from 'react'
import { Link } from 'react-router-dom'
import Icon from './UI/Icon'

function AccountLink({
	title,
	subtitle,
	icon,
	route,
}) {
	return (
		<li className="account-links__item">
			<Link to={route} className="account-links__link">
				<div className="account-links__icon-container">
					{icon && <Icon name={icon} className="account-links__icon" />}
				</div>
				<div className="account-links__text">
					<div className="account-links__title">{title}</div>
					<div className="account-links__text">{subtitle}</div>
				</div>
			</Link>
		</li>
	)
}

export default AccountLink
