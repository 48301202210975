import React, { useEffect, useState } from 'react'
import Button from './UI/Button';
import FAB from './UI/FAB';
import Modal from './UI/Modal';
import AddNewCategoryOrService from './ServicesManager/AddNewCategoryOrService';
import CategoryForm from './ServicesManager/CategoryForm';
import Category from './ServicesManager/Category';

const defaultNewCategoryName = '';

function ServicesManager({
	categories,
	presetCategories,
	services,
	onCategoryAdd, 
	onCategoryEdit,
	onServiceAdd,
	onServiceDelete,
	onServiceEdit,
	allowCategoryUpdates,
}) {

	const [newCategoryFormOpen, setNewCategoryFormOpen] = useState(false);
	const [newServiceFormOpen, setNewServiceFormOpen] = useState(false);
	const [newCategoryOrServiceFormOpen, setNewCategoryOrServiceFormOpen] = useState(false);
	
	const [newCategoryName, setNewCategoryName] = useState(defaultNewCategoryName);

	useEffect(() => {
		if (categories.length > 0) {
			setNewCategoryFormOpen(false);
			setNewServiceFormOpen(false);
			setNewCategoryName(defaultNewCategoryName);
		}
	}, [categories]);

	useEffect(() => {
		setNewCategoryFormOpen(false);
		setNewServiceFormOpen(false);
	}, [services]);

	const handleAddCategoryButtonClick = e => {
		setNewCategoryFormOpen(true);
		setNewServiceFormOpen(false);
		setNewCategoryOrServiceFormOpen(false);
	}

	const handleAddServiceButtonClick = (e, category) => {
		setNewServiceFormOpen(true);
		setNewCategoryFormOpen(false);
		setNewCategoryOrServiceFormOpen(false);
	}

	const handleCancelNewCategoryButtonClick = e => {
		e.preventDefault();
		setNewCategoryFormOpen(false);
	}

	const handleCancelNewServiceButtonClick = e => {
		e.preventDefault();
		setNewServiceFormOpen(false);
	}

	const handleAddNewCategoryOrServiceButtonClick = e => {
		e.preventDefault();
		if (categories.length) {
			setNewCategoryOrServiceFormOpen(true);
		} else {
			setNewCategoryFormOpen(true);
		}
	}

	const handleNewCategoryFormSubmit = e => {
		e.preventDefault();
		onCategoryAdd({
			name: newCategoryName,
		});
	}

	return (
		<div className="services-manager">
			{categories.map(c => <Category 
				key={`category_${c.id}`} 
				services={services}
				categories={categories}
				category={c} 
				onCategoryEdit={onCategoryEdit}
				newServiceFormOpen={newServiceFormOpen}
				onNewServiceFormCancel={handleCancelNewServiceButtonClick}
				onAddServiceButtonClick={handleAddServiceButtonClick}
				onServiceAdd={onServiceAdd}
				onServiceDelete={onServiceDelete}
				onServiceEdit={onServiceEdit}
				allowCategoryUpdates={allowCategoryUpdates}
			/>)}
			<Modal 
				isOpen={newCategoryFormOpen}
				title="Add a category"
				subtitle="What category would you like to add."
				onClick={handleCancelNewCategoryButtonClick}
				content={<CategoryForm
					onSubmit={handleNewCategoryFormSubmit}
					onCancel={handleCancelNewCategoryButtonClick}
					categoryName={newCategoryName}
					setCategoryName={setNewCategoryName}
					presetCategories={presetCategories}
				/>}
			>
				<Button 
					className="mb-2" 
					size="full"
					variant="secondary"
					onClick={handleAddCategoryButtonClick}
				>
					Add new category +
				</Button>
			</Modal>
			<Modal 
				isOpen={newCategoryOrServiceFormOpen}
				title="Choose an option"
				subtitle="Do you want to add a category or service?"
				onClick={() => setNewCategoryOrServiceFormOpen(false)}
				removePadding
				content={<AddNewCategoryOrService
					onCategoryClick={handleAddCategoryButtonClick}
					onServiceClick={handleAddServiceButtonClick}
				/>}
			>
				<FAB onClick={handleAddNewCategoryOrServiceButtonClick} />
			</Modal>
		</div>
	)
}

export default ServicesManager
