import React from 'react'
import ArrowRightIcon from '../Icons/ArrowRight';

function Button(props) {

	const {
		className,
		variant,
		size,
		arrow,
		element,
		children,
		...otherProps
	} = props;

	const classes = ['button', ...className.split(' ')];

	if (variant) {
		classes.push(`button--${variant}`);
	}

	if (size) {
		classes.push(`button--${size}`);
	}

	const ComponentName = element === 'a' ? 'a' : 'button';

	return (
		<ComponentName className={classes.join(' ')} {...otherProps}>
			{children} {arrow && <ArrowRightIcon className="" />}
		</ComponentName>
	)
}

Button.defaultProps = {
	'className': '',
};

export default Button
