import React from 'react'
import { useHistory, useRouteMatch } from 'react-router';
import formatDate from '../../utils/formatDate'
import timestampToDate from '../../utils/timestampToDate'
import BarberRating from '../BarberRating'
import BarberAvatar from '../UI/BarberAvatar';

function UpcomingBooking({
	booking,
	setSelectedBooking,
}) {
	const history = useHistory();
	const { path, url } = useRouteMatch();

	const handleBookingClick = e => {
		e.preventDefault();
		setSelectedBooking(booking);
		history.push(`${path}/upcoming-bookings/${booking.id}`)
	}

	return (
		<button className="card pending-booking-card" onClick={handleBookingClick}>
			<div className="d-flex justify-between items-center">
				<div className="mr-4">
					<p className="mb-2"><span className={`status-badge status-badge--${booking.status_text.toLowerCase()}`}>{booking.status_text}</span></p>
					<p className="mb-1">{formatDate(timestampToDate(booking.date))}</p>
					<p className="sml">{booking.barber.name}</p>
					<p className="sml">
						<span className="pending-booking-card__meta">
							<span className="mr-1">✂️</span>
							<span>
								{booking.services.map(s => <span className="d-block" key={`${booking.id}_${s.id}`}>{s.name} x {s.quantity}</span>)}
							</span>
						</span>
						<span className="pending-booking-card__meta">
							<span className="mr-1">⏰</span>
							{booking.total_duration} mins
						</span>
					</p>
					<p className="muted-text muted-text--light">Price: £{booking.gross_price}</p>
				</div>
				<div>
					<BarberAvatar barber={booking.barber} size="large" className="mb-1" />
					<BarberRating
						rating={booking.barber.rating}
						className="text__align--center"
					/>
				</div>
			</div>
		</button>
	)
}

export default UpcomingBooking
