import React, { useEffect, useState } from 'react'
import { isSameDay, format } from 'date-fns'
import BookingsForDate from './BookingsForDate';
import Calendar from '../UI/Calendar';
import timestampToDate from '../../utils/timestampToDate';

function BookingsByMonth({
	bookings,
	date,
	setCurrentDate,
	availability, 
	updateBooking,
	getBookings,
}) {
	const [availabilityForDay, setAvailabilityForDay] = useState(null);

	useEffect(() => {
		const dayName = format(date, 'EEEE').toLowerCase();
		const day = availability[dayName];
		setAvailabilityForDay(day);
	}, [date, availability])

	const bookingsForCurrentDay = bookings.filter(b => {
		const bookingDate = timestampToDate(b.start_time);
		return isSameDay(bookingDate, date);
	});

	const bookingEvents = bookings.map(b => {
		return {
			id: b.id,
			start: timestampToDate(b.start_time),
			end: timestampToDate(b.end_time),
		}
	})

	const handleDateChange = d => {
		setCurrentDate(d.date);
	}

	const getCalendarDayClassNames = d => {
		const classes = [];
		const eventsForDay = bookingEvents.filter(e => format(e.start, 'yyyy-MM-dd') === format(d.date, 'yyyy-MM-dd'));
		if (eventsForDay.length) {
			// @TODO - logic to determine if a day is 'full' or 'part-full'
			if (eventsForDay.length > 1) {
				classes.push('is-full');
			} else {
				classes.push('is-part-full');
			}
		} else {
			classes.push('is-empty');
		}
		return classes;
	}

	const handleMonthChange = firstDayOfMonth => {
		setCurrentDate(firstDayOfMonth);
	}

	return <>
		<div className="container pb-6">
			<Calendar
				availability={availability}
				onDateClick={handleDateChange}
				dateToShow={date}
				events={bookingEvents}
				allowPreviousDates={true}
				getDayClassNames={getCalendarDayClassNames}
				onChangeMonth={handleMonthChange}
				keys={['Full', 'Part Full', 'Empty']}
			/>
		</div>
		<BookingsForDate 
			date={date}
			updateBooking={updateBooking} 
			bookings={bookingsForCurrentDay} 
			availability={availabilityForDay}
			getBookings={getBookings}
		/>
	</>
}

export default BookingsByMonth
