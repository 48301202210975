import React from 'react'

function BarberRating({
	rating,
	className
}) {

	if (!rating || rating.count == 0) {
		return <div className={`muted-text muted-text--light barber-rating ${className}`}>No reviews yet</div>
	}

	return (
		<div className={`barber-rating ${className}`}>
			<span className="barber-rating__star">★</span>
			<span className="barber-rating__value">{ rating.value }</span>
			<span className="barber-rating__count">({ rating.count })</span>
		</div>
	)
}

export default BarberRating
