import React, { useState } from 'react'
import CategoryForm from './CategoryForm';

function EditCategory({
	category,
	onCancel,
	onCategoryEdit,
	setEditFormOpen,
}) {
	const [categoryName, setCategoryName] = useState(category.name);

	const handleFormSubmit = e => {
		e.preventDefault();
		onCategoryEdit(category, {
			name: categoryName,
		});
		setEditFormOpen(false);
	}

	return (
		<div>
			<CategoryForm
				onSubmit={handleFormSubmit}
				onCancel={onCancel}
				categoryName={categoryName}
				setCategoryName={setCategoryName}
			/>
		</div>
	)
}

export default EditCategory
