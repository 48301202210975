import { format } from 'date-fns';
import React, { createRef, useState } from 'react'
import timestampToDate from '../../utils/timestampToDate';
import formatDate from '../../utils/formatDate';
import CrossIcon from '../Icons/Cross';
import Panel from '../UI/Panel'
import Button from '../UI/Button'
import axios from 'axios';
import alert from '../../utils/alert';

function InvoicePanel({
	booking,
	currentPath,
	onClose,
}) {
	const panelRef = createRef();
	const [sendingInvoice, setSendingInvoice] = useState(false);

	const sendInvoice = () => {
		if (sendingInvoice) return false;
		setSendingInvoice(true);

		axios.post(`/api/user/customer/bookings/${booking.id}/send-invoice`)
			.then(response => {
				alert('Invoice sent! Please check your email.');
				setSendingInvoice(false);
			})
			.catch(error => alert('Your invoice could not be sent. Please contact our team.'));
	}

	return (
		<Panel
			path={`${currentPath}/invoice`}
			condition={booking !== null}
			panelRef={panelRef}
			className="invoice-panel"
		>
			<div className="invoice-panel__top">
				<div className="container">
					<header>
						<div className="d-flex justify-end">
							<button className="icon-button icon-button--black" onClick={onClose}>
								<CrossIcon className="icon-button__icon" />
							</button>
						</div>
						<div className="muted-text muted-text--light">{format(timestampToDate(booking.date), 'd MMM y')}</div>
						<h3>Invoice #{booking.id}</h3>
					</header>
					<h5 className="mb-1">{booking.barber.name}</h5>
					<p className="mb-1">{booking.barber.user_name}</p>
					<p className="sml muted-text muted-text--light mb-1">{booking.barber.address.line_1}</p>
					{booking.barber.address.line_2 && <p className="sml muted-text muted-text--light mb-1">{booking.barber.address.line_2}</p>}
					<p className="sml muted-text muted-text--light mb-1">{booking.barber.address.city}</p>
					<p className="sml muted-text muted-text--light mb-1">{booking.barber.address.postcode}</p>
					<p className="sml muted-text muted-text--light mb-4">{booking.barber.phone}</p>
					<p className="muted-text muted-text--light mb-1">Billed to:</p>
					<p className="sml mb-1"><strong>{booking.customer.first_name} {booking.customer.last_name}</strong></p>
					<p className="sml">{booking.customer.email}</p>
				</div>
			</div>
			<div className="invoice-panel__middle">
				<div className="container">
					<table className="booking-pricing-table">
						<tbody>
							{booking.services.map((s, i) => {
								const isLastRow = i + 1 === booking.services.length;
								return (
									<tr key={`booking_${booking.id}_service_${s.id}`} className={`booking-pricing-table__row ${isLastRow ? 'booking-pricing-table__row--last' : ''}`}>
										<td className="booking-pricing-table__cell">
											<div>{s.name} x {s.quantity}</div>
										</td>
										<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
											<div>£{s.gross_price}</div>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="invoice-panel__bottom py-4">
				<div className="container">
					<table className="booking-pricing-table">
						<tbody>
							<tr className="booking-pricing-table__row booking-pricing-table__row--subtotal">
								<td className="booking-pricing-table__cell">Subtotal</td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									£{booking.gross_price}
								</td>
							</tr>
							<tr className="booking-pricing-table__row booking-pricing-table__row--subtotal">
								<td className="booking-pricing-table__cell">Service Fee</td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									£{booking.service_fee}
								</td>
							</tr>
							{booking.total_discount && booking.total_discount !== '0.00' &&
								<tr className="booking-pricing-table__row booking-pricing-table__row--subtotal">
									<td className="booking-pricing-table__cell">Discount</td>
									<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
										-£{booking.total_discount}
									</td>
								</tr>
							}
							<tr className="booking-pricing-table__row">
								<td className="booking-pricing-table__cell pt-0"><strong>Total</strong></td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price pt-0">
									<strong>£{booking.grand_total}</strong>
								</td>
							</tr>
							{booking.payment.deposit_date &&
								<tr className="booking-pricing-table__row">
									<td className="booking-pricing-table__cell">
										Barbashop Online ({booking.payment.brand} *{booking.payment.last4})
										<div className="muted-text muted-text--light">{formatDate(timestampToDate(booking.payment.deposit_date))}</div>
									</td>
									<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
										<strong>£{booking.payment.deposit_amount}</strong>
									</td>
								</tr>
							}
							{booking.payment.balance_paid &&
								<tr className="booking-pricing-table__row">
									<td className="booking-pricing-table__cell">
										Barbashop Online ({booking.payment.brand} *{booking.payment.last4})
										<div className="muted-text muted-text--light">{formatDate(timestampToDate(booking.payment.balance_date))}</div>
									</td>
									<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
										<strong>£{booking.payment.balance_date}</strong>
									</td>
								</tr>
							}
							<tr className="booking-pricing-table__row booking-pricing-table__row--last">
								<td className="booking-pricing-table__cell"><strong>Balance</strong></td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									<strong>£{booking.payment.remaining}</strong>
								</td>
							</tr>
						</tbody>
					</table>
					<Button
						onClick={sendInvoice}
						size="full"
						disabled={sendingInvoice}
					>
						Send me a copy
					</Button>
				</div>
			</div>
		</Panel>
	)
}

export default InvoicePanel
