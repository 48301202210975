import React from 'react'

function SortIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="36" height="39" viewBox="0 0 36 39" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M28.3579 1.81395L28.4278 32.3619L34.5916 25.8769L36 27.3306L27.449 36.3268L18.7403 27.3375L20.1353 25.8699L26.4455 32.3836L26.3757 1.81887L28.3579 1.81395ZM9.60869 4.487L18.1597 13.4833L16.7513 14.9369L10.5874 8.452L10.5176 39L8.53538 38.9951L8.6052 8.43029L2.295 14.9438L0.899994 13.4763L9.60869 4.487Z" />
		</svg>
	)
}

export default SortIcon