import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement, PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';

import BookingHeaderBar from '../BookingHeaderBar';
import Button from '../UI/Button';
import BarberAvatar from '../UI/BarberAvatar';
import LoadingSpinner from '../UI/LoadingSpinner';
import PaymentMethod from '../PaymentMethod';
import axios from 'axios';
import CrossIcon from '../Icons/Cross';
import TextField from '../UI/TextField';
import PaymentForm from '../PaymentForm';
import alert from '../../utils/alert';

function BarberBookingSummary({ 
	auth,
	barber,
	staff,
	booking,
	goToBookingAppointment,
}) {
	const storage = window.sessionStorage;

	const [cart, setCart] = useState({});
	const [currentVoucherCode, setCurrentVoucherCode] = useState('');
	const [voucherCodes, setVoucherCodes] = useState([]);
	const [paymentIntentId, setPaymentIntentId] = useState('');
	const [paymentIntentSecret, setPaymentIntentSecret] = useState('');

	const [cartLoaded, setCartLoaded] = useState(false);
	const [checkingVoucher, setCheckingVoucher] = useState(false);

	useEffect(() => {
		getCart();
	}, [booking, voucherCodes]);

	const getCart = () => {
		if (!parseInt(document.body.dataset.auth)) {
			return false;
		}

		setCartLoaded(false);

		const data = {
			services: booking.services,
			vouchers: voucherCodes,
			barber_id: barber.id,
			date: booking.date + ' ' + booking.time,
			total_guests: booking.total_guests,
		};

		if (paymentIntentId) {
			data.payment_intent_id = paymentIntentId;
		}
		
		axios.post('/api/cart', data)
			.then(response => {
				setCart(response.data.cart);
				setPaymentIntentId(response.data.payment_intent_id);
				setPaymentIntentSecret(response.data.client_secret);
				setCartLoaded(true);
			})
			.catch(error => {
				alert(error.response.data.message);
			});
	}

	const captureBookingAndLogin = () => {
		storage.setItem('booking_login_session', JSON.stringify(booking));
		window.location.href = '/login';
	};

	const handleVoucherFormSubmit = e => {
		e.preventDefault();

		const code = currentVoucherCode;

		if (voucherCodes.includes(code)) {
			alert('Voucher already used!');
			return false;
		}

		setCheckingVoucher(true);

		axios.post('/api/cart/check-voucher', { code })
			.then(response => {
				setVoucherCodes([...voucherCodes, code]);
				setCurrentVoucherCode('');
			})
			.catch(error => {
				if (error.response.status === 404) {
					alert('Please enter a valid voucher code.');
				} else {
					alert(error.response.data.errors);
				}
			})
			.finally(() => setCheckingVoucher(false));
	}

	const completeBooking = () => {
		const bookingData = {
			...booking, 
			date: booking.date + ' ' + booking.time,
			barber_id: barber.id,
			staff_id: staff.id,
			vouchers: voucherCodes,
			payment_intent_id: paymentIntentId,
		};
		axios.post(`/api/barbers/${barber.slug}/book`, bookingData)
			.then(response => {
				const booking = response.data.data;
				window.location.href = window.location.origin + '/booking-confirmation/' + booking.confirmation_token;
			})
			.catch(error => {
				alert('An error occurred while processing your booking. Please contact our team.');
			});
	}

	return <div>
		<div className="pb-12">
			<BookingHeaderBar
				title="Payment information"
				showPaymentLogos
				onClick={goToBookingAppointment}
			/>
			<div className="container">
				<section className="section">
					<div className="booking-summary">
						<BarberAvatar
							barber={staff}
							size="xl"
							className="booking-summary__avatar"
						/>
						<div className="booking-summary__content">
							<h6 className="booking-summary__staff-name">{staff.full_name}</h6>
							<div className="booking-summary__barber-name muted-text">{barber.name}</div>
							<div className="booking-summary__meta"><strong>👤 No of guests:</strong> {booking.total_guests}</div>
							<div className="booking-summary__meta"><strong>✂️ Service:</strong> {[...new Set(Object.values(booking.services).map(obj => obj.name))].join(', ')}</div>
							<div className="booking-summary__meta"><strong>📅 Date:</strong> {booking.date}</div>
							<div className="booking-summary__meta"><strong>⏰ Time:</strong> {booking.time}</div>
						</div>
					</div>
				</section>
				<section className="section pt-0">
					<table className="booking-costs">
						<tbody>
							{cartLoaded ? <>
								<tr>
									<td className="booking-costs__cell booking-costs__cell--label h4">Pay today:</td>
									<td className="booking-costs__cell booking-costs__cell--amount h4">£{cart.deposit}</td>
								</tr>
								{cart.guests_discount !== "0.00" && <tr>
									<td className="muted-text booking-costs__cell booking-costs__cell--label">Multiple Guest Discount:</td>
									<td className="muted-text booking-costs__cell booking-costs__cell--amount">-£{cart.guests_discount}</td>
								</tr>}
								{cart.service_fee !== "0.00" && <tr>
									<td className="muted-text booking-costs__cell booking-costs__cell--label">Service charge:</td>
									<td className="muted-text booking-costs__cell booking-costs__cell--amount">£{cart.service_fee}</td>
								</tr>}
								<tr>
									<td className="muted-text booking-costs__cell booking-costs__cell--label">Total Price:</td>
									<td className="muted-text booking-costs__cell booking-costs__cell--amount">£{cart.gross_price}</td>
								</tr>
								{cart.vouchers.map(v => {
									return <tr key={`cart_voucher_${v.code}`}>
										<td className="muted-text booking-costs__cell booking-costs__cell--label">Voucher ({v.code}):</td>
										<td className="muted-text booking-costs__cell booking-costs__cell--amount">-£{v.formattedAmount}</td>
									</tr>
								})}
								<tr>
									<td className="muted-text booking-costs__cell booking-costs__cell--label">Pay when complete:</td>
									<td className="muted-text booking-costs__cell booking-costs__cell--amount">£{cart.remaining_balance}</td>
								</tr>
							</> : <tr><td className="text__align--center"><LoadingSpinner center /></td></tr>}
						</tbody>
					</table>
				</section>
				<section className="section pt-0 pb-1">
					{auth === 'true' ? <PaymentForm 
						paymentIntentSecret={paymentIntentSecret}
						onComplete={completeBooking}
						acceptsVouchers={true}
						handleVoucherFormSubmit={handleVoucherFormSubmit}
						currentVoucherCode={currentVoucherCode}
						setCurrentVoucherCode={setCurrentVoucherCode}
						checkingVoucher={checkingVoucher}
						actionName="Book"
						displayDescription={`Booking with ${barber.name}`}
						displayAmount={cartLoaded && parseFloat(cart.deposit) * 100}
					/> : <div className="button-options">
						<Button className="button-options__button" onClick={captureBookingAndLogin}>
							Sign in
						</Button>
						<div className="button-options__divider">
							<span className="button-options__divider-text">or</span>
						</div>
						<Button className="button-options__button" onClick={() => window.location.href = '/register'}>
							Create an account
						</Button>
					</div>}
				</section>
				<section className="section pt-0">
					<div className="booking-disclaimer">
						By booking an appointment with Barbashop you agree to the <a href="/booking-terms" target="_blank">Terms of Service</a> and the <a href="/privacy-policy" target="_blank">Privacy Policy</a>
					</div>
				</section>
			</div>
		</div>
	</div>;
}

export default BarberBookingSummary
