import axios from 'axios';
import React, { useState } from 'react';
import ActionBar from '../ActionBar';

function Step6({ token }) {
    const [redirecting, setRedirecting] = useState(false);

	const redirect = () => {
		setRedirecting(true);

		const payload = new FormData();
		payload.append('token', token);

		axios.post('/api/barbers/register/stripe', payload)
			.then(res => {
				try {
					window.location.href = res.data.url;
				} catch (e) {
					alert(e)
				}
			})
			.catch(err => {
				setRedirecting(false);
			});
	};

	return <div className="pb-12">
		<h1>Set your payout details</h1>
		<p>Barbashop uses Stripe to make sure you get paid</p>
		<div>
			<p>
				To get paid, your account must be onboarded onto Stripe. Use the button below to redirect to Stripe.
			</p>
		</div>
		<ActionBar
			title="Step 6 of 6"
			subTitle="Set your payout details"
			buttonText={redirecting ? 'Redirecting to Stripe...' : 'Go to Stripe'}
			disabled={redirecting}
			onClick={redirect}
		/>
	</div>;
}

export default Step6;
