import React from 'react'
import Accordion from './UI/Accordion'

function MerchantFAQs() {

	const items = [
		{
			title: "Can I set my own rates?",
			content: <p>Barbashop allows you to set your own rates and services.</p>
		},
		{
			title: "How do I get paid?",
			content: <p>Barbashop only allows online payments and pays out weekly, every Friday.</p>
		},
		{
			title: "Can I decline a booking?",
			content: <p>Barbashop allows you to decline or accept every booking that comes through the app.</p>
		},
		{
			title: "How often do I work?",
			content: <p>Barbashop allows you to change your booking availability at any time.</p>
		},
	];

	return (
		<Accordion items={items} variant="faqs" />
	)
}

export default MerchantFAQs
