import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import CalendarIcon from '../Icons/Calendar'
import SearchIcon from '../Icons/Search'
import SettingsIcon from '../Icons/Settings'
import UserIcon from '../Icons/User'

function Nav() {
	const homeMatch = useRouteMatch({ path: '/search' });
	const calendarMatch = useRouteMatch({ path: '/manage-bookings' });
	const servicesMatch = useRouteMatch({ path: '/account-details' });
	const accountMatch = useRouteMatch({ path: '/account-settings' });

	let hasActiveLink = false;
	if (homeMatch || calendarMatch || servicesMatch || accountMatch) {
		hasActiveLink = true;
	}

	return (
		<nav className={`bottom-nav ${!hasActiveLink ? 'bottom-nav--no-active' : ''}`}>
			<div className="container">
				<ul className="bottom-nav__list">
					<li className="bottom-nav__item">
						<a 
							className="bottom-nav__link" 
							href="/search"
						>
							<SearchIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Search</span>
						</a>
					</li>
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/manage-bookings"
							activeClassName="is-current"
						>
							<CalendarIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">My Bookings</span>
						</NavLink>
					</li>
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/"
							activeClassName="is-current"
						>
							<UserIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Profile</span>
						</NavLink>
					</li>
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/account-settings"
							activeClassName="is-current"
						>
							<SettingsIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Settings</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default Nav
