import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { isBefore, isSameDay, isSameMonth } from 'date-fns'
import slugify from 'slugify';
import { addDays, startOfDay } from 'date-fns/esm';

// set the earliest booking date to today (previously 2 days ahead)
const earliestBookingDate = startOfDay(new Date());
function Calendar({
	availability,
	onDateClick,
	dateToShow,
	allowPreviousDates,
	events,
	getDayClassNames,
	keys,
	onChangeMonth,
	isBookingCalendar
}) {
	const calendarRef = useRef(null);

	useEffect(() => {
		const api = calendarRef.current.getApi();
		api.gotoDate(dateToShow);
	}, [dateToShow]);

	let disabledDays = [];

	if (availability) {
		disabledDays = Object.entries(availability)
			.filter(e => !e[1])
			.map(e => {
				switch (e[0]) {
					case 'sunday': return 0;
					case 'monday': return 1;
					case 'tuesday': return 2;
					case 'wednesday': return 3;
					case 'thursday': return 4;
					case 'friday': return 5;
					case 'saturday': return 6;
				}
			});
	}

	const dayIsDisabled = date => {
		if (isBookingCalendar) {
			if (isBefore(date, earliestBookingDate)) {
				return true;
			}
		}
		if (disabledDays.includes(date.getDay())) {
			return true;
		}
		if (!allowPreviousDates && isBefore(date, startOfDay(new Date()))) {
			return true;
		}
		return false;
	}

	const getDayClasses = d => {
		const classes = [];
		if (isSameDay(dateToShow, d.date)) {
			classes.push('is-current');
		}
		if (dayIsDisabled(d.date)) {
			classes.push('is-disabled');
		}
		const otherClasses = typeof getDayClassNames === 'function' ? getDayClassNames(d) : [];
		return [...classes, ...otherClasses];
	};

	const handleDateClick = d => {
		if (dayIsDisabled(d.date)) {
			return false;
		}
		onDateClick(d);
	};

	return <div id="calendar">
		{keys.length ?
			<div className="calendar-key">
				{keys.map(k => {
					const modifier = slugify(k, { lower: true });
					return (
						<div className="calendar-key__item" key={k}>
							<div className={`calendar-key__circle calendar-key__circle--${modifier}`}></div>
							<div className="calendar-key__text">{k}</div>
						</div>
					)
				})}
			</div>
			: null
		}
		<FullCalendar
			plugins={[dayGridPlugin, interactionPlugin]}
			initialView="dayGridMonth"
			firstDay="1"
			contentHeight="auto"
			fixedWeekCount={false}
			dayHeaderContent={d => d.text.slice(0, 1)}
			dateClick={handleDateClick}
			dayCellClassNames={getDayClasses}
			datesSet={({ view }) => {
				if (onChangeMonth) {
					if (!isSameMonth(view.currentStart, dateToShow)) {
						onChangeMonth(view.currentStart);
					}
				}
			}}
			ref={calendarRef}
			events={events}
		/>
	</div>
}

Calendar.defaultProps = {
	events: [],
	keys: [],
};

export default Calendar
