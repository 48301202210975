import React from 'react'

function InfoIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M7.59993 7.19999V11.2H8.39993V7.19999H7.59993Z" />
			<path d="M7.99993 3.99999C7.5581 3.99999 7.19993 4.35816 7.19993 4.79999C7.19993 5.24182 7.5581 5.59999 7.99993 5.59999C8.44175 5.59999 8.79993 5.24182 8.79993 4.79999C8.79993 4.35816 8.44175 3.99999 7.99993 3.99999Z" />
			<path fillRule="evenodd" clipRule="evenodd" d="M15.1999 7.99999C15.1999 11.9764 11.9764 15.2 7.99993 15.2C4.02348 15.2 0.799927 11.9764 0.799927 7.99999C0.799927 4.02354 4.02348 0.799988 7.99993 0.799988C11.9764 0.799988 15.1999 4.02354 15.1999 7.99999ZM14.3999 7.99999C14.3999 11.5346 11.5345 14.4 7.99993 14.4C4.4653 14.4 1.59993 11.5346 1.59993 7.99999C1.59993 4.46536 4.4653 1.59999 7.99993 1.59999C11.5345 1.59999 14.3999 4.46536 14.3999 7.99999Z" />
		</svg>
	)
}

export default InfoIcon