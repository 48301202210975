import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';

import BackButton from '../UI/BackButton';
import LoadingSpinner from '../UI/LoadingSpinner';
import Button from '../UI/Button';
import SearchIcon from '../Icons/Search';
import timestampToDate from '../../utils/timestampToDate';
import { isAfter, isBefore } from 'date-fns';
import UpcomingBookings from './UpcomingBookings';
import PastBookings from './PastBookings';

function ManageBookings() {
	const history = useHistory();
	
	const [loaded, setLoaded] = useState(false);
	const [bookings, setBookings] = useState([]);

	useEffect(() => {		
		getBookings();
	}, []);

	const getBookings = () => {
		axios.get('/api/user/customer/bookings')
			.then(res => {
				setBookings(res.data.data);
				setLoaded(true);
			});
	}

	const upcomingBookings = bookings.filter(b => !b.past);
	const pastBookings = bookings.filter(b => b.past);
	const cancelledBookings = bookings.filter(b => b.status === BOOKING_STATUS.cancelled || b.status === BOOKING_STATUS.rejected);

	return <div className="pb-12">
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className={`section ${upcomingBookings.length > 0 ? 'background-grey-02' : ''}`}>
			<div className="container">
				<div className="mb-4">
					<h1>Manage my bookings</h1>
					<p>Here you can see all your appointments, past and present.</p>
				</div>
				{loaded ? <div>
					{bookings.length === 0 && 
						<div className="section search-no-results">
							<div className="search-no-results__icon-holder">
								<SearchIcon />
							</div>
							<h3>Let’s get booking!</h3>
							<p className="muted-text muted-text--light">It doesn’t look like you have made any appointments yet!</p>
							<Button 
								variant="secondary" 
								size="full" 
								arrow 
								element="a"
								href="/search"
							>
								Make your first appointment
							</Button>
						</div>
					}
					<UpcomingBookings
						bookings={upcomingBookings}
						getBookings={getBookings}
					/>
				</div> : <div className="loading-spinner__wrap"><LoadingSpinner /></div>}
			</div>
		</section>
		{pastBookings.length > 0 && <section className="section">
			<div className="container">
				<PastBookings
					bookings={pastBookings}
					cancelledBookings={cancelledBookings}
					getBookings={getBookings}
				/>
			</div>
		</section>}
	</div>
}

export default ManageBookings
