import React from 'react'

function Field({
	name,
	id,
	label,
	hideLabel,
	helperText,
	characterLimit,
	value,
	defaultValue,
	children,
}) {
	const labelClass = hideLabel ? 'sr-only' : 'label';

	let currentLength = 0;
	if (characterLimit) {
		if (typeof value === 'string') {
			currentLength = value.length;
		} else {
			currentLength = defaultValue.length;
		}
	}

	return (
		<div className="field">
			<label 
				htmlFor={id || name} 
				className={labelClass}
			>
				{label}
				{characterLimit && <div className="muted-text muted-text--light field__character-count">
					{currentLength}/{characterLimit}
				</div>}
			</label>
			{helperText && <p className="muted-text muted-text--light">{helperText}</p>}
			{children}
		</div>
	)
}

export default Field
