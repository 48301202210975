import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

function Accordion({ items, variant }) {
	const [currentIndex, setCurrentIndex] = useState(0);

	return <div className="accordion">
		{items.map((i, x) => <AccordionItem 
			key={x}
			title={i.title} 
			content={i.content}
			isOpen={x === currentIndex}
			onClick={() => {
				const val = x === currentIndex ? null : x;
				setCurrentIndex(val);
			}}
			variant={variant}
		/>)}
	</div>;
}

export default Accordion;
