import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { differenceInDays, format, nextFriday } from 'date-fns'
import formatCurrency from '../../utils/formatCurrency';
import BackButton from '../UI/BackButton';
import Button from '../UI/Button'
import LoadingSpinner from '../UI/LoadingSpinner';
import formatDate from '../../utils/formatDate';

function BillingSettings() {
	const history = useHistory();

	const [accountBalance, setAccountBalance] = useState(null);
	const [loginLink, setLoginLink] = useState(null);
	const [balanceLoaded, setBalanceLoaded] = useState(false);

	const [userRoleChecked, setUserRoleChecked] = useState(false);
    const [userRole, setUserRole] = useState(null);

	useEffect(() => {
        axios.get('/api/check-role')
            .then(response => {
                setUserRoleChecked(true);
                setUserRole(response.data);
            });
    }, []);

	useEffect(() => {
		console.log({userRole, userRoleChecked});
		if (userRoleChecked && userRole === 'merchant') {
			axios.get('/api/user/manager/balance')
				.then(response => {
					setAccountBalance(response.data.balance);
					setLoginLink(response.data.login_link);
					setBalanceLoaded(true);
				})
				.catch(error => alert('Something went wrong while getting your details. Please contact our team.'));
		}
	}, [userRoleChecked, userRole]);

	const today = new Date();
	const nextPayoutDate = nextFriday(today);

	if (!userRoleChecked) {
		return null;
	}

	if (userRole === 'staff') {
		return <>
			<BackButton
				text="Back to dashboard"
				onClick={() => history.push('/')}
			/>
			<section className="section">
				<div className="container">
					<p>You do not have permission to view the billing settings for this Barber.</p>
				</div>
			</section>
		</>
	}

	return <>
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className="section background-grey-02">
			<div className="container">
				<header className="mb-4">
					<h1>Your Wallet</h1>
					<p>Control your earnings from BARBA</p>
				</header>
				<div className="card">
					{balanceLoaded ? <>
						<h2>You’ve earned {formatCurrency(accountBalance.pending[0].amount / 100)}&nbsp;💷</h2>
						<p>It will be with in your account in {differenceInDays(nextPayoutDate, today)} days.</p>
					</> : <LoadingSpinner center />}
					<p className="sml d-flex items-start">
						🤑 <span className="d-inline-block ml-1">Payouts from Barbashop are every Friday</span>
					</p>
					<p className="sml d-flex items-start">
						📆 <span className="d-inline-block ml-1">Your next payout is due on {format(nextPayoutDate, 'EEEE do LLLL')}</span>
					</p>
					{loginLink &&
						<p className="sml d-flex items-start">
							<a href={loginLink.url} target="_blank" rel="noopener" className="d-inline-block ml-1">Access previous payouts here</a>
						</p>
					}
				</div>
			</div>
		</section>
		<section className="section">
			<div className="container">
				<p>If you want to change/update any of your payment details, you can do this at any time. Just head to the dashboard.</p>
				{loginLink &&
					<Button
						element="a"
						href={loginLink.url}
						target="_blank"
						rel="noopener"
					>
						View payment details
					</Button>
				}
			</div>
		</section>
	</>
}

export default BillingSettings
