import React, { useEffect, useState } from 'react';
import Sorter from '../UI/Sorter';

const defaultSortOptions = {
	'recently-added': 'Recently Added',
	'rating': 'Rating',
	'price-high-to-low': 'Price: High to Low',
	'price-low-to-high': 'Price: Low to High',
};

function BarberSearchSorting({
	visible,
	setVisible,
	sortOption,
	setSortOption,
	auth,
	postcode,
	searchBoxValue,
}) {

	const [sortOptions, setSortOptions] = useState();

	useEffect(() => {
		if (auth === 'true' || searchBoxValue !== '' || postcode !== '') {
			const newSortOptions = { ...defaultSortOptions };
			newSortOptions['distance'] = 'Distance';
			setSortOptions(newSortOptions);
		} else {
			setSortOptions(defaultSortOptions);
		}
	}, [searchBoxValue]);

	const handleSortChange = value => {
		setSortOption(value);
	}

	return <Sorter
		options={sortOptions}
		selected={sortOption}
		onChange={handleSortChange}
		isOpen={visible}
		setIsOpen={setVisible}
	/>;
}

export default BarberSearchSorting;
