import React from 'react'
import TickIcon from '../Icons/Tick'
import useVariableInputProps from '../Hooks/useVariableInputProps'

function SwitchField({
	name,
	id,
	label,
	value,
	checked,
	defaultChecked,
	onChange,
	required,
}) {
	const variableInputProps = {};
	if (onChange) {
		variableInputProps.onChange = e => onChange(e);
		variableInputProps.checked = checked;
	} else {
		variableInputProps.defaultChecked = defaultChecked;
	}

	return (
		<label className="field switch-field">
			<input 
				type="checkbox" 
				name={name}
				id={id || name}
				value={value}
				className="sr-only switch-field__input"
				required={required}
				{...variableInputProps}
			/>
			<span className="switch-field__label">{label}</span>
			<div className="switch-field__control">
				<span>&nbsp;</span>
				<div className="switch-field__handle"></div>
			</div>
		</label>
	)
}

export default SwitchField
