import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import ActionBar from '../ActionBar';
import ServicesManager from '../ServicesManager';
import alert from '../../utils/alert';


function Step4({ token, setStep }) {
	const history = useHistory();

	const [categories, setCategories] = useState([]);
	const [services, setServices] = useState([]);
	const [errors, setErrors] = useState({});
	const [newServiceId, setNewServiceId] = useState(1);
	const [presetCategories, setPresetCategories] = useState([]);

	useEffect(() => {
		axios.get('/api/preset-categories')
			.then(response => {
				setPresetCategories(response.data);
			});
	}, [])

	const handleFormSubmit = e => {
		e.preventDefault();

		if (services.length === 0) {
			alert('Please enter at least one category/service.');
			return false;
		}

		axios.post('/api/barbers/register/services', { token, services, categories })
			.then(response => {
				setStep(5);
				history.push('/your-availability');
			})
			.catch(error => {
				setErrors(error.response.data.errors);
			});
	};

	const handleNewCategoryAdded = newCategory => {
		const categoryToAdd = {
			...newCategory,
			id: categories.length + 1,
			services: [],
		}
		setCategories([...categories, categoryToAdd]);
	}

	const handleCategoryEdit = (category, newProperties) => {
		const newCategories = [...categories];
		const categoryIndex = newCategories.findIndex(c => parseInt(c.id) === parseInt(category.id));
		newCategories[categoryIndex] = {...categories[categoryIndex], ...newProperties};
		setCategories(newCategories);
	}

	const handleNewServiceAdded = newService => {
		setServices([...services, {...newService, id: newServiceId}]);
		setNewServiceId(newServiceId + 1);
	}

	const handleServiceDeleted = service => {
		const newServices = [...services];
		const serviceIndex = newServices.findIndex(s => parseInt(s.id) === parseInt(service.id));
		newServices.splice(serviceIndex, 1);
		setServices(newServices);
	}

	const handleServiceEdited = (service, newProperties) => {
		const newServices = [...services];
		const serviceIndex = newServices.findIndex(s => parseInt(s.id) === parseInt(service.id));
		newServices[serviceIndex] = {...services[serviceIndex], ...newProperties};
		setServices(newServices);
	}

	return (
		<div className="pb-12">
			<h1>Choose your Services</h1>
			<p>Show customers what services you offer</p>
			<ServicesManager
				categories={categories}
				presetCategories={presetCategories}
				services={services}
				onCategoryAdd={handleNewCategoryAdded}
				onCategoryEdit={handleCategoryEdit}
				onServiceAdd={handleNewServiceAdded}
				onServiceDelete={handleServiceDeleted}
				onServiceEdit={handleServiceEdited}
				newServiceId={newServiceId}
				allowCategoryUpdates={true}
			/>
			<ActionBar
				title="Step 4 of 6"
				subTitle="Add your services"
				buttonText="Next"
				onClick={handleFormSubmit}
			/>
		</div>
	);
}

export default Step4;
