import React, { useEffect, createRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BarberBookingAppointment from '../BarberBooking/BarberBookingAppointment';
import BarberBookingConfirmation from '../BarberBooking/BarberBookingConfirmation';
import BarberBookingSummary from '../BarberBooking/BarberBookingSummary';
import BarberProfile from '../BarberProfile'
import Panel from '../UI/Panel';

const stripePromise = loadStripe(`${process.env.MIX_STRIPE_KEY}`);

function StaffPanel({
	auth,
	clearStaffId,
	staffId,
	barberId,
	panelRef,
	closePanel,
}) {
	const history = useHistory();
	const staffPanelInnerRef = createRef();

	const [currentStep, setCurrentStep] = useState('profile');
	const [staff, setStaff] = useState(null);
	const [booking, setBooking] = useState({});

	useEffect(() => {
		if (!staffId) return false;

        axios.get(`/api/barbers/staff/${staffId}`)
			.then(res => {
				setStaff(res.data.data);
			});
    }, [staffId]);

	useEffect(() => {
		if (staffPanelInnerRef.current) {
			staffPanelInnerRef.current.scrollTo(0, 0);
			history.push({ hash: currentStep });
		}
	}, [currentStep]);

	const defaultProps = {
		staff,
		booking,
		auth,
		barber: staff?.barber,
		setBooking: (v) => setBooking(v)
	};

	if (!staff) return null;

	return <Panel
		path="/barbers/:barber/:staff"
		condition={staffId !== null}
		panelRef={panelRef}
		innerRef={staffPanelInnerRef}
		className="barber-panel"
	>
		<Elements stripe={stripePromise}>
			{currentStep === 'profile' && 
				<BarberProfile 
					{...defaultProps}
					closePanel={closePanel} 
					goToBookingAppointment={() => setCurrentStep('booking-appointment')} 
				/>
			}
			{currentStep === 'booking-appointment' && 
				<BarberBookingAppointment 
					{...defaultProps}
					goToProfile={() => setCurrentStep('profile')}
					goToBookingSummary={() => setCurrentStep('booking-summary')}
				/>
			}
			{currentStep === 'booking-summary' && 
				<BarberBookingSummary 
					{...defaultProps}
					goToBookingAppointment={() => setCurrentStep('booking-appointment')} 
				/>
			}
		</Elements>
	</Panel>
}

export default StaffPanel;
