import React from 'react'
import { Range } from 'react-range';

const defaultChangeHandler = () => {
	return null;
}

function RangeField({
	step,
	min,
	max,
	values,
	onChange,
	onFinalChange,
	minLabel,
	maxLabel,
	valueLabel,
}) {
	return (
		<div className="range-field">
			<Range
				step={step}
				min={min}
				max={max}
				values={values}
				onChange={onChange || defaultChangeHandler}
				onFinalChange={onFinalChange || defaultChangeHandler}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={props.style}
						className="range-field__track-outer"
					>
						<div
							ref={props.ref}
							className="range-field__track-inner"
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props }) => (
					<div
						{...props}
						className="range-field__thumb"
					/>
				)}
			/>
			<div className="range-field__labels">
				{minLabel && <div className="range-field__label range-field__label--min">{minLabel}</div>}
				{valueLabel && <div className="range-field__label range-field__label--value muted-text muted-text--light">{valueLabel}</div>}
				{maxLabel && <div className="range-field__label range-field__label--max">{maxLabel}</div>}
			</div>
		</div>
	)
}

export default RangeField
