import React from 'react'
import Button from '../UI/Button'

function DeleteService({
	onDelete,
	onCancel,
}) {
	return (
		<div>
			<p>Once removed, this action cannot be undone and you will have to add again.</p>
			<Button
				size="full"
				className="mb-2"
				onClick={onDelete}
			>
				Yes, remove this service
			</Button>
			<Button
				size="full"
				variant="secondary"
				onClick={onCancel}
			>
				Cancel changes
			</Button>
		</div>
	)
}

export default DeleteService
