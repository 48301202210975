import axios from 'axios';
import React, { useState } from 'react'
import Button from './UI/Button';
import LoadingSpinner from './UI/LoadingSpinner';
import TextField from './UI/TextField';

function RequestAreaNotifications() {

	const [postcode, setPostcode] = useState('');
	const [email, setEmail] = useState('');
	const [processing, setProcessing] = useState(false);
	const [complete, setComplete] = useState(false);
	const [errors, setErrors] = useState({});

	const handleFormSubmit = e => {
		e.preventDefault();
		setProcessing(true);
		axios.post('/api/area-notifications', { postcode, email })
			.then(response => {
				setProcessing(false);
				setComplete(true);
			})
			.catch(error => {
				setProcessing(false);
				setErrors(error.response.data.errors);
			});
	}

	if (processing) {
		return <LoadingSpinner center />;
	}

	if (complete) {
		return <>
			<h4>Thank You!</h4>
			<p>We'll notify you as soon as there are barbers in your area.</p>
		</>
	}

	return (
		<form className="text__align--left" onSubmit={handleFormSubmit} method="POST" action="">
			<TextField
                name="postcode"
                type="text"
                value={postcode}
				label="Enter your postcode"
                onChange={(e) => setPostcode(e.target.value)}
                required
				errors={errors.postcode}
            />
			<TextField
                name="email"
                type="email"
                value={email}
				label="Email"
                onChange={(e) => setEmail(e.target.value)}
                required
				placeholder="Email Address"
				errors={errors.email}
            />
			<Button size="full" disabled={!postcode || !email}>
				Subscribe to notifications
			</Button>
		</form>
	)
}

export default RequestAreaNotifications
