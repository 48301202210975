import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom';
import CrossIcon from '../Icons/Cross';
import Button from '../UI/Button'
import TextAreaField from '../UI/TextAreaField'
import TextField from '../UI/TextField'

function EditMerchantForm({
	firstName,
	lastName,
	email,
	phone,
	line1,
	line2,
	city,
	postcode,
	setFirstName,
	setLastName,
	setEmail,
	setPhone,
	availability,
	handleFormSubmit,
	errors,
	setEditingLocation,
	setEditingAvailability,
	setEditingImages,
	isOwner,
}) {
	const { path } = useRouteMatch();

	const availabilityArray = [];
	for (const day in availability) {
		if (Object.hasOwnProperty.call(availability, day)) {
			const dayName = day.charAt(0).toUpperCase() + day.slice(1);
			let dayText = '';
			if (availability[day]) {
				const times = availability[day][0];
				dayText = `${dayName}: ${times.from}-${times.to}`;
			} else {
				dayText = `${dayName}: Closed`;
			}
			availabilityArray.push(dayText);
		}
	}

	return (
		<>
			<header className="mb-4">
				<h1>Account Details</h1>
				<p>Update your account details</p>
			</header>
			<form onSubmit={handleFormSubmit}>
				<TextField
					name="first_name"
					label="First Name"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					placeholder="First name"
					required
					errors={errors.first_name}
				/>
				<TextField
					name="last_name"
					label="Last Name"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					placeholder="Last name"
					required
					errors={errors.last_name}
				/>
				<TextField
					name="email"
					type="email"
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="Email Address"
					required
					errors={errors.email}
				/>
				<TextField
					name="phone"
					type="tel"
					label="Contact Number"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					placeholder="07123 456 789"
					errors={errors.phone}
				/>
				{isOwner &&
					<TextField
						name="address"
						label="Address"
						rightIcon="pencil"
						value={`${line1}, ${line2 ? line2 + ', ' : ''}${city} ${postcode}`}
						onChange={() => null}
						inputProps={{
							onClick: () => setEditingLocation(true),
							readOnly: true
						}}
					/>
				}
				<TextAreaField
					name="availability_display"
					label="Working Hours"
					rightIcon="pencil"
					value={availabilityArray.join("\n")}
					onChange={() => null}
					noFixedHeight={true}
					inputProps={{
						onClick: () => setEditingAvailability(true),
						readOnly: true,
						rows: 7,
					}}
				/>
				<div className="mb-4">
					<Button
						onClick={() => setEditingImages(true)}
						variant="secondary"
					>
						Manage Images
					</Button>
				</div>
				<input type="hidden" name="address_line_1" value={line1} />
				<input type="hidden" name="address_line_2" value={line2} />
				<input type="hidden" name="address_city" value={city} />
				<input type="hidden" name="address_postcode" value={postcode} />
				<Button type="submit">
					Update
				</Button>
			</form>
			<Link to={`${path}/close-account`} className="mt-2 d-flex items-center text__color--danger">
				<span className="icon-button icon-button--red mr-1"><CrossIcon className="icon-button__icon" /></span>
				I want to close this account
			</Link>
		</>
	)
}

export default EditMerchantForm
