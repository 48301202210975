import React from 'react';

function LoadingSpinner({
    color,
    size,
    center,
    className,
}) {

    const classes = ['loading-spinner', className];

    if (size) {
        classes.push(`loading-spinner--${size}`);
    }

    if (color) {
        classes.push(`loading-spinner--${color}`);
    }

    if (center) {
        classes.push(`loading-spinner--center`);
    }

	return (
		<div className={classes.filter(Boolean).join(' ')}>
            <div></div>
            <div></div>
            <div></div>
        </div>
	)
}

export default LoadingSpinner;
