import React from 'react';
import ChevronRightIcon from './Icons/ChevronRight';
import PaymentMethodIcon from './PaymentMethodIcon';

function PaymentMethod({ 
    method,
    setMethod
}) {
    const isClickable = typeof setMethod === 'function';
    let label = '';
    let Element = isClickable ? 'button' : 'div';

    switch(method.brand) {
        case 'mastercard':
            label = 'MasterCard';
            break;
        case 'amex':
            label = 'American Express';
            break;
        case 'visa':
            label = 'VISA';
            break;
        default:
            label = method.brand.replace(/(?:^|_)([a-z])/g, function($0,$1) { return $1.toUpperCase(); });
            break;
    }

    return (
        <Element className="payment-method" onClick={setMethod}>
            <PaymentMethodIcon type={method.brand} />
            <div className="payment-method__details">
                <h3 className="payment-method__name">{label}</h3>
                <div className="payment-method__info">Card ending in {method.last_four}</div>
            </div>
            {isClickable && <ChevronRightIcon />}
        </Element>
    );
}

export default PaymentMethod;