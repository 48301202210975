import { addMinutes, format, parse } from 'date-fns';
import React, { useState } from 'react'
import formatDate from '../../utils/formatDate';
import timestampToDate from '../../utils/timestampToDate';
import CrossIcon from '../Icons/Cross';
import Button from '../UI/Button';
import TextField from '../UI/TextField';

function EditBarberBlockedTimes({
	blockedTimes,
	handleAddBlockedTimeFormSubmit,
	handleBlockedTimeDeleteClick,
	errors,
}) {
	const [minEndDate, setMinEndDate] = useState(new Date());

	const handleStartDateChange = e => {
		setMinEndDate(parse(e.target.value, "yyyy-MM-dd'T'HH:mm", new Date));
	}

	return (
		<div>
			<h2>Blocked Times</h2>
			<div className="mb-4">
				{blockedTimes.length ? <ul className="pl-0">
					{blockedTimes.map(t => <li key={t.id} className="mb-1 d-flex justify-between items-start">
						<span className="mr-3">{formatDate(timestampToDate(t.start_time))} - {formatDate(timestampToDate(t.end_time))}</span>
						<button 
							className="text__color--danger flex-shrink-0" 
							onClick={() => handleBlockedTimeDeleteClick(t.id)}
						>
							<CrossIcon /> <small>Delete</small>
						</button>
					</li>)}
				</ul> : <p><i>You do not have any blocked times.</i></p>}
			</div>
			<div>
				<h5>Add A Blocked Time</h5>
				<form method="POST" onSubmit={handleAddBlockedTimeFormSubmit}>
					<TextField
						name="start_time"
						type="datetime-local"
						label="Start Date / Time"
						required
						onChange={handleStartDateChange}
						errors={errors.blockedTime?.start_time}
						inputProps={{
							min: format(new Date, "yyyy-MM-dd'T'HH:mm"),
						}}
					/>
					<TextField
						name="end_time"
						type="datetime-local"
						label="End Date / Time"
						required
						errors={errors.blockedTime?.end_time}
						inputProps={{
							min: format(addMinutes(minEndDate, 1), "yyyy-MM-dd'T'HH:mm"),
						}}
					/>
					<Button
						type="submit"
					>
						Add Time
					</Button>
				</form>
			</div>
		</div>
	)
}

export default EditBarberBlockedTimes;