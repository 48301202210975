import React from 'react'
import PlusIcon from '../Icons/Plus'

function AddNewCategoryOrService({
	onCategoryClick,
	onServiceClick,
}) {
	return (
		<div className="add-new-category-or-service">
			<button onClick={onCategoryClick} className="add-new-category-or-service__button">
				<span className="icon-button icon-button--fill-black icon-button--large mb-2">
					<PlusIcon className="icon-button__icon" />
				</span>
				Add a category
			</button>
			<button onClick={onServiceClick} className="add-new-category-or-service__button">
				<span className="icon-button icon-button--fill-black icon-button--large mb-2">
					<PlusIcon className="icon-button__icon" />
				</span>
				Add a service
			</button>
		</div>
	)
}

export default AddNewCategoryOrService
