import React, { useState } from 'react'
import { usePopper } from 'react-popper';
import SortIcon from '../Icons/Sort';
import FocusTrap from 'focus-trap-react';

function Sorter({
	options,
	selected,
	onChange,
	isOpen,
	setIsOpen,
	dropdownOffsetX,
	dropdownOffsetY,
}) {
	
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom',
		modifiers: [
			{
				name: 'arrow',
				options: {
					element: arrowElement
				}
			},
			{
				name: 'offset',
				options: {
					offset: [dropdownOffsetX, dropdownOffsetY],
				},
			},
		],
	});

	const handleOptionChange = e => {
		onChange(e.target.value);
	}

	return (
		<div className="barber-search-sorting">
			<button className="barber-search-actions__button" onClick={() => setIsOpen(!isOpen)} ref={setReferenceElement}>
				Sort <span className="icon-button icon-button--grey ml-1"><SortIcon className="icon-button__icon" /></span>
			</button>

			{isOpen &&
				<FocusTrap focusTrapOptions={{
					onDeactivate: () => setIsOpen(false),
					clickOutsideDeactivates: true,
				}}>
					<div className="barber-search-sorting-dropdown" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
						<ul className="barber-search-sorting-dropdown__list">
							{Object.entries(options).map(option => {
								return (
									<li className="barber-search-sorting-dropdown__item" key={option[0]}>
										<label htmlFor={`sort_options_${option[0]}`} className="barber-search-sorting-dropdown__label">
											<input 
												type="radio"
												name="barber_search_sorting"
												className="barber-search-sorting-dropdown__input sr-only"
												id={`sort_options_${option[0]}`}
												value={option[0]}
												onChange={handleOptionChange}
												checked={selected === option[0]}
											/>
											<div className="barber-search-sorting-dropdown__text">{option[1]}</div>
										</label>
									</li>
								)
							})}
						</ul>
						<div ref={setArrowElement} style={styles.arrow} className="barber-search-sorting-dropdown__arrow" />
					</div>
				</FocusTrap>
			}
		</div>
	)
}

Sorter.defaultProps = {
	dropdownOffsetX: 0,
	dropdownOffsetY: 24,
}

export default Sorter
