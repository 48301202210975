import axios from 'axios';
import { addDays, isBefore } from 'date-fns';
import React, { useEffect, useState } from 'react';
import getNextAvailableDay from '../../utils/getNextAvailableDay';
import formatDate from '../../utils/formatDate';

import ActionBar from '../ActionBar';
import BookingHeaderBar from '../BookingHeaderBar';
import Calendar from '../UI/Calendar';
import { format } from 'date-fns/esm';

const recurringOptions = [
	{ value: 0, label: 'One-off' },
	{ value: 1, label: 'Every week' },
	{ value: 2, label: 'Every 2 weeks' },
	{ value: 4, label: 'Every 4 weeks' },
	{ value: 6, label: 'Every 6 weeks' },
];

function BarberBookingAppointment({
	barber, 
	staff,
	booking, 
	setBooking, 
	goToProfile, 
	goToBookingSummary
}) {
	const [selectedDate, setSelectedDate] = useState(() => {
		return getNextAvailableDay(new Date(), staff.availability)
	});
	const [selectedTime, setSelectedTime] = useState('');
	const [timeOptions, setTimeOptions] = useState([]);
	const [recurringFrequency, setRecurringFrequency] = useState(null);

	/**
	 * Store booking details and submit it.
	 * @returns {void}
	 */
	const submit = () => {
		setBooking(Object.assign(booking, { 
			date: selectedDate.toDateString(), 
			time: selectedTime,
			recurring_frequency: recurringFrequency,
		}));

		goToBookingSummary();
	};

	// update available times when selected date changes
	useEffect(() => {
		const date = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
		const duration = Object.values(booking.services).reduce((x, y) => x += (y.duration * y.quantity), 0);

		axios.get(`/api/barbers/${barber.slug}/${staff.slug}/availability?date=${date}&duration=${duration}`)
			.then(res => {
				setTimeOptions(res.data);
			})
			.catch(err => {
				setTimeOptions([]);
			})
			.finally(() => setSelectedTime(''));
		
	}, [selectedDate]);

	const handleMonthChange = month => {
		if (isBefore(month, new Date)) {
			return false;
		}
		setSelectedDate(getNextAvailableDay(month, staff.availability))
	}

	return <div className="pb-12">
		<BookingHeaderBar
			title="Book an appointment"
			subTitle={`with ${staff.full_name} (${barber.name})`}
			onClick={goToProfile}
		/>
		<div className="container">
			<section className="section">
				<div className="availability-calendar">
					<Calendar
						availability={staff.availability}
						dateToShow={selectedDate}
						onDateClick={(d) => setSelectedDate(d.date)}
						allowPreviousDates={false}
						onChangeMonth={handleMonthChange}
						isBookingCalendar={true}
					/>
				</div>
			</section>
			<section className="section pt-0">
				<legend className="h4">All available times</legend>
				{timeOptions.length === 0 ?
					<p>No times available</p> : <p className="muted-text">Please select a time slot</p>
				}
				<div className="booking-options row">
					{timeOptions.map((t, i) => <label key={`time_options_${i}`} className="booking-options__item col-xs-4">
						<input type="radio" name="time" id={`time_options_${i}`} checked={selectedTime === t} onChange={(e) => e.target.checked ? setSelectedTime(t) : false} className="sr-only booking-options__input" />
						<div className="booking-options__button">{t}</div>
					</label>)}
				</div>
			</section>
			<section className="section pt-0">
				<legend className="h4">Barba+</legend>
				<p className="muted-text">Repeat bookings (schedule your booking below)</p>
				<div className="booking-options row">
					{recurringOptions.map(r => <label key={`repeat_options_${r.value}`} className="booking-options__item col-xs-6">
						<input 
							type="radio" 
							name="repeat" 
							id={`repeat_options_${r.value}`} 
							className="sr-only booking-options__input" 
							onChange={() => setRecurringFrequency(r.value)}
							required
						/>
						<div className="booking-options__button">{r.label}</div>
					</label>)}
				</div>
			</section>
		</div>
		<ActionBar
			title={`Step 2 of 2`}
			subTitle={(!selectedDate || !selectedTime) ? 'Choose a date and time' : format(selectedDate, "do MMMM") + ' at ' + selectedTime}
			buttonText="Book Now"
			disabled={!selectedDate || !selectedTime || recurringFrequency === null}
			onClick={submit}
		/>
	</div>;
}

export default BarberBookingAppointment;
