export default (services) => {

	const servicesArray = Object.values(services);

	// if there are no services, return zero
	if (servicesArray.length === 0) {
		return { totalPrice: 0, totalDiscount: 0 };
	}

	const discountRates = [
		0,
		0.1,
		0.15,
		0.2,
		0.25,
	];

	// sort the services by price
	const sortedServices = servicesArray.sort((a, b) => b.perPrice - a.perPrice);

	// push the individual prices into an array
	const servicePrices = []
	sortedServices.forEach(s => {
		for (let i = 0; i < s.quantity; i++) { 
			servicePrices.push(s.perPrice);
		}
	});

	// loop through and calculate the discount
	const totalDiscount = parseFloat(servicePrices.reduce((total, servicePrice, key) => {
		return total + (servicePrice * discountRates[key]);
	}, 0)).toFixed(2);

	// work out the total price of all the services
	let totalPrice = parseFloat(
		servicesArray.map(obj => parseFloat(obj.totalPrice))
			.reduce((prev, curr) => prev + curr)
	).toFixed(2);

	totalPrice = parseFloat(totalPrice - totalDiscount).toFixed(2);

	return { totalPrice, totalDiscount };
}