import React from 'react'
import { format, startOfHour, addHours, parse, differenceInHours } from 'date-fns';

import CalendarBookingCard from './CalendarBookingCard';

const minutesToPixelsRatio = 2;
const hourWidth = 100;
const bookingCardOffset = 20;

function BookingsForDate({
	date,
	bookings,
	updateBooking,
	availability,
	getBookings,
}) {
	if (!bookings || !bookings.length) {
		return <div className="py-4">
			<div className="container">
				<p className="muted-text muted-text--light">No appointments on this day.</p>
			</div>
		</div>;
	}

	if (!availability) {
		return <div className="py-4">
			<div className="container">
				<p className="muted-text muted-text--light">No availability on this day.</p>
			</div>
		</div>;
	}

	const startHour = startOfHour(parse(availability[0].from, 'HH:mm', date));
	const toHour = startOfHour(addHours(parse(availability[0].to, 'HH:mm', date), 1));
	const diffInHours = differenceInHours(toHour, startHour) + 1;

	return <div className="pb-4">
		<div className="container">
			<div className="day-calendar">
				{[...Array(diffInHours)].map((x, i) => {
					const currentHour = addHours(startHour, i);
					return (
						<div 
							className="day-calendar__hour" 
							key={`hour_${i}`} 
							style={{ height: 60 * minutesToPixelsRatio }}
						>
							<div className="day-calendar__hour-text" style={{ width: hourWidth }}>
								{format(currentHour, 'HH:mm')}
							</div>
							<div className="day-calendar__hour-bookings">
								<span className="day-calendar__hour-divider"></span>
								<span className="day-calendar__hour-divider"></span>
								<span className="day-calendar__hour-divider"></span>
							</div>
						</div>
					)
				})}
				{bookings.map((b, i) => {
					return <CalendarBookingCard
						key={`day_booking_${b.id}`}
						booking={b}
						updateBooking={updateBooking}
						startHour={startHour}
						minutesToPixelsRatio={minutesToPixelsRatio}
						hourWidth={hourWidth}
						bookingCardOffset={bookingCardOffset}
						index={i}
						getBookings={getBookings}
					/>
				})}
			</div>
		</div>
	</div>;
}

export default BookingsForDate;
