import React from 'react';
import CrossIcon from '../Icons/Cross';

import Button from '../UI/Button';
import RangeField from '../UI/RangeField';
import SwitchField from '../UI/SwitchField';

function BarberSearchFilters({
	onUpdate,
	visible,
	setVisible, 
	handleStarRatingChange,
	setPriceRange,
	currentPriceRange,
	setCurrentPriceRange,
	setMaxTravelDistance,
	currentMaxTravelDistance,
	setCurrentMaxTravelDistance,
	ratingCounts,
}) {

	if (!ratingCounts) {
		return null;
	}

	return <div style={{ display: visible ? 'block' : 'none' }} className="barber-search-filters">
		<div className="barber-search-filters__header">
			<h3>Filter by</h3>
			<button onClick={() => setVisible(false)} className="icon-button icon-button--black">
				<CrossIcon className="icon-button__icon" />
			</button>
		</div>
		<div>
			<div className="mb-4">
				<h5>Star Rating</h5>
				{[...Array(5)].map((x, i) => {
					const value = 5 - i;
					const ratingCount = ratingCounts.find(r => r.rating === value);
					return <div key={`barber_search_filter_star_rating_${value}`}>
						<SwitchField
							name="star-rating"
							label={
								<>
									<span className="text__color--warning">{"★".repeat(value)}</span>
									<span className="muted-text muted-text--light">&nbsp;({ratingCount ? ratingCount.count : 0})</span>
								</>
							}
							value={value}
							onChange={e => handleStarRatingChange(e.target.value)}
						/>
					</div>
				})}
			</div>
			<hr className="mb-4" />
			<div className="mb-4">
				<h5>How far are you willing to travel?</h5>
				<RangeField
					step={5}
					min={5}
					max={200}
					values={currentMaxTravelDistance}
					onChange={values => setCurrentMaxTravelDistance(values)}
					onFinalChange={values => setMaxTravelDistance(values)}
					minLabel={<div>5<br />miles</div>}
					maxLabel={<div>200<br />miles</div>}
					valueLabel={currentMaxTravelDistance[0] + ' miles'}
				/>
			</div>
			<hr className="mb-4" />
			<div className="mb-4">
				<h5>What’s your budget?</h5>
				<RangeField
					step={5}
					min={5}
					max={75}
					values={currentPriceRange}
					onChange={values => setCurrentPriceRange(values)}
					onFinalChange={values => setPriceRange(values)}
					minLabel={<div>£5<br />Price min</div>}
					maxLabel={<div>£75<br />Price max</div>}
					valueLabel={`£${currentPriceRange[0]}-£${currentPriceRange[1]}`}
				/>
			</div>
			<Button 
				onClick={onUpdate}
				size="full"
			>
				Confirm
			</Button>
		</div>
	</div>;
}

export default BarberSearchFilters;
