import { differenceInSeconds } from 'date-fns';
import React, { useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router';
import timestampToDate from '../../utils/timestampToDate';
import Sorter from '../UI/Sorter';
import ConfirmedBooking from './ConfirmedBooking'
import ConfirmedBookingPanel from './ConfirmedBookingPanel';

const sortOptions = {
	'recent': 'Recently accepted',
	'closest': 'Distance: Closest',
	'furthest': 'Distance: Furthest',
};

function ConfirmedBookings({
	bookings,
	getBookings,
}) {
	const history = useHistory();
	const { path, url } = useRouteMatch();

	const [sortOption, setSortOption] = useState('');
	const [sortOpen, setSortOpen] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [cancelFormOpen, setCancelFormOpen] = useState(false);

	const handleSortChange = value => {
		setSortOption(value);
		setSortOpen(false);
	}

	const sortBookingsByConfirmedDate = (a, b) => {
		return differenceInSeconds(timestampToDate(b.confirmed_at), timestampToDate(a.confirmed_at));
	}

	const clearBooking = () => {
		setCancelFormOpen(false);
		setSelectedBooking(null);
		history.push(path);
	}

	return <>
		<div className="d-flex justify-between">
			<h2 className="h4 d-flex">Confirmed <span className="booking-count-badge ml-1">{bookings.length}</span></h2>
		</div>
		<ul className="arrow-links">
			{bookings.map(b => <ConfirmedBooking
				key={`confirmed_booking_${b.id}`}
				booking={b}
				setSelectedBooking={setSelectedBooking}
			/>)}
		</ul>
		<ConfirmedBookingPanel
			booking={selectedBooking}
			bookings={bookings}
			setSelectedBooking={setSelectedBooking}
			onClose={clearBooking}
			getBookings={getBookings}
			cancelFormOpen={cancelFormOpen}
			setCancelFormOpen={setCancelFormOpen}
			clearBooking={clearBooking}
		/>
	</>
}

export default ConfirmedBookings
