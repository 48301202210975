import React, { createRef, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router';
import formatDate from '../../utils/formatDate';
import timestampToDate from '../../utils/timestampToDate';
import CrossIcon from '../Icons/Cross';
import Panel from '../UI/Panel';
import BarberRating from '../BarberRating';
import Sheet from '../UI/Sheet';
import StarIcon from '../Icons/Star';
import Button from '../UI/Button';
import ReloadIcon from '../Icons/Reload';
import ReviewForm from './ReviewForm';
import ratings from '../../utils/reviewRatings';
import TipForm from './TipForm';
import BarberAvatar from '../UI/BarberAvatar';
import InvoicePanel from './InvoicePanel';
import PaymentMethodIcon from '../PaymentMethodIcon';
import TipFormEntry from './TipFormEntry';
import alert from '../../utils/alert';
import googleMapsUrl from '../../utils/googleMapsUrl';

function PastBookingPanel({
	booking,
	onClose,
	getBookings,
	clearBooking,
}) {
	const history = useHistory();
	const { path } = useRouteMatch();
	const panelRef = createRef();

	const [reviewFormOpen, setReviewFormOpen] = useState(false);
	const [reviewSubmitted, setReviewSubmitted] = useState(false);
	const [tipFormOpen, setTipFormOpen] = useState(false);
	const [tipAdded, setTipAdded] = useState(false);
	const [defaultTipValue, setDefaultTipValue] = useState(null);
	const [defaultTipFormat, setDefaultTipFormat] = useState('percentage');

	useEffect(() => {
		if (reviewSubmitted || tipAdded) {
			clearBooking();
			getBookings();
		}
	}, [reviewSubmitted, tipAdded])

	useEffect(() => {
		if (defaultTipValue || defaultTipFormat === 'custom') {
			setTipFormOpen(true);
		}
	}, [defaultTipValue, defaultTipFormat])

	const handleReviewFormSubmitted = async () => {
		alert('Thanks for leaving a review!');
		setReviewSubmitted(true);
		setReviewFormOpen(false);
	}

	const handleTipFormSubmitted = async () => {
		alert('Thanks for leaving a tip!');
		setTipAdded(true);
		setTipFormOpen(false);
	}

	if (!booking) return null;

	return <Panel
		path={`${path}/past-bookings/:id`}
		condition={booking !== null}
		panelRef={panelRef}
		className="booking-panel"
	>
		<>
			<header className="booking-panel__top">
				<div className="container">
					<div className="d-flex justify-end">
						<button className="icon-button icon-button--white" onClick={onClose}>
							<CrossIcon className="icon-button__icon" />
						</button>
					</div>
					<h3>{formatDate(timestampToDate(booking.date))}</h3>
					<p className="mb-4"><span className="status-badge status-badge--confirmed">Confirmed</span></p>
					<div className="d-flex justify-between mb-4">
						<div className="mr-3">
							<p className="mb-1"><strong>{booking.barber.name}</strong></p>
							<p className="mb-1 muted-text muted-text--light">At {booking.address}</p>
							<p className="mb-1 muted-text muted-text--light"><a className="booking-panel__directions-link" href={googleMapsUrl(booking.address)} target="_blank">Get Directions</a></p>
							<p className="mb-0 muted-text muted-text--light">Booking Ref: {booking.id}</p>
						</div>
						<div className="flex-shrink-0">
							<BarberAvatar barber={booking.barber} size="large" className="mb-1" />
							<BarberRating
								rating={booking.barber.rating}
								className="text__align--center"
							/>
						</div>
					</div>
					<div className="d-flex">
						{booking.customer.review === null &&
							<Sheet
								isOpen={reviewFormOpen}
								onClick={() => setReviewFormOpen(false)}
								content={<ReviewForm 
									booking={booking}
									afterSubmit={handleReviewFormSubmitted}
								/>}
							>
								<Button
									className="booking-panel__action-button mr-2 button--small-width"
									onClick={() => setReviewFormOpen(true)}
								>
									Leave Review
									<StarIcon className="booking-panel__action-button-icon" />
								</Button>
							</Sheet>
						}
						<Button
							className="booking-panel__action-button button--small-width"
							element="a"
							href={`/barbers/${booking.barber.slug}`}
						>
							Rebook
							<ReloadIcon className="booking-panel__action-button-icon" />
						</Button>
					</div>
				</div>
			</header>
			<div className="booking-panel__tipping">
				<div className="container">
					<Sheet
						isOpen={tipFormOpen}
						onClick={() => setTipFormOpen(false)}
						content={<TipForm
							booking={booking}
							afterSubmit={handleTipFormSubmitted}
							defaultTipValue={defaultTipValue}
							defaultTipFormat={defaultTipFormat}
						/>}
					>
						<TipFormEntry
							barber={booking.barber}
							setDefaultTipValue={setDefaultTipValue}
							setDefaultTipFormat={setDefaultTipFormat}
						/>
					</Sheet>
				</div>
			</div>
			<div className="booking-panel__middle">
				<div className="container">
					<table className="booking-pricing-table">
						<tbody>
							{booking.services.map(s => {
								return (
									<tr key={`booking_${booking.id}_service_${s.id}`} className="booking-pricing-table__row">
										<td className="booking-pricing-table__cell">
											<div>{s.name} x {s.quantity}</div>
											<div className="muted-text muted-text--light">{s.total_duration} mins</div>
										</td>
										<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
											<div>£{s.gross_price}</div>
										</td>
									</tr>
								)
							})}
						</tbody>
						<tfoot>
							<tr className="booking-pricing-table__row">
								<td className="booking-pricing-table__cell"><strong>Total</strong></td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									<strong>£{booking.gross_price}</strong>
								</td>
							</tr>
							<tr className="booking-pricing-table__row">
								<td className="booking-pricing-table__cell">
									<span className="muted-text muted-text--light">Paid by card</span>
								</td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price booking-payment-details">
									<PaymentMethodIcon 
										type={booking.payment.brand} 
										className="booking-payment-details__logo" 
										iconClassName="booking-payment-details__icon" 
									/>
									●●●● {booking.payment.last4}
								</td>
							</tr>
						</tfoot>
					</table>
					<div className="text__align--center py-2">
						<button
							className="text-button"
							onClick={() => history.push(`${path}/past-bookings/${booking.id}/invoice`)}
						>
							View Invoice
						</button>
					</div>
				</div>
			</div>
			<div className="booking-panel__bottom">
				<div className="container">
					{booking.customer.review &&
						<div className="mb-5">
							<p className="mb-1"><strong>Your Review</strong></p>
							<div className="mb-1">
								{[...Array(booking.customer.review.rating)].map((r, i) => {
									return <span 
										key={`review_stars_${i + 1}`} 
										className="d-inline-block text__color--warning"
										style={{ marginRight: 2, display: 'inline-block' }}
									>★</span>
								})}
								<strong className="d-inline-block ml-1">
									{booking.customer.review.rating}.0&nbsp;
									{ratings[booking.customer.review.rating - 1]}
								</strong>
							</div>
							<p>{booking.customer.review.message}</p>
						</div>
					}
					<h6>Important info</h6>
					<p>As a courtesy to the business please avoid cancelling within 48 hours of your appointment. We do charge for late cancellations.</p>
					<h6>Cancellation policy</h6>
					<p>Cancel for free up to <strong>48 hours</strong> ahead, otherwise the <strong>£5</strong> deposit will be taken and service fee price for late cancellation.</p>
				</div>
			</div>
		</>
		<InvoicePanel
			booking={booking}
			currentPath={`${path}/past-bookings/${booking.id}`}
			onClose={() => history.push(`${path}/past-bookings/${booking.id}`)}
		/>
	</Panel>
}

export default PastBookingPanel
