import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';

import ServicesManager from '../ServicesManager';
import BackButton from '../UI/BackButton';

function Services({
	user,
}) {
	const history = useHistory();

	const [services, setServices] = useState([]);
	const [tmpCategories, setTmpCategories] = useState([]);
	const [presetCategories, setPresetCategories] = useState([]);

	const [userRoleChecked, setUserRoleChecked] = useState(false);
    const [userRole, setUserRole] = useState(null);

	useEffect(() => {
        axios.get('/api/check-role')
            .then(response => {
                setUserRoleChecked(true);
                setUserRole(response.data);
            });
    }, []);

	useEffect(() => {
		getServices();

		axios.get('/api/preset-categories')
			.then(response => {
				setPresetCategories(response.data);
			});
	}, []);

	const getServices = () => {
		axios.get('/api/user/manager/services')
			.then(res => res.data)
			.then(data => {
				setServices(data);
			});
	}

	const categories = services.reduce((currentCategories, s) => {
		const categoryIndex = currentCategories.findIndex(c => c.id === s.category_id);
		if (categoryIndex === -1) {
			currentCategories.push(s.category);
		}
		return currentCategories;
	}, [...tmpCategories]);

	const handleNewCategoryAdded = newCategory => {
		axios.post(`/api/barbers/${user.barber.slug}/categories`, newCategory)
			.then(response => {
				setTmpCategories([...tmpCategories, response.data]);
				getServices()
			});
	}

	const handleNewServiceAdded = newService => {
		axios.post(`/api/barbers/${user.barber.slug}/services`, newService)
			.then(response => getServices());
	}

	const handleServiceDeleted = service => {
		axios.delete(`/api/barbers/${user.barber.slug}/services/${service.id}`)
			.then(response => getServices());
	}

	const handleServiceEdited = (service, newProperties) => {
		axios.put(`/api/barbers/${user.barber.slug}/services/${service.id}`, newProperties)
			.then(response => getServices());
	}

	if (!userRoleChecked) {
		return null;
	}

	if (userRole === 'staff') {
		return <>
			<BackButton
				text="Back to dashboard"
				onClick={() => history.push('/')}
			/>
			<section className="section">
				<div className="container">
					<p>You do not have permission to edit services for this Barber.</p>
				</div>
			</section>
		</>
	}

	return (
		<>
			<BackButton
				text="Back to dashboard"
				onClick={() => history.push('/')}
			/>
			<section className="section">
				<div className="container">
					<h1>Manage Services</h1>
					<p>Add, edit and remove your services here.</p>
					<ServicesManager 
						services={services}
						presetCategories={presetCategories}
						categories={categories}
						onCategoryAdd={handleNewCategoryAdded}
						onCategoryEdit={() => false}
						onServiceAdd={handleNewServiceAdded}
						onServiceDelete={handleServiceDeleted}
						onServiceEdit={handleServiceEdited}
					/>
				</div>
			</section>
		</>
	)
}

export default Services
