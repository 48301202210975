import React, { useEffect } from 'react';
import TickIcon from '../Icons/Tick';
import Button from '../UI/Button';

function Step7() {
    const storage = window.sessionStorage;

    useEffect(() => {
        storage.removeItem('step');
        storage.removeItem('token');
    }, []);

	return <div className="hero bg-white">
        {/* <img src="/images/app-home-banner.jpg" alt="" className="bg__image" /> */}
        <div className="hero__content">
            <div className="container">
                <div className="icon-button icon-button--large icon-button--black mb-3 mx-auto">
                    <TickIcon className="icon-button__icon" />
                </div>
                <h1 className="h3">You’re good to go!</h1>
                <p className="mb-4"> And just like that, you’ve completed your Barbashop profile, it’s now time to get booked ✂️💈</p>
                <Button
                    variant="primary"
                    size="full"
                    element="a"
                    href="/merchant-dashboard"
                    arrow
                >
                    Go to your dashboard
                </Button>
            </div>
        </div>
    </div>;
}

export default Step7;
