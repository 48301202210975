import React from 'react'
import GalleryImagesPicker from '../GalleryImagesPicker';
import ProfilePicturePicker from '../ProfilePicturePicker';
import Button from '../UI/Button';

function EditBarberImages({
	user,
	profilePicture,
	setProfilePicture,
	errors,
	handleProfilePicFormSubmit,
}) {
	return <>
		<form onSubmit={handleProfilePicFormSubmit}>
			<h3>Update Profile Picture</h3>
			<ProfilePicturePicker
				currentImage={profilePicture} 
				setCurrentImage={(file) => setProfilePicture(file)}
				errors={errors.profile_picture}
				required={true}
				icon="pencil"
			/>
			<Button
				element="button"
				type="submit"
				variant="secondary"
			>Upload</Button>
		</form>
	</>
}

export default EditBarberImages;