import React from 'react'

function Tab() {
	return (
		<div>
			Tab
		</div>
	)
}

export default Tab
