import trimEnd from 'lodash/trimEnd';
import React from 'react'
import LocationIcon from './Icons/Location'

function BarberDistance({
	className,
	distance,
	suffix,
}) {
	return (
		<div className={`muted-text ${className}`}>
			<LocationIcon className="mr-1" />{trimEnd(distance, ' miles')} miles{suffix}
		</div>
	)
}

export default BarberDistance
