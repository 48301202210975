import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import BarberSearchResults from "./BarberSearch/BarberSearchResults";
import BarberSearchActions from "./BarberSearch/BarberSearchActions";
import BarberSearchFilters from "./BarberSearch/BarberSearchFilters";
import xor from "lodash/xor";
import Nav from "./BarberSearch/Nav";
import StaffPanel from "./BarberSearch/StaffPanel";
import { useHistory } from "react-router-dom";

const initialPriceRange = [5, 75];
const initialMaxTravelDistance = [50];

const BarberSearch = (props) => {
	return <Router basename={props.baseRoute}>
		<BarberSearchInner {...props} />
	</Router>
};

function BarberSearchInner({
    auth,
    postcode,
    prefilledBarber,
    prefilledStaff,
}) {
    const history = useHistory();

    const storage = window.sessionStorage;

    const [starRatings, setStarRatings] = useState([]);
	const [priceRange, setPriceRange] = useState(initialPriceRange);
    const [currentPriceRange, setCurrentPriceRange] = useState(initialPriceRange);
	const [maxTravelDistance, setMaxTravelDistance] = useState(initialMaxTravelDistance);
    const [currentMaxTravelDistance, setCurrentMaxTravelDistance] = useState(initialMaxTravelDistance);

    const [sortOption, setSortOption] = useState(null);

    const [barbers, setBarbers] = useState([]);
    const [selectedBarberId, setSelectedBarberId] = useState(prefilledBarber || null);
    const [selectedStaffId, setSelectedStaffId] = useState(prefilledStaff || null);
    const [prefilledBooking, setPrefilledBooking] = useState({});
    const [loading, setLoading] = useState(true);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [sortingOpen, setSortingOpen] = useState(false);
    const [ratingCounts, setRatingCounts] = useState([]);

    const [searchBoxValue, setSearchBoxValue] = useState('');

    const [userRoleChecked, setUserRoleChecked] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const search = () => {
        setLoading(true);
        const params = new URLSearchParams();
        const query = searchBoxValue || postcode || false;

        if (query) params.append('postcode', query);

        params.append('ratings', starRatings.join());
        params.append('price_range', priceRange.join('-'));
        params.append('max_distance', maxTravelDistance[0]);

        if (sortOption !== null) {
            params.append('sort', sortOption);
        }

        return axios.get('/api/barbers', { params })
            .then(res => {
                setBarbers(res.data.data);
                setRatingCounts(res.data.rating_counts);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    alert('We couldn\'t find that postcode - please try another search.');
                } else {
                    alert('Whoops - something went wrong! Please try another search.');
                }
                setBarbers([]);
            })
            .finally(() => setLoading(false));
    };

    const handleSearchBoxValueChange = e => {
        setSearchBoxValue(e.detail);
    };

    const handleStarRatingChange = value => {
        const newStarRatings = xor(starRatings, [value]);
        setStarRatings([...newStarRatings]);
    }

    const closePanel = e => {
        history.push(`/barbers/${selectedBarberId}`);
        setSelectedStaffId(null);
    }

    useEffect(() => {
        if (auth === 'true') {
            const loginSession = storage.getItem('booking_login_session');

            if (loginSession !== null) {
                const data = JSON.parse(loginSession);
                storage.removeItem('booking_login_session')
    
                setSelectedBarberId(data.barber_id);
                setPrefilledBooking(data);
            }
        }

        // listen for changes on the search box
        document.addEventListener('postcode-search', handleSearchBoxValueChange);
    }, []);

    useEffect(() => {
        axios.get('/api/check-role')
            .then(response => {
                setUserRoleChecked(true);
                setUserRole(response.data);
            });
    }, []);

    useEffect(() => {
        if (selectedStaffId === null) {
            search();
        }
        setSortingOpen(false);
    }, [
        selectedStaffId,
        starRatings,
        priceRange,
        maxTravelDistance,
        searchBoxValue,
        sortOption,
    ]);

	const staffPanelRef = React.createRef();

    return <div>
        <BarberSearchFilters 
            visible={filtersOpen}
            setVisible={setFiltersOpen}
            onUpdate={() => setFiltersOpen(false)}
            handleStarRatingChange={handleStarRatingChange}
            setPriceRange={setPriceRange}
            currentPriceRange={currentPriceRange}
            setCurrentPriceRange={setCurrentPriceRange}
            setMaxTravelDistance={setMaxTravelDistance}
            currentMaxTravelDistance={currentMaxTravelDistance}
            setCurrentMaxTravelDistance={setCurrentMaxTravelDistance}
            ratingCounts={ratingCounts}
        />
        <BarberSearchActions 
            count={barbers.length} 
            setFiltersOpen={setFiltersOpen}
            sortingOpen={sortingOpen}
            setSortingOpen={setSortingOpen}
            sortOption={sortOption}
            setSortOption={setSortOption}
            auth={auth}
            postcode={postcode}
            searchBoxValue={searchBoxValue}
        />
        <BarberSearchResults 
            barberId={selectedBarberId}
            setBarberId={(id) => setSelectedBarberId(id)} 
            setStaffId={(id) => setSelectedStaffId(id)} 
            barbers={barbers} 
            loading={loading}
            sortingOpen={sortingOpen}
        />
        <StaffPanel
            auth={auth}
            staffId={selectedStaffId}
            barberId={selectedBarberId}
            clearStaffId={() => setSelectedStaffId(null)}
            panelRef={staffPanelRef}
            closePanel={closePanel}
        />
        {auth && userRoleChecked && userRole === 'customer' && <Nav />}
    </div>
}

export default BarberSearch;
