import React from 'react'

function PencilIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M10.4551 5.09789L8.90197 3.54474L2.33695 10.1098L1.63252 12.3673L3.89011 11.6629L10.4551 5.09789ZM11.0208 4.5322L9.46765 2.97905L11.0298 1.41687L12.583 2.97002L11.0208 4.5322ZM13.1775 3.50692C13.474 3.2104 13.474 2.72964 13.1775 2.43311L11.5667 0.822399C11.2702 0.525875 10.7895 0.525875 10.4929 0.822399L1.68515 9.63019C1.62235 9.69299 1.58355 9.77582 1.57551 9.86427L0.601451 12.9859C0.579986 13.2221 0.777796 13.4199 1.01391 13.3984L4.13559 12.4243C4.22404 12.4163 4.30687 12.3775 4.36967 12.3147L13.1775 3.50692Z"/>
		</svg>
	)
}

export default PencilIcon