import axios from 'axios';
import React, { useState } from 'react'
import { useHistory } from 'react-router';
import Button from '../UI/Button';
import TextField from '../UI/TextField';

function CloseAccount() {
	const history = useHistory();
	
	const [password, setPassword] = useState('');

	const [processing, setProcessing] = useState(false);
	const [errors, setErrors] = useState({});

	const handleFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		axios.post('/api/user/delete', { password })
			.then(response => {
				window.location.href = '/';
			})
			.catch(error => {
				setErrors(error.response.data.errors);
			})
			.finally(() => setProcessing(false));
	}

	return <>
		<h1 className="h3 mb-2">Close Account</h1>
		<hr />
		<p className="sml mt-2">Confirm your password and click 'Close' below to close your account</p>
		<form onSubmit={handleFormSubmit}>
			<TextField
				type="password"
				name="password"
				value={password} 
				onChange={(e) => setPassword(e.target.value)}
				errors={errors.password}
				placeholder="********"
			/>
			<div>
				<Button
					type="submit"
					size="small-width"
					className="mr-1"
					disabled={processing}
				>
					Close account
				</Button>
				<Button
					type="button"
					size="small-width"
					variant="secondary"
					onClick={() => history.push('/')}
				>
					Cancel
				</Button>
			</div>
		</form>
	</>
}

export default CloseAccount
