import React from 'react'

function Sheet({
	children,
	isOpen,
	content,
	onClick,
	removePadding,
}) {

	const handleInnerClick = e => {
		e.stopPropagation();
	}

	return (
		<>
			{children}
			{isOpen &&
				<div className="sheet" onClick={onClick}>
					<div className="sheet__container">
						<div className="sheet__inner" onClick={handleInnerClick}>
							<div className="container">
								<div className="text__align--right">
									<button className="sheet__close-button" onClick={onClick}>Skip</button>
								</div>
								<div className={`sheet__content ${removePadding ? 'p-0' : ''}`}>
									{content}
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default Sheet
