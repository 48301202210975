import React from 'react'

function CalendarIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M14.3999 6H1.59993V14.4H14.3999V6ZM14.3999 5.2H1.59993V2.4H14.3999V5.2ZM15.1999 2.4C15.1999 1.95817 14.8418 1.6 14.3999 1.6H1.59993C1.1581 1.6 0.799927 1.95817 0.799927 2.4V14.4C0.799927 14.8418 1.1581 15.2 1.59993 15.2H14.3999C14.8418 15.2 15.1999 14.8418 15.1999 14.4V2.4Z" />
			<path fillRule="evenodd" clipRule="evenodd" d="M4.39993 4V0H5.19993V4H4.39993ZM10.7999 4V0H11.5999V4H10.7999Z" />
		</svg>
	)
}

export default CalendarIcon