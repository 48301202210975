import React, { useState } from 'react'
import { Switch, Route } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const openPanelStyles = {
    opacity: 1,
    x: 0,
    overflow: 'auto',
    transform: ""
};

const closedPanelStyles = {
    opacity: 0,
    x: "100%",
    overflow: 'auto',
    transform: ""
};

const transitionStyles = {
    transition: {
        ease: 'circOut',
        duration: 0.35,
    }
};

function Panel({
	path,
	condition,
	children,
	className,
	panelRef,
	innerRef,
}) {
	return (
		<AnimatePresence>
			<Switch>
				{condition && <Route path={path}>
					<motion.div
						initial={closedPanelStyles}
						animate={{
							...openPanelStyles,
							...transitionStyles,
						}}
						exit={{
							...closedPanelStyles,
							...transitionStyles,
						}}
						className={`panel ${className}`}
						ref={panelRef}
					>
						<div className="panel__inner" ref={innerRef}>
							{children}
						</div>
					</motion.div>
				</Route>}
			</Switch>
		</AnimatePresence>
	)
}

export default Panel
