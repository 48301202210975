import React from 'react'

function ChevronLeftIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="6" height="12" viewBox="0 0 6 12" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M1.16558 5.99999L5.68274 1.48283L5.11705 0.917145L0.0342102 5.99999L5.11705 11.0828L5.68274 10.5171L1.16558 5.99999Z" />
		</svg>
	)
}

export default ChevronLeftIcon
