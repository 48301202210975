import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import ActionBar from '../ActionBar';
import EditBarberAvailability from '../EditBarberAvailability';

function Step5({ token, setStep }) {
	const history = useHistory();

	const [errors, setErrors] = useState({});

	const handleFormSubmit = (e, availability) => {
		e.preventDefault();

		axios.post('/api/barbers/register/availability', { token, availability })
			.then(res => {
				setStep(6);
				history.push('/billing');
			})
			.catch(error => {
				setErrors(error.response.data.errors);
			});
	};

	return (
		<EditBarberAvailability
			formFooter={({ availability }) => <ActionBar
				title="Step 5 of 6"
				subTitle="Set your availability"
				buttonText="Next"
				onClick={e => handleFormSubmit(e, availability)}
			/>}
		/>
	);
}

export default Step5;
