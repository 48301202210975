import React, { createRef, useEffect, useState } from 'react'
import { Redirect, useRouteMatch } from 'react-router';
import formatDate from '../../utils/formatDate';
import timestampToDate from '../../utils/timestampToDate';
import CrossIcon from '../Icons/Cross';
import Panel from '../UI/Panel';
import BarberRating from '../BarberRating';
import Button from '../UI/Button';
import axios from 'axios';
import BarberAvatar from '../UI/BarberAvatar';
import LoadingSpinner from '../UI/LoadingSpinner';
import ReloadIcon from '../Icons/Reload';
import confirmAlert from '../../utils/confirmAlert';
import googleMapsUrl from '../../utils/googleMapsUrl';

function UpcomingBookingPanel({
	booking,
	onClose,
	getBookings,
	clearBooking,
}) {
	const { path } = useRouteMatch();
	const panelRef = createRef();

	const [bookingCancelled, setBookingCancelled] = useState(false);
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (bookingCancelled) {
			clearBooking();
			getBookings();
		}
	}, [bookingCancelled])

	const handleCancellationClick = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		confirmAlert({
			title: 'Cancel Booking?',
			message: 'Are you sure you want to cancel this booking?',
			accept: () => {
				axios.patch(`/api/user/customer/bookings/${booking.id}/cancel/`)
					.then(response => {
						alert('Booking cancelled.');
						setBookingCancelled(true);
					})
					.catch(error => alert(error.response.data.message))
					.finally(() => setProcessing(false));
			},
			decline: () => setProcessing(false),
		});
	}

	if (!booking) return null;

	return <Panel
		path={`${path}/upcoming-bookings/:id`}
		condition={booking !== null}
		panelRef={panelRef}
		className="booking-panel"
	>
		<>
			<header className="booking-panel__top">
				<div className="container">
					<div className="d-flex justify-end">
						<button className="icon-button icon-button--white" onClick={onClose}>
							<CrossIcon className="icon-button__icon" />
						</button>
					</div>
					<h3>{formatDate(timestampToDate(booking.date))}</h3>
					<p className="mb-4"><span className={`status-badge status-badge--${booking.status_text.toLowerCase()}`}>{booking.status_text}</span></p>
					<div className="d-flex justify-between mb-4">
						<div className="mr-3">
							<p className="mb-1"><strong>{booking.barber.name}</strong></p>
							<p className="mb-1 muted-text muted-text--light">At {booking.address}</p>
							<p className="mb-1 muted-text muted-text--light"><a className="booking-panel__directions-link" href={googleMapsUrl(booking.address)} target="_blank">Get Directions</a></p>
							<p className="mb-0 muted-text muted-text--light">Booking Ref: {booking.id}</p>
						</div>
						<div className="flex-shrink-0">
							<BarberAvatar barber={booking.barber} size="large" className="mb-1" />
							<BarberRating
								rating={booking.barber.rating}
								className="text__align--center"
							/>
						</div>
					</div>
					{booking.status !== BOOKING_STATUS.cancelled && booking.status !== BOOKING_STATUS.rejected &&
						<Button
							size="full"
							className="booking-panel__action-button"
							onClick={handleCancellationClick}
							disabled={processing}
						>
							{processing ? <LoadingSpinner /> : <>
								Cancel
								<CrossIcon className="booking-panel__action-button-icon" />
							</>}
						</Button>
					}

					{(booking.status === BOOKING_STATUS.cancelled || booking.status === BOOKING_STATUS.rejected) &&
						<Button
							className="booking-panel__action-button button--small-width"
							element="a"
							href={`/barbers/${booking.barber.slug}`}
						>
							Rebook
							<ReloadIcon className="booking-panel__action-button-icon" />
						</Button>
					}
				</div>
			</header>
			<div className="booking-panel__middle">
				<div className="container">
					<table className="booking-pricing-table">
						<tbody>
							{booking.services.map(s => {
								return (
									<tr key={`booking_${booking.id}_service_${s.id}`} className="booking-pricing-table__row">
										<td className="booking-pricing-table__cell">
											<div>{s.name} x {s.quantity}</div>
											<div className="muted-text muted-text--light">{s.total_duration} mins</div>
										</td>
										<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
											<div>£{s.gross_price}</div>
										</td>
									</tr>
								)
							})}
						</tbody>
						<tfoot>
							<tr className="booking-pricing-table__row booking-pricing-table__row--last">
								<td className="booking-pricing-table__cell"><strong>Total</strong></td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									<strong>£{booking.gross_price}</strong>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div className="booking-panel__bottom">
				<div className="container">
					<h6>Important info</h6>
					<p>As a courtesy to the business please avoid cancelling within 48 hours of your appointment. We do charge for late cancellations.</p>
					<h6>Cancellation policy</h6>
					<p>Cancel for free up to <strong>48 hours</strong> ahead, otherwise the <strong>£5</strong> deposit will be taken and service fee price for late cancellation.</p>
				</div>
			</div>
		</>
	</Panel>
}

export default UpcomingBookingPanel
