import React from 'react'

function ArrowLeftIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="15" height="8" viewBox="0 0 15 8" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.31837 0.515808L4.88135 1.08419L2.36979 3.57184L14.2008 3.6L14.1989 4.4L2.36138 4.37182L4.88404 6.9185L4.31568 7.4815L0.834167 3.96684L4.31837 0.515808Z"/>
		</svg>
	)
}

export default ArrowLeftIcon