import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CrossIcon from '../Icons/Cross';
import BackButton from '../UI/BackButton';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import Modal from '../UI/Modal';
import TextField from '../UI/TextField';
import SelectField from '../UI/SelectField';
import confirmAlert from '../../utils/confirmAlert';
import PencilIcon from '../Icons/Pencil';
import SwitchField from '../UI/SwitchField';

function StaffManager() {

	const [staff, setStaff] = useState([]);
	const [roles, setRoles] = useState([]);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [roleId, setRoleId] = useState('');
	
	const [staffLoaded, setStaffLoaded] = useState(false);
	const [rolesLoaded, setRolesLoaded] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [errors, setErrors] = useState({});
	const [addStaffModalOpen, setAddStaffModalOpen] = useState(false);
	const [activeStaffEditId, setActiveStaffEditId] = useState(null);

	useEffect(() => {
		axios.get('/api/user/manager/staff')
			.then(response => {
				setStaff(response.data);
				setStaffLoaded(true);
			});
		axios.get('/api/user/manager/staff/roles')
			.then(response => {
				setRoles(response.data.reverse());
				setRolesLoaded(true);
			});
	}, []);

	useEffect(() => {
		if (roles.length && rolesLoaded) {
			setRoleId(roles[0].id);
		}
	}, [rolesLoaded, roles]);

	const handleAddStaffSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		const payload = new FormData();

		payload.append('first_name', firstName);
		payload.append('last_name', lastName);
		payload.append('email', email);
		payload.append('phone', phone);
		payload.append('password', password);
		payload.append('password_confirmation', passwordConfirm);
		payload.append('role_id', roleId);

		axios.post('/api/user/manager/staff', payload)
			.then(response => {
				setStaff([...staff, response.data]);
				alert('Staff Member added!');
				setAddStaffModalOpen(false);
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while adding this staff member.');
			})
			.finally(() => setProcessing(false));
	}

	const handleStaffDeleteButtonClick = (e, staff) => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		confirmAlert({
			title: 'Delete Staff Member?',
			message: 'Are you sure you want to delete this staff member?',
			accept: () => {
				axios.delete(`/api/user/manager/staff/${staff.slug}`)
					.then(response => {
						alert('Account deleted.');
						setStaff(response.data);
					})
					.catch(error => alert(error.response.data.message))
					.finally(() => setProcessing(false));
			},
			decline: () => setProcessing(false),
		});
	}

	const handleStaffBookableSwitchChange = (staffId, bookable) => {
		if (processing) return false;
		setProcessing(true);

		const endpoint = bookable ? 'bookable' : 'unbookable';

		axios.patch(`/api/user/manager/staff/${staffId}/${endpoint}`)
			.then(response => {
				setStaff(response.data);
			})
			.catch(errors => {
				alert('An error occurred while updating this staff member.');
			})
			.finally(() => setProcessing(false));
	}

	if (!staffLoaded || !rolesLoaded) {
		return <>
			<BackButton
				text="Back to dashboard"
				onClick={() => history.push('/')}
			/>
			<section className="section">
				<div className="container">
					<LoadingSpinner center className="d-block" />
				</div>
			</section>
		</>
	}

	const addStaffForm = <form onSubmit={handleAddStaffSubmit} action="" method="POST">
		<TextField
			name="first_name"
			label="First Name"
			value={firstName}
			onChange={(e) => setFirstName(e.target.value)}
			placeholder="First name"
			required
			errors={errors?.first_name}
		/>
		<TextField
			name="last_name"
			label="Last Name"
			value={lastName}
			onChange={(e) => setLastName(e.target.value)}
			placeholder="Last name"
			required
			errors={errors?.last_name}
		/>
		<TextField
			name="email"
			type="email"
			label="Email"
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			placeholder="Email Address"
			required
			errors={errors?.email}
		/>
		<TextField
			name="contact_number"
			type="tel"
			label="Contact Number"
			value={phone}
			onChange={(e) => setPhone(e.target.value)}
			placeholder="07123 456 789"
			required
			errors={errors?.phone}
		/>
		<SelectField
			name="role_id"
			label="Role"
			required
			value={roleId} 
			onChange={e => setRoleId(e.target.value)}
			errors={errors?.role_id}
		>
			{roles.map(r => <option value={r.id} key={r.id}>
				{r.name.charAt(0).toUpperCase() + r.name.slice(1)}
			</option>)}
		</SelectField>
		<hr className="mt-3 mb-2"/>
		<TextField
			name="password"
			type="password"
			label="Password"
			value={password}
			onChange={(e) => setPassword(e.target.value)}
			placeholder="*****"
			required
			errors={errors?.password}
			inputProps={{
				autoComplete: 'new-password'
			}}
		/>
		<TextField
			name="password_confirm"
			type="password"
			label="Confirm Password"
			value={passwordConfirm}
			onChange={(e) => setPasswordConfirm(e.target.value)}
			placeholder="*****"
			required
			inputProps={{
				autoComplete: 'new-password'
			}}
		/>
		<Button
			element="button"
			type="submit"
			disabled={processing}
		>
			{processing ? <LoadingSpinner color="white" size="small" /> : 'Add'}	
		</Button>
	</form>;

	return <>
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className="section">
			<div className="container">
				<h1>Manage Staff</h1>
				<ul className="staff-manager">
					{staff.map(s => {
						return <li key={s.id} className="staff-manager__row">
							<div>
								<div>{s.first_name} {s.last_name}</div>
								<div className="muted-text">
									{s.is_owner ? 'Account Owner' : ''}
									{s.is_manager && !s.is_owner ? 'Manager' : ''}
									{s.is_staff ? 'Staff' : ''}
									<div className={`indicator indicator--${s.bookable ? 'success' : 'danger'}`}>
										<span className="indicator__dot"></span>
										<span className="indicator__text">{s.bookable ? 'Bookable' : 'Not Bookable'}</span>
									</div>
								</div>
								<div className="muted-text muted-text--light">{s.email}</div>
							</div>
							<div>
								<Modal
									title="Edit Staff Member"
									subtitle={`${s.full_name}`}
									onClick={() => setActiveStaffEditId(null)}
									content={<>
										<SwitchField
											checked={s.bookable}
											onChange={e => handleStaffBookableSwitchChange(s.slug, e.target.checked)}
											label="Available for bookings?"
										/>
										{!s.is_owner &&
											<button 
												className="staff-manager__delete-button" 
												onClick={e => handleStaffDeleteButtonClick(e, s)}
											>
												<span className="icon-button icon-button--red mr-1"><CrossIcon className="icon-button__icon" /></span>
												Delete this staff member
											</button>
										}
									</>}
									isOpen={activeStaffEditId === s.id}
								>
									<button 
										className="staff-manager__action-button" 
										onClick={e => setActiveStaffEditId(s.id)}
									>
										<PencilIcon className="staff-manager__action-button-icon" />
									</button>
								</Modal>
							</div>
						</li>
					})}
				</ul>
				<Modal
					isOpen={addStaffModalOpen}
					title="Add Staff Member"
					subtitle=""
					onClick={() => setAddStaffModalOpen(false)}
					content={addStaffForm}
				>
					<Button
						onClick={() => setAddStaffModalOpen(true)}
						type="button"
						element="button"
					>
						Add Staff
					</Button>
				</Modal>
			</div>
		</section>
	</>
}

export default StaffManager;