import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';

import BackButton from '../UI/BackButton';
import LoadingSpinner from '../UI/LoadingSpinner';
import PaymentMethod from '../PaymentMethod';
import { useHistory } from 'react-router';
import PlusIcon from '../Icons/Plus';

function PaymentDetails() {
	const stripe = useStripe();
	const elements = useElements();
	const history = useHistory();

	const [methods, setMethods] = useState([]);
	const [methodsLoading, setMethodsLoading] = useState(true);

	const [currentMethod, setCurrentMethod] = useState(false);
	const [newMethod, setNewMethod] = useState(false);
	const [working, setWorking] = useState(false);
	const [newName, setNewName] = useState('');
	const [deleting, setDeleting] = useState(false);


	const getMethods = () => {
		setMethods([]);
		setMethodsLoading(true);

		axios.get('/api/user/customer/methods')
			.then(res => setMethods(res.data.data))
			.then(() => setMethodsLoading(false));
	};

	const updateOrCreateMethod = async () => {
		if (!stripe || !elements) return;

		setWorking(true);

		const cardNumberElement = elements.getElement(CardNumberElement);
	
		// use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardNumberElement,
			billing_details: {
				name: newName
			}
		});
	
		if (error) {
			console.log('[error]', error);
		} else {
			if (currentMethod) {
				axios.delete(`/api/user/customer/methods/${currentMethod.id}`).then((res) => {
					if (res.data.deleted) {
						axios.post(`/api/user/customer/methods/${paymentMethod.id}`)
							.then(() => { setCurrentMethod(false); setNewMethod(false); })
							.then(() => { getMethods(); setWorking(false); });
					}
				});
			} else {
				axios.post(`/api/user/customer/methods/${paymentMethod.id}`)
					.then(() => { setCurrentMethod(false); setNewMethod(false); })
					.then(() => { getMethods(); setWorking(false); });
			}
		}
	};

	// delete a payment method
	const deletePaymentMethod = (id) => {
		setDeleting(true);

		axios.delete(`/api/user/customer/methods/${id}`)
			.then(() => { setCurrentMethod(false); setNewMethod(false); })
			.then(() => setDeleting(false))
			.then(() => getMethods());
	};

	// initally load payment methods
	useEffect(getMethods, []);

	// index view
	let content = <div className="container">
		<h1 className="h3 mb-1">Your wallet</h1>
		<p className="sml mb-4">Manage your payment details</p>
		<h2 className="h4 mb-1">Payment methods</h2>
		<div className="mb-4">
			{methodsLoading && <div className="loading-spinner__wrap">
				<LoadingSpinner color="black" size="normal" />
			</div>}
			{methods.map(method => <PaymentMethod 
				key={method.id} 
				method={method} 
				setMethod={() => { setCurrentMethod(method); setNewName(method.billing_name) }} 
			/>)}
			{!methodsLoading && methods.length === 0 && <p className="sml my-2">You have no payment methods.</p>}
		</div>
		<h2 className="h3 mb-1">Add payment method</h2>
		<p className="sml">Click below to add another payment method</p>
		<div>
			<button type="button" onClick={() => setNewMethod(true)} className="button button--small-width mr-1">
				<span>Add method</span>
				<PlusIcon className="ml-1" />
			</button>
		</div>
	</div>;

	// show the payment details form if creating or updating a card
	if (currentMethod || newMethod) {
		const style = {
			style: {
				base: {
					'::placeholder': {
						color: '#8B90A0'
					}
				}
			}
		};

		content = <div className="container">
			<h1 className="h3 mb-1">{currentMethod ? 'Payment details' : 'Add new method'}</h1>
			<p className="sml mb-4">Manage your payment details</p>
			<div className="mb-1">Card Information</div>
			<div className="text-input text-input--no-fixed-height mb-2">
				<CardNumberElement options={style} />
			</div>
			<div className="text-input__group mb-2">
				<div className="text-input text-input--no-fixed-height mr-1">
					<CardExpiryElement options={style} />
				</div>
				<div className="text-input text-input--no-fixed-height ml-1">
					<CardCvcElement options={style} />
				</div>
			</div>
			<input type="text" className="text-input mb-4" value={newName} onChange={(e) => setNewName(e.target.value)} placeholder="Mr John H Doe" />
			<div className="mb-2">
				<button type="button" className="button button--small-width mr-1" onClick={() => updateOrCreateMethod()}>{working ? <LoadingSpinner color="white" size="small" /> : 'Update'}</button>
				<button type="button" className="button button--small-width button--secondary" onClick={() => { setCurrentMethod(false); setNewMethod(false); }}>Cancel</button>
			</div>
			{currentMethod && <div className="payment-method__delete" onClick={() => deletePaymentMethod(currentMethod.id)}>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="12" cy="12" r="11.5" stroke="#E21111"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M11.4342 12L7.71704 8.28285L8.28273 7.71716L11.9999 11.4343L15.717 7.71716L16.2827 8.28285L12.5656 12L16.2827 15.7172L15.717 16.2828L11.9999 12.5657L8.28273 16.2828L7.71704 15.7172L11.4342 12Z" fill="#E21111"/>
				</svg>
				<span>{deleting ? 'Deleting...' : 'Delete payment method'}</span>
				{deleting && <LoadingSpinner color="black" size="small" />}
			</div>}
		</div>;
	}

	return (
		<>
			<BackButton
				text={currentMethod || newMethod ? 'Back to payment methods' : 'Back to dashboard'}
				onClick={currentMethod || newMethod ? () => { setCurrentMethod(false); setNewMethod(false); } : () => history.push('/')}
			/>
			<section className="section">
				{content}
			</section>
		</>
	);
}

export default PaymentDetails
