import React, { useState } from 'react'
import ClockIcon from '../Icons/Clock';
import CrossIcon from '../Icons/Cross'
import PencilIcon from '../Icons/Pencil'
import Modal from '../UI/Modal';
import DeleteService from './DeleteService';
import EditService from './EditService';

function Service({
	categories,
	service,
	onServiceDelete,
	onServiceEdit,
}) {

	const [deleteFormOpen, setDeleteFormOpen] = useState(false);
	const [editFormOpen, setEditFormOpen] = useState(false);

	return (
		<li className="service-list__item services-manager__service-list-item">
			<Modal
				isOpen={deleteFormOpen}
				title="Are you sure you want to remove this service?"
				onClick={() => setDeleteFormOpen(false)}
				content={<DeleteService
					onDelete={onServiceDelete}
					onCancel={() => setDeleteFormOpen(false)}
				/>}
			>
				<button 
					className="services-manager__action-button" 
					onClick={() => setDeleteFormOpen(true)}
				>
					<CrossIcon className="services-manager__action-button-icon text__color--danger" />
				</button>
			</Modal>
			<div className="service-list__title">
				<div className="service-list__name">{service.name}</div>
				<div className="muted-text muted-text--light service-list__duration">
					<ClockIcon className="service-list__clock-icon" /> {service.duration} mins
				</div>
			</div>
			<div className="service-list__price">
				£{service.price}
			</div>
			<Modal
				isOpen={editFormOpen}
				title="Edit Service"
				onClick={() => setEditFormOpen(false)}
				content={<EditService
					service={service}
					categories={categories}
					setEditFormOpen={setEditFormOpen}
					onServiceEdit={onServiceEdit}
					onCancel={() => setEditFormOpen(false)}
				/>}
			>
				<button 
					className="services-manager__action-button" 
					onClick={() => setEditFormOpen(true)}
				>
					<PencilIcon className="services-manager__action-button-icon" />
				</button>
			</Modal>
		</li>
	)
}

export default Service
