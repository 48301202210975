import React from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import ChevronRightIcon from './Icons/ChevronRight';
import BackButton from './UI/BackButton'
import LoadingSpinner from './UI/LoadingSpinner';
import NotificationSettings from './MerchantDashboard/NotificationSettings';

function AccountSettings({
	user,
	setUser,
	userLoaded,
}) {
	const history = useHistory();
	let { path, url } = useRouteMatch();

	const goBackToSettings = () => history.push(path);

	if (!userLoaded) {
		return <section className="section">
			<div className="container text__align--center">
				<LoadingSpinner center />
			</div>
		</section>;
	}

	return (
		<Switch>
			<Route path={`${path}/notification-settings`}>
				<NotificationSettings
					user={user}
					setUser={setUser}
					goBackToSettings={goBackToSettings}
				/>
			</Route>
			<Route>
				<>
					<BackButton
						text="Back to dashboard"
						onClick={() => history.push('/')}
					/>
					<section className="section">
						<div className="container">
							<h1>Settings</h1>
							<p>Manage your account settings</p>
							<ul className="arrow-links">
								<li className="arrow-links__item">
									<Link to={`${path}/notification-settings`} className="arrow-links__link">
										Notification Settings
										<ChevronRightIcon className="arrow-links__icon" />
									</Link>
								</li>
								<li className="arrow-links__item">
									<a href="/booking-terms" className="arrow-links__link" target="_blank">
										Booking Terms
										<ChevronRightIcon className="arrow-links__icon" />
									</a>
								</li>
								<li className="arrow-links__item">
									<a href="/privacy-policy" className="arrow-links__link" target="_blank">
										Privacy Policy
										<ChevronRightIcon className="arrow-links__icon" />
									</a>
								</li>
							</ul>
						</div>
					</section>
				</>
			</Route>
		</Switch>
	)
}

export default AccountSettings
