import { useState } from "react";
import { useHistory } from "react-router-dom";
import BarberAvatar from "../UI/BarberAvatar";
import LoadingSpinner from "../UI/LoadingSpinner";

const BarberStaffList = ({
	staff,
	barberId,
	setStaffId,
}) => {
	const history = useHistory();

	return <ul className="barber-staff-list">
		{staff.filter(s => s.bookable && s.availability).map(s => (
			<li 
				key={s.id}
				className="barber-staff-list__item"
				onClick={() => {
					setStaffId(s.slug);
					history.push(`/barbers/${barberId}/${s.slug}`)
				}}
			>
				<BarberAvatar barber={s} className="barber-staff-list__avatar" />
				{s.full_name}
			</li>
		))}
	</ul>;
}
 
export default BarberStaffList;