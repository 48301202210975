import React from 'react'
import CalendarIcon from '../Icons/Calendar'
import SearchIcon from '../Icons/Search'
import SettingsIcon from '../Icons/Settings'
import UserIcon from '../Icons/User'

function Nav() {
	return (
		<nav className="bottom-nav">
			<div className="container">
				<ul className="bottom-nav__list">
					<li className="bottom-nav__item">
						<a 
							className="bottom-nav__link is-current" 
							href="/search"
						>
							<SearchIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Search</span>
						</a>
					</li>
					<li className="bottom-nav__item">
						<a 
							className="bottom-nav__link" 
							href="/customer-dashboard/manage-bookings"
						>
							<CalendarIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">My Bookings</span>
						</a>
					</li>
					<li className="bottom-nav__item">
						<a 
							className="bottom-nav__link" 
							href="/customer-dashboard"
						>
							<UserIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Profile</span>
						</a>
					</li>
					<li className="bottom-nav__item">
						<a 
							className="bottom-nav__link" 
							href="/customer-dashboard/account-settings"
						>
							<SettingsIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Settings</span>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default Nav
