import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ActionBar from '../ActionBar';
import EditBarberLocation from '../EditBarberLocation';

function Step2({
	token,
	setStep,
}) {
	const history = useHistory();

	const [errors, setErrors] = useState({});

	const handleFormSubmit = e => {
		e.preventDefault();
		
		const formData = new FormData(e.target);
		formData.append('token', token);

		axios.post('/api/barbers/register/address', formData)
			.then(res => {
				setStep(3);
				history.push('/your-details');
			})
			.catch(error => {
				setErrors(error.response.data.errors);
			});
	};

	return (
		<EditBarberLocation
			onSubmit={handleFormSubmit}
			errors={errors}
			formFooter={({addressConfirmed}) => {
				return <ActionBar
					title="Step 2 of 6"
					subTitle="Find your shop"
					buttonText="Next"
					disabled={!addressConfirmed}
				/>
			}}
		/>
	);
}

export default Step2;
