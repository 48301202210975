import { CardCvcElement, CardExpiryElement, CardNumberElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CrossIcon from './Icons/Cross'
import PaymentMethod from './PaymentMethod'
import Button from './UI/Button'
import LoadingSpinner from './UI/LoadingSpinner'
import TextField from './UI/TextField'
import alert from '../utils/alert';

const style = {
	style: {
		base: {
			'::placeholder': {
				color: '#8B90A0'
			}
		}
	}
};

function PaymentForm({
	paymentIntentSecret,
	onComplete,
	acceptsVouchers,
	handleVoucherFormSubmit,
	currentVoucherCode,
	setCurrentVoucherCode,
	checkingVoucher,
	actionName,
	displayDescription,
	displayAmount,
}) {
	const stripe = useStripe();
	const elements = useElements();

	const [paymentMethodName, setPaymentMethodName] = useState('');
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [paymentRequest, setPaymentRequest] = useState(null);
	const [showCardModal, setShowCardModal] = useState(false);
	const [processingPayment, setProcessingPayment] = useState(false);
	const [methods, setMethods] = useState([]);

	useEffect(() => {
		axios.get('/api/user/customer/methods')
			.then(res => setMethods(res.data.data))
			.catch((err) => {});
	}, []);

	useEffect(() => {
		if (!stripe || !displayAmount) return false;

		if (!paymentRequest) {
			const pr = stripe.paymentRequest({
				country: 'GB',
				currency: 'gbp',
				total: {
					label: displayDescription,
					amount: displayAmount,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			// Check the availability of the Payment Request API.
			pr.canMakePayment().then(result => {
				if (result) {
					setPaymentRequest(pr);
				}
			});
		} else {
			paymentRequest.update({
				total: {
					label: displayDescription,
					amount: displayAmount,
				},
			});
		}
	}, [stripe, displayAmount]);

	const handlePaymentRequestButtonClicked = () => {
		paymentRequest.on('paymentmethod', handlePaymentMethodReceived);
		paymentRequest.on('cancel', () => {
			paymentRequest.off('paymentmethod');
		});
	}

	const handlePaymentMethodReceived = async (ev) => {

		if (!stripe || processingPayment) return;

		setProcessingPayment(true);

		// Confirm the PaymentIntent without handling potential next actions (yet).
		const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
			paymentIntentSecret,
			{payment_method: ev.paymentMethod.id},
			{handleActions: false}
		);
		
		if (confirmError) {
			// Report to the browser that the payment failed, prompting it to
			// re-show the payment interface, or show an error message and close
			// the payment interface.
			ev.complete('fail');
			setProcessingPayment(false);
		} else {
			// Report to the browser that the confirmation was successful, prompting
			// it to close the browser payment method collection interface.
			ev.complete('success');
		
			// Check if the PaymentIntent requires any actions and if so let Stripe.js
			// handle the flow. If using an API version older than "2019-02-11"
			// instead check for: `paymentIntent.status === "requires_source_action"`.
			if (paymentIntent.status === "requires_action") {
				// Let Stripe.js handle the rest of the payment flow.
				const {error} = await stripe.confirmCardPayment(paymentIntentSecret);

				if (error) {
					// The payment failed -- ask your customer for a new payment method.
					alert(result.error.message);
					setProcessingPayment(false);
				} else {
					// The payment has succeeded.
					onComplete();
				}
			} else {
				// The payment has succeeded.
				onComplete();
			}
		}
	}

	const getPaymentMethod = () => {
		if (selectedPaymentMethod) {
			return selectedPaymentMethod.id;
		}
		return {
			card: elements.getElement(CardNumberElement),
			billing_details: {
				name: paymentMethodName,
			},
		}
	}

	const pay = () => {
		if (!stripe || processingPayment) return;

		setProcessingPayment(true);

		const config = {
			payment_method: getPaymentMethod(),
			setup_future_usage: 'off_session',
		};
	  
		stripe.confirmCardPayment(paymentIntentSecret, config).then(result => {
			if (result.error) {
				alert(result.error.message);
				setProcessingPayment(false);
			} else {
				if (result.paymentIntent.status === 'succeeded') {
					onComplete();
				} else {
					setProcessingPayment(false);
					alert('An error occurred');
				}
			}
		});
	};

	return (
		<div className="button-options">
			{paymentRequest && <>
				<PaymentRequestButtonElement options={{paymentRequest}} onClick={handlePaymentRequestButtonClicked} />
				<div className="button-options__divider">
					<span className="button-options__divider-text">or</span>
				</div>
			</>}
			<Button className="button-options__button mb-4" onClick={() => setShowCardModal(true)}>
				{actionName} with card
			</Button>
			{acceptsVouchers &&
				<form onSubmit={handleVoucherFormSubmit}>
					<TextField
						name="voucher_code"
						label="Got a voucher? Enter your code here!"
						value={currentVoucherCode}
						onChange={e => setCurrentVoucherCode(e.target.value)}
						required
						inputProps={{
							disabled: checkingVoucher,
						}}
						buttonRight={<Button
							type="submit" 
							size="small-width" 
							className="py-0"
							disabled={checkingVoucher}
						>Go</Button>}
					/>
				</form>
			}
			{showCardModal && <div className="booking-card-modal">
				<header className="booking-card-modal__header">
					<h3 className="mb-0">{actionName} with card</h3>
					<button onClick={() => setShowCardModal(false)} type="button" className="icon-button icon-button--black">
						<CrossIcon className="icon-button__icon" />
					</button>
				</header>
				{selectedPaymentMethod ?
					<>
						<p>{actionName} using this payment method:</p>
						<PaymentMethod method={selectedPaymentMethod} />
						<div className="button-wrap mt-3">
							<Button 
								className="w-xs-full button-wrap__button" 
								onClick={pay} 
								disabled={processingPayment}
							>
								{processingPayment ? <LoadingSpinner color="white" size="small" /> : actionName + ' Now'}
							</Button>
							<Button 
								className="w-xs-full button-wrap__button" 
								onClick={() => setSelectedPaymentMethod(null)} 
								variant="secondary"
								disabled={processingPayment}
							>
								Go Back
							</Button>
						</div>
					</>
				:
					<>
						<fieldset className="fieldset">
							<legend className="label">Card information</legend>
							<div className="text-input text-input--no-fixed-height mb-2">
								<CardNumberElement options={style} />
							</div>
							<div className="text-input__group mb-2">
								<div className="text-input text-input--no-fixed-height mr-1">
									<CardExpiryElement options={style} />
								</div>
								<div className="text-input text-input--no-fixed-height ml-1">
									<CardCvcElement options={style} />
								</div>
							</div>
							<input type="text" value={paymentMethodName} onChange={(e) => setPaymentMethodName(e.target.value)} className="text-input mb-4" placeholder="Mr John H Doe" />
						</fieldset>
						<div className="button-wrap">
							<Button 
								className="w-xs-full button-wrap__button" 
								onClick={pay}
								disabled={processingPayment}
							>
								{processingPayment ? <LoadingSpinner color="white" size="small" /> : actionName + ' Now'}
							</Button>
							<Button 
								className="w-xs-full button-wrap__button" 
								onClick={() => setShowCardModal(false)} 
								variant="secondary"
								disabled={processingPayment}
							>
								Cancel
							</Button>
						</div>
						<div className="button-options__divider mt-3 mb-3">
							<span className="button-options__divider-text">or</span>
						</div>
						<h3 className="mb-0">Use an existing card</h3>
						{methods.map(method => <PaymentMethod 
							key={method.id} 
							method={method} 
							setMethod={() => setSelectedPaymentMethod(method)} 
						/>)}
						{methods.length < 1 && <p className="sml my-2">You have no saved payment methods.</p>}
					</>
				}
			</div>}
		</div>
	)
}

export default PaymentForm
