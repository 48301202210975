import React from 'react'

function QuestionIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M11.3257 6.63009C10.7671 6.53427 10.1926 6.63925 9.70394 6.92643C9.2153 7.2136 8.84405 7.66445 8.65597 8.19912C8.54601 8.51172 8.20346 8.67598 7.89087 8.56602C7.57828 8.45606 7.41401 8.11351 7.52397 7.80092C7.80609 6.99891 8.36295 6.32263 9.09591 5.89187C9.82888 5.46109 10.6906 5.30363 11.5286 5.44737C12.3665 5.59109 13.1265 6.02674 13.6741 6.67714C14.2215 7.32747 14.5212 8.15055 14.52 9.00063C14.5197 10.3188 13.5389 11.2252 12.7528 11.7492C12.3414 12.0235 11.9343 12.2266 11.6324 12.3608C11.4806 12.4283 11.3533 12.4792 11.2625 12.5138C11.217 12.5311 11.1806 12.5444 11.1547 12.5536L11.1238 12.5644L11.1146 12.5676L11.1116 12.5686L11.1105 12.569C11.1103 12.569 11.1097 12.5692 10.92 12L11.1105 12.569C10.7961 12.6737 10.4555 12.5041 10.3508 12.1897C10.2461 11.8756 10.4156 11.5361 10.7295 11.431C10.7296 11.431 10.7294 11.4311 10.7295 11.431M10.7295 11.431L10.7334 11.4297L10.7527 11.423C10.7707 11.4166 10.7987 11.4064 10.8353 11.3924C10.9086 11.3645 11.0156 11.3218 11.145 11.2642C11.4056 11.1484 11.7485 10.9765 12.0872 10.7508C12.8009 10.2749 13.32 9.6815 13.32 9.00002C13.3208 8.43323 13.121 7.88355 12.756 7.44994C12.391 7.01634 11.8843 6.72591 11.3257 6.63009"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M10.4 16C10.4 15.6686 10.6686 15.4 11 15.4H11.01C11.3414 15.4 11.61 15.6686 11.61 16C11.61 16.3314 11.3414 16.6 11.01 16.6H11C10.6686 16.6 10.4 16.3314 10.4 16Z"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M0.399994 11C0.399994 5.14577 5.14577 0.399994 11 0.399994C16.8542 0.399994 21.6 5.14577 21.6 11C21.6 16.8542 16.8542 21.6 11 21.6C5.14577 21.6 0.399994 16.8542 0.399994 11ZM11 1.59999C5.80852 1.59999 1.59999 5.80852 1.59999 11C1.59999 16.1914 5.80852 20.4 11 20.4C16.1914 20.4 20.4 16.1914 20.4 11C20.4 5.80852 16.1914 1.59999 11 1.59999Z"/>
		</svg>
	)
}

export default QuestionIcon
