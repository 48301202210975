import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function(message) {

	const settings = {
		title: '',
		message: message,
		buttons: [
			{
				label: 'Ok',
				onClick: () => {},
			},
		],
	};

	confirmAlert(settings);
};