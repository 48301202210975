import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'
import CalendarIcon from '../Icons/Calendar'
import DashboardIcon from '../Icons/Dashboard'
import SettingsIcon from '../Icons/Settings'
import SortDescIcon from '../Icons/SortDesc'

function Nav({
	upcomingBookings,
	isStaff,
}) {
	const homeMatch = useRouteMatch({ path: '/', strict: true, exact: true });
	const calendarMatch = useRouteMatch({ path: '/bookings-calendar' });
	const servicesMatch = useRouteMatch({ path: '/services-manager' });
	const accountMatch = useRouteMatch({ path: '/account-settings' });

	const bookingCount = upcomingBookings.filter(b => b.status === BOOKING_STATUS.open).length;

	let hasActiveLink = false;
	if (homeMatch || calendarMatch || servicesMatch || accountMatch) {
		hasActiveLink = true;
	}

	return (
		<nav className={`bottom-nav ${!hasActiveLink ? 'bottom-nav--no-active' : ''}`}>
			<div className="container">
				<ul className="bottom-nav__list">
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/"
							activeClassName="is-current"
							exact
						>
							<DashboardIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Dashboard</span>
						</NavLink>
					</li>
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/bookings-calendar"
							activeClassName="is-current"
						>
							<CalendarIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">My Bookings</span>
							{bookingCount > 0 && <span className="bottom-nav__badge">{bookingCount}</span>}
						</NavLink>
					</li>
					{!isStaff &&
						<li className="bottom-nav__item">
							<NavLink 
								className="bottom-nav__link" 
								to="/services-manager"
								activeClassName="is-current"
							>
								<SortDescIcon className="bottom-nav__icon" />
								<span className="bottom-nav__text">Services</span>
							</NavLink>
						</li>
					}
					<li className="bottom-nav__item">
						<NavLink 
							className="bottom-nav__link" 
							to="/account-settings"
							activeClassName="is-current"
						>
							<SettingsIcon className="bottom-nav__icon" />
							<span className="bottom-nav__text">Settings</span>
						</NavLink>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default Nav
