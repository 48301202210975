import React, { useState } from 'react'
import { formatDistance } from 'date-fns'
import timestampToDate from '../utils/timestampToDate';
import BarberRating from './BarberRating';
import Button from './UI/Button';
import Avatar from './UI/Avatar';

const today = new Date();

function Reviews({
	reviews,
}) {

	const [showAllReviews, setShowAllReviews] = useState(false);

	const containerClass = showAllReviews ? 'is-expanded' : '';

	const selectedReviews = showAllReviews ? reviews : reviews.slice(0, 3);

	return (
		<div className="container">
			<section className="section">
				{reviews.length} Reviews
				<div className={`barber-reviews ${containerClass}`}>
					{selectedReviews.map(r => {
						const createdAtDate = timestampToDate(r.created_at);
						return (
							<div className="barber-reviews__review barber-review" key={r.id}>
								<header className="barber-review__header">
									<div className="barber-review__meta">
										<Avatar src={r.user.avatar_url} className="barber-review__avatar" />
										<div>
											<div className="barber-review__name">{r.user.name}</div>
											<div className="barber-review__time muted-text muted-text--light">{formatDistance(today, createdAtDate)} ago</div>
										</div>
									</div>
									{showAllReviews &&
										<div className="barber-review__stars" style={{ '--rating': r.rating }}></div>
									}
								</header>
								<div className="barber-review__content">
									<p><small>{r.content}</small></p>
								</div>
							</div>
						)
					})}
				</div>
				{!showAllReviews && selectedReviews.length > 0 &&
					<Button 
						onClick={() => setShowAllReviews(true)} 
						className="w-xs-full"
					>
						Show all reviews
					</Button>
				}
			</section>
		</div>
	)
}

export default Reviews