import React from 'react'

function InstagramIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || '' }`} width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M15.5998 20.3539C16.8505 20.3539 18.0311 19.8752 18.924 19.0062C19.817 18.137 20.3087 16.988 20.3087 15.7706V6.22942C20.3087 5.01204 19.817 3.86296 18.924 2.99383C18.0311 2.12471 16.8505 1.64607 15.5998 1.64607H6.40024C5.14947 1.64607 3.96889 2.12471 3.07593 2.99383C2.18297 3.86296 1.69121 5.01204 1.69121 6.22942V15.7706C1.69121 16.988 2.18297 18.137 3.07593 19.0062C3.96889 19.8752 5.14947 20.3539 6.40024 20.3539H15.5998ZM15.5998 22H6.40024C2.88009 22 0 19.1968 0 15.7706V6.22942C0 2.80322 2.88009 0 6.40024 0H15.5998C19.1199 0 22 2.80322 22 6.22942V15.7706C22 19.1968 19.1199 22 15.5998 22Z"/>
			<path d="M11.0048 7.05869C8.81393 7.05869 7.03792 8.78735 7.03792 10.9197C7.03792 13.0521 8.81393 14.7808 11.0048 14.7808C13.1957 14.7808 14.9717 13.0521 14.9717 10.9197C14.9717 8.78735 13.1957 7.05869 11.0048 7.05869ZM17.0921 10.9151C17.0921 14.1716 14.3798 16.8115 11.034 16.8115C7.68821 16.8115 4.97589 14.1716 4.97589 10.9151C4.97589 7.65857 7.68821 5.01864 11.034 5.01864C14.3798 5.01864 17.0921 7.65857 17.0921 10.9151Z"/>
			<path d="M18.6238 4.92694C18.6238 5.69801 17.9816 6.32314 17.1893 6.32314C16.3971 6.32314 15.7549 5.69801 15.7549 4.92694C15.7549 4.15587 16.3971 3.53075 17.1893 3.53075C17.9816 3.53075 18.6238 4.15587 18.6238 4.92694Z"/>
		</svg>
	)
}

export default InstagramIcon