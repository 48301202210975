import React from 'react'

function FormError({
	message,
}) {
	return (
		<small className="d-block mt-1">{message}</small>
	)
}

export default FormError
