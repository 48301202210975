import BarberSearch from './BarberSearch';
import MerchantDashboard from './MerchantDashboard';
import StaffDashboard from './StaffDashboard';
import CustomerDashboard from './CustomerDashboard';
import MerchantOnboarding from './MerchantOnboarding';
import SetUserLocation from './SetUserLocation';
import BarberBookingConfirmation from './BarberBooking/BarberBookingConfirmation';
import PostcodeSearchForm from './PostcodeSearchForm';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
	dsn: "https://b856c01ddeb64509acfee18251859268@o224984.ingest.sentry.io/6164752",
	integrations: [new Integrations.BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

export default {
    '.js-barber-search': BarberSearch,
    '.js-merchant-dashboard': MerchantDashboard,
    '.js-staff-dashboard': StaffDashboard,
    '.js-customer-dashboard': CustomerDashboard,
    '.js-merchant-onboarding': MerchantOnboarding,
    '.js-booking-confirmation': BarberBookingConfirmation,
    '.js-set-user-location': SetUserLocation,
    '.js-postcode-search-form': PostcodeSearchForm,
};