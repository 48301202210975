import React from 'react'

function GuestIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M11.1999 3.2C11.1999 4.96731 9.76724 6.4 7.99993 6.4C6.23262 6.4 4.79993 4.96731 4.79993 3.2C4.79993 1.43269 6.23262 0 7.99993 0C9.76724 0 11.1999 1.43269 11.1999 3.2Z" />
			<path d="M0.799931 14.4C0.799944 11.749 2.94897 9.60002 5.59993 9.60002L10.3999 9.60001C13.0509 9.60001 15.1999 11.749 15.1999 14.4V15.2H0.799927L0.799931 14.4Z" />
		</svg>
	)
}

export default GuestIcon