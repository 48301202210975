import React from 'react'

function CrossIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M6.15148 7.00001L0.575745 1.42427L1.42427 0.575745L7.00001 6.15148L12.5757 0.575745L13.4243 1.42427L7.84854 7.00001L13.4243 12.5757L12.5757 13.4243L7.00001 7.84854L1.42427 13.4243L0.575745 12.5757L6.15148 7.00001Z" />
		</svg>
	)
}

export default CrossIcon
