import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import SetLocationForm from '../SetLocationForm';
import BackButton from '../UI/BackButton';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import CloseAccount from './CloseAccount';
import EditCustomerForm from './EditCustomerForm';
import alert from '../../utils/alert';

function AccountDetails({
	user,
	userLoaded,
	setUser,
}) {
	const history = useHistory();
	const { path } = useRouteMatch();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const [editingLocation, setEditingLocation] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [errors, setErrors] = useState({});
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (userLoaded) {
			setFirstName(user.first_name);
			setLastName(user.last_name);
			setEmail(user.email);
			setPhone(user.phone || '');
			setIsLoaded(true);
		}
	}, [userLoaded]);

	const handleFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);
		const formData = new FormData(e.target);
		formData.append('_method', 'PATCH');

		axios.post('/api/user', formData)
			.then(response => {
				setUser(response.data);
				alert('Your details have been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your details.');
			})
			.finally(() => setProcessing(false));
	}

	const handleAddressFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);
		const formData = new FormData(e.target);
		formData.append('_method', 'PATCH');

		axios.post('/api/user/customer/address', formData)
			.then(response => {
				setUser(response.data);
				setEditingLocation(false);
				alert('Your details have been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your details.');
			})
			.finally(() => setProcessing(false));
	}

	return (
		<div className="pb-12">
			<BackButton
				text="Back to dashboard"
				onClick={() => history.push('/')}
			/>
			<section className="section">
				<div className="container">
					<Switch>
						<Route path={`${path}/close-account`}>
							<CloseAccount />
						</Route>
						<Route>
							{!isLoaded && <LoadingSpinner center />}
							{isLoaded && !editingLocation && <>
								<h1 className="h3 mb-1">Account Details</h1>
								<p className="sml">Update your account details</p>
								<EditCustomerForm
									isLoaded={isLoaded}
									handleFormSubmit={handleFormSubmit}
									firstName={firstName}
									lastName={lastName}
									email={email}
									phone={phone}
									line1={user.address_line_1}
									line2={user.address_line_2}
									city={user.address_city}
									postcode={user.address_postcode}
									setFirstName={setFirstName}
									setLastName={setLastName}
									setEmail={setEmail}
									setPhone={setPhone}
									setEditingLocation={setEditingLocation}
									errors={errors}
								/>
							</>}
							{isLoaded && editingLocation && <>
								<h1 className="h3 mb-1">Edit your address</h1>
								<p className="sml">Let barbers know where to find you</p>
								<form onSubmit={handleAddressFormSubmit}>
									<SetLocationForm
										formFooter={showFields => {
											return <>
												{showFields && <Button
													size="full"
													type="submit"
													className="mb-2"
												>
													Confirm
												</Button>}
												<Button
													onClick={() => setEditingLocation(false)}
													size="full"
													type="button"
													variant="secondary"
												>
													Cancel
												</Button>
											</>
										}}
									/>
								</form>
							</>}
						</Route>
					</Switch>
				</div>
			</section>
		</div>
	)
}

export default AccountDetails
