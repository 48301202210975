import React from 'react'
import CalendarIcon from '../Icons/Calendar';
import MailIcon from '../Icons/Mail';
import QuestionIcon from '../Icons/Question';
import ScissorsIcon from '../Icons/Scissors';
import UserIcon from '../Icons/User';
import WalletIcon from '../Icons/Wallet';
import SettingsIcon from '../Icons/Settings';
import PencilIcon from '../Icons/Pencil';

const icons = {
	calendar: CalendarIcon,
	mail: MailIcon,
	pencil: PencilIcon,
	question: QuestionIcon,
	scissors: ScissorsIcon,
	settings: SettingsIcon,
	user: UserIcon,
	wallet: WalletIcon,
}

function Icon({
	name,
	className,
}) {
	if (!name || !icons[name]) return null;

	return React.createElement(icons[name], {className});
}

export default Icon
