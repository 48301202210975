import React, { useState } from 'react'
import ServiceForm from './ServiceForm'

function EditService({
	service,
	categories,
	setEditFormOpen,
	onServiceEdit,
	onCancel,
}) {

	const [serviceName, setServiceName] = useState(service.name);
	const [serviceDuration, setServiceDuration] = useState(service.duration);
	const [servicePrice, setServicePrice] = useState(service.price);
	const [serviceCategory, setServiceCategory] = useState(service.category_id);

	const handleFormSubmit = e => {
		e.preventDefault();
		onServiceEdit(service, {
			name: serviceName,
			duration: serviceDuration,
			price: servicePrice,
			category_id: parseInt(serviceCategory),
		});
		setEditFormOpen(false);
	}

	return (
		<div>
			<ServiceForm
				onSubmit={handleFormSubmit}
				onCancel={onCancel}
				categories={categories}
				serviceName={serviceName}
				serviceDuration={serviceDuration}
				servicePrice={servicePrice}
				serviceCategory={serviceCategory}
				setServiceName={setServiceName}
				setServiceDuration={setServiceDuration}
				setServicePrice={setServicePrice}
				setServiceCategory={setServiceCategory}
			/>
		</div>
	)
}

export default EditService
