import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router';
import formatDate from '../../utils/formatDate';
import googleMapsUrl from '../../utils/googleMapsUrl';
import timestampToDate from '../../utils/timestampToDate';
import CrossIcon from '../Icons/Cross';
import Button from '../UI/Button';
import Modal from '../UI/Modal';
import Panel from '../UI/Panel';
import CancelBooking from './CancelBooking';
import alert from '../../utils/alert';

function ConfirmedBookingPanel({
	booking,
	onClose,
	getBookings,
	clearBooking,
	cancelFormOpen, 
	setCancelFormOpen,
}) {
	const { path } = useRouteMatch();
	const panelRef = createRef();

	const [processing, setProcessing] = useState(false);

	const updateStatus = (status, reason = '') => {
		if (processing) return false;
		setProcessing(true);
		axios.patch(`/api/user/merchant/bookings/${booking.id}/${status}`, { reason })
			.then(response => {
				clearBooking();
				getBookings();
			})
			.catch(error => {
				alert(error.response.data.errors.reason[0]);
			})
			.finally(() => setProcessing(false));
	};

	if (!booking) return null;

	return <Panel
		path={`${path}/confirmed-bookings/:id`}
		condition={booking !== null}
		panelRef={panelRef}
		className="booking-panel"
	>
		<>
			<header className="booking-panel__top">
				<div className="container">
					<div className="d-flex justify-end">
						<button className="icon-button icon-button--white" onClick={onClose}>
							<CrossIcon className="icon-button__icon" />
						</button>
					</div>
					<h3>{formatDate(timestampToDate(booking.start_time))}</h3>
					<p className="mb-4"><span className="status-badge status-badge--confirmed">Confirmed</span></p>
					<div className="d-flex justify-between mb-4">
						<div className="mr-3">
							<p className="mb-1"><strong>{booking.customer_full_name}</strong></p>
						</div>
						<img src={booking.customer_avatar_url} alt="" className="avatar avatar--large" />
					</div>
					<Modal
						isOpen={cancelFormOpen}
						title="Reason for cancelling this booking."
						subtitle="Choose a reason for cancelling and we will notify the customer."
						onClick={() => setCancelFormOpen(false)}
						content={<CancelBooking
							updateStatus={updateStatus}
						/>}
					>
						<Button
							size="full"
							className="booking-panel__action-button"
							onClick={() => setCancelFormOpen(true)}
							disabled={processing}
						>
							Cancel
							<CrossIcon className="booking-panel__action-button-icon" />
						</Button>
					</Modal>
				</div>
			</header>
			<div className="booking-panel__middle">
				<div className="container">
					<table className="booking-pricing-table">
						<tbody>
							{booking.services.map(s => {
								return (
									<tr key={`booking_${booking.id}_service_${s.id}`} className="booking-pricing-table__row">
										<td className="booking-pricing-table__cell">
											<div>{s.name} x {s.quantity}</div>
											<div className="muted-text muted-text--light">{s.total_duration} mins</div>
										</td>
										<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
											<div>£{s.gross_price}</div>
										</td>
									</tr>
								)
							})}
						</tbody>
						<tfoot>
							<tr className="booking-pricing-table__row booking-pricing-table__row--last">
								<td className="booking-pricing-table__cell"><strong>Total</strong></td>
								<td className="booking-pricing-table__cell booking-pricing-table__cell--price">
									<strong>£{booking.gross_price}</strong>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div className="booking-panel__bottom">
				<div className="container">
				<h6>Important info</h6>
					<p>As a courtesy to the business please avoid cancelling within 48 hours of your appointment. We do charge for late cancellations.</p>
					<h6>Cancellation policy</h6>
					<p>Cancel for free up to <strong>48 hours</strong> ahead, otherwise the <strong>£5</strong> deposit will be taken and service fee price for late cancellation.</p>
				</div>
			</div>
		</>
	</Panel>
}

export default ConfirmedBookingPanel
