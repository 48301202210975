import React from 'react'
import TickIcon from '../Icons/Tick'

function RadioField({
	name,
	id,
	label,
	value,
	checked,
	defaultChecked,
	onChange,
	required,
}) {
	const variableInputProps = {};
	if (onChange) {
		variableInputProps.onChange = onChange;
		variableInputProps.checked = checked;
	} else {
		variableInputProps.defaultChecked = defaultChecked;
	}

	return (
		<label className="field radio-field">
			<input 
				type="radio" 
				name={name}
				id={id || name}
				value={value}
				className="sr-only radio-field__input"
				required={required}
				{...variableInputProps}
			/>
			<div className="radio-field__box">
				<TickIcon className="radio-field__icon" />
			</div>
			<span className="radio-field__label">{label}</span>
		</label>
	)
}

export default RadioField
