import React from "react";
import AddressLookup from "./AddressLookup";
import Button from "./UI/Button";

function PostcodeSearchForm({ hasButton }) {
    const handleResolve = (postcode) => {
        const event = new CustomEvent("postcode-search", {
            detail: postcode.postcode,
        });
        document.dispatchEvent(event);
    };

    const formModifierClass = hasButton ? "postcode-search--has-button" : "";

    return (
        <form
            method="GET"
            action="/search"
            className={`postcode-search ${formModifierClass}`}
        >
            <label htmlFor="postcode" className="sr-only">
                Search
            </label>
            <div className="search-wrap">
                <AddressLookup
                    onResolve={handleResolve}
                    inputName="postcode"
                    placeholder="Enter your booking postcode."
                />
                {hasButton && (
                    <Button
                        type="submit"
                        className="search-button"
                        size="full"
                        arrow
                    >
                    </Button>
                )}
            </div>
        </form>
    );
}

export default PostcodeSearchForm;
