import React from 'react'

function StarIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M11.3022 9.93555L12.498 13.7138C12.6538 14.2061 12.0627 14.6015 11.6388 14.2885L8.57921 12.0293C8.2715 11.7821 7.72836 11.7821 7.42065 12.0293L4.36102 14.2885C3.93711 14.6015 3.34608 14.2061 3.5019 13.7138L4.69763 9.93555C4.79676 9.60111 4.6034 9.10769 4.30318 8.91474L1.02107 6.52565C0.597098 6.21704 0.823506 5.5658 1.35477 5.5658H5.26553C5.63599 5.5658 6.02212 5.24693 6.1239 4.90354L7.53285 1.15227C7.71366 0.670867 8.42555 0.686814 8.58297 1.17579L9.79401 4.93823C9.89579 5.28162 10.2696 5.5658 10.64 5.5658H14.6451C15.1763 5.5658 15.4028 6.21704 14.9788 6.52565L11.6967 8.91473C11.3965 9.10769 11.2031 9.6011 11.3022 9.93555ZM11.2409 8.25703L13.839 6.3658H10.64C9.91833 6.3658 9.23632 5.85326 9.03017 5.17613L8.03607 2.08771L6.88095 5.16316C6.77028 5.50801 6.54224 5.79342 6.29076 5.99168C6.0316 6.196 5.67303 6.3658 5.26553 6.3658H2.16084L4.759 8.25703C5.07596 8.46953 5.28249 8.79032 5.39594 9.08258C5.51125 9.37963 5.57964 9.77493 5.46465 10.1629L5.46257 10.1699L4.50825 13.1853L6.9348 11.3936C7.25794 11.1408 7.65304 11.0439 7.99993 11.0439C8.34682 11.0439 8.74193 11.1408 9.06508 11.3936L11.4916 13.1853L10.5373 10.1699L10.5352 10.1629C10.4202 9.77493 10.4886 9.37963 10.6039 9.08257C10.7174 8.79031 10.9239 8.46953 11.2409 8.25703Z" />
		</svg>
	)
}

export default StarIcon