import React from 'react'
import AccountActions from '../AccountActions'
import AccountLinks from './AccountLinks'
import CustomerFAQs from '../CustomerFAQs'
import Skeleton from 'react-loading-skeleton'

function FrontPage({
	user,
	userLoaded,
}) {
	return (
		<div className="pb-12">
			<section className="section">
				<div className="container">
					<h1 className="h3 mb-1">{userLoaded ? `Welcome ${user.first_name}` : <Skeleton width={192} />}</h1>
					<p className="sml">Manage your account, payment details<br/> along with bookings.</p>
					<AccountLinks />
				</div>
			</section>
			<section className="section pt-0 pb-2">
				<div className="container">
					<h2 className="mb-4">Support</h2>
					<CustomerFAQs />
				</div>
			</section>
			<section className="section pt-0">
				<div className="container">
					<AccountActions />
				</div>
			</section>
		</div>
	)
}

export default FrontPage
