import React from 'react'
import ArrowLeftIcon from '../Icons/ArrowLeft'

function BackButton({
	text,
	onClick,
}) {
	return (
		<button className="back-button" onClick={onClick}>
			<div className="container">
				<ArrowLeftIcon className="back-button__icon" /> {text}
			</div>
		</button>
	)
}

export default BackButton
