import React, { useState } from 'react';
import AddressLookup from './AddressLookup';
import TextField from './UI/TextField';

function SetLocationForm({
    formFooter,
}) {
    const [showFields, setShowFields] = useState(false);

    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [city, setCity] = useState('');
    const [postcode, setPostcode] = useState('');

    const fillAddress = (address) => {
		setLine1(address.line_1);
		setLine2(address.line_2);
		setCity(address.city);
		setPostcode(address.postcode);
		setShowFields(true);
	};

	return <div className="pb-4">
        {!showFields && <>
            <AddressLookup onResolve={fillAddress} />
            <p className="pt-1"><small>or <button type="button" style={{ textDecoration: 'underline' }} onClick={() => setShowFields(true)}>fill out manually</button></small></p>
        </>}
        {showFields && <>
            <TextField
                name="address_line_1"
                type="text"
                label="Line 1"
                value={line1}
                onChange={(e) => setLine1(e.target.value)}
                placeholder="1 Example Street"
                required
            />
            <TextField
                name="address_line_2"
                type="text"
                label="Line 2"
                value={line2}
                onChange={(e) => setLine2(e.target.value)}
            />
            <TextField
                name="address_city"
                type="text"
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Derby"
                required
            />
            <TextField
                name="address_postcode"
                type="text"
                label="Postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
                placeholder="DE1 1AA"
                required
            />
        </>}
        {formFooter(showFields)}
    </div>;
}

export default SetLocationForm;
