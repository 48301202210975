import React, { useEffect, useState } from 'react'
import FormError from './FormError';

function NumberToggle({
	name,
	id,
	max,
	suffix,
	defaultValue,
	onValueChange,
	required,
	errors,
}) {

	const [value, setValue] = useState(defaultValue || 0);

	useEffect(() => {
		setValue(Math.min(value, max));
	}, [max]);

	const increment = () => {
		setValue(Math.min(value + 1, max));

		if (onValueChange) {
			onValueChange(Math.min(value + 1, max));
		}
	};
	const decrement = () => {
		setValue(Math.max(value - 1, 0));

		if (onValueChange) {
			onValueChange(Math.min(value - 1, max));
		}
	};

	return (
		<div className="number-toggle">
			<div className="number-toggle__controls">
				<button
					type="button"
					className="number-toggle__button"
					onClick={decrement}
					disabled={value === 0}
				>-</button>
				<input
					type="number"
					className="number-toggle__input"
					value={value}
					min="0"
					max="999"
					name={name}
					id={id}
					readOnly
					required={required}
				/>
				{suffix && <span className="number-toggle__suffix">{suffix}</span>}
				<button
					type="button"
					className="number-toggle__button"
					onClick={increment}
					disabled={value === max}
				>+</button>
			</div>
			{errors.map((e, i) => <FormError key={`${name}_error_${i}`} message={e} />)}
		</div>
	)
}

NumberToggle.defaultProps = {
	errors: [],
}

export default NumberToggle
