import React from 'react'

function BarberAvatar({
	barber,
	size,
	className,
}) {

	const classes = ['avatar', className];

	if (size) {
		classes.push(`avatar--${size}`);
	}

	return (
		<img 
			src={barber.avatar_url}
			alt={barber.name}
			className={classes.join(' ')}
		/>
	)
}

export default BarberAvatar
