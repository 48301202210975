import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import ActionBar from '../ActionBar';
import GalleryImagesPicker from '../GalleryImagesPicker';
import ProfilePicturePicker from '../ProfilePicturePicker';
import TextAreaField from '../UI/TextAreaField';
import TextField from '../UI/TextField';

function Step3({ token, name, setStep }) {
	const history = useHistory();

	const [bio, setBio] = useState('');
	const [instagram, setInstagram] = useState('');
	const [twitter, setTwitter] = useState('');
	const [facebook, setFacebook] = useState('');
	const [profilePicture, setProfilePicture] = useState('');
	const [galleryImages, setGalleryImages] = useState([]);
	const [errors, setErrors] = useState({});
	const [processing, setProcessing] = useState(false);

	const handleFormSubmit = e => {
		e.preventDefault();

		const payload = new FormData();
		
		payload.append('token', token);
		payload.append('bio', bio);
		payload.append('instagram', instagram);
		payload.append('twitter', twitter);
		payload.append('facebook', facebook);
		payload.append('profile_picture', profilePicture);

		galleryImages.forEach(img => {
			payload.append('gallery_images[]', img);
		});

		setProcessing(true);

		axios.post('/api/barbers/register/details', payload)
			.then(res => {
				setStep(4);
				history.push('/your-services');
			})
			.catch(error => {
				setErrors(error.response.data.errors);
				setProcessing(false);
			});
	};

	return (
		<div className="pb-12">
			<h1>Hey, {name}</h1>
			<p>Welcome to Barbashop ✂️💈</p>
			<form onSubmit={handleFormSubmit} method="POST" action="">
				<ProfilePicturePicker 
					currentImage={profilePicture} 
					setCurrentImage={(file) => setProfilePicture(file)}
					errors={errors.profile_picture}
				/>
				<hr className="mb-4"/>
				<TextAreaField
					name="bio"
					label="Add your Bio"
					value={bio}
					onChange={(e) => setBio(e.target.value)}
					helperText="Tell customers about you"
					errors={errors.bio}
				/>
				<GalleryImagesPicker 
					images={galleryImages}
					setImages={setGalleryImages}
					errors={errors.gallery_images}
				/>
				<div className="field mb-4">
					<legend className="label">Add your socials</legend>
					<p className="muted-text muted-text--light">Tell customers where to find you</p>
					<TextField
						name="instagram"
						label="Instagram username"
						value={instagram}
						onChange={(e) => setInstagram(e.target.value)}
					/>
					{/* <TextField
						name="twitter"
						label="Twitter username"
						value={twitter}
						onChange={(e) => setTwitter(e.target.value)}
					/>
					<TextField
						name="facebook"
						label="Facebook username"
						value={facebook}
						onChange={(e) => setFacebook(e.target.value)}
					/> */}
				</div>
				<hr className="mt-3 mb-4"/>
				<ActionBar
					title="Step 3 of 6"
					subTitle="Fill out your details"
					buttonText="Next"
					disabled={processing}
				/>
			</form>
		</div>
	);
}

export default Step3;
