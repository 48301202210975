import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router';
import UpcomingBooking from './UpcomingBooking'
import UpcomingBookingPanel from './UpcomingBookingPanel'

function UpcomingBookings({
	bookings,
	getBookings,
}) {
	const history = useHistory();
	const { path, url } = useRouteMatch();

	const [selectedBooking, setSelectedBooking] = useState(null);

	const clearBooking = () => {
		setSelectedBooking(null);
		history.push(path);
	}

	const upcomingBookings = bookings.filter((booking) => {
		return booking.status !== BOOKING_STATUS.cancelled && booking.status !== BOOKING_STATUS.rejected
	})



	return <>
		<h2 className="h4 d-flex">Upcoming <span className="booking-count-badge ml-1">{upcomingBookings.length}</span></h2>
		<ul className="p-0">
			{upcomingBookings.map(b => <UpcomingBooking 
				key={`upcoming_booking_${b.id}`}
				booking={b} 
				getBookings={getBookings}
				setSelectedBooking={setSelectedBooking}
			/>)}
		</ul>

		<UpcomingBookingPanel
			booking={selectedBooking}
			bookings={bookings}
			setSelectedBooking={setSelectedBooking}
			onClose={clearBooking}
			getBookings={getBookings}
			clearBooking={clearBooking}
		/>
	</>
}

export default UpcomingBookings
