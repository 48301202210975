import React from 'react'
import Button from './UI/Button'

function GetHelp() {
	return (
		<div>
			<p>Email your query to <a href="mailto:help@barbashop.app">help@barbashop.app</a> and someone will aim to get in touch within 24hrs.</p>
			<Button 
				arrow 
				size="full" 
				element="a" 
				href="mailto:help@barbashop.app"
				target="_blank"
			>
				Send Email 
			</Button>
		</div>
	)
}

export default GetHelp
