import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function(options) {

	const settings = {
		title: options.title,
		message: options.message,
		buttons: [
			{
				label: 'Yes',
				onClick: options.accept,
			},
			{
				label: 'No',
				onClick: options.decline,
			}
		],
		onClickOutside: options.decline,
		onKeypressEscape: options.decline,
	};

	confirmAlert(settings);
};