import { addDays, nextDay } from "date-fns";

const weekdayStrings = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default (currentDay, barberAvailability) => {
	
	// get the current weekday
	let weekday = currentDay.getDay();
	let weekdayName = weekdayStrings[weekday];

	// filter all available weekdays for barber
	const availableWeekdays = Object.entries(barberAvailability).filter(av => av[1]).map(av => av[0]);

	// return the current date if it's available
	if (availableWeekdays.includes(weekdayName)) {
		return currentDay;
	}

	// increase day by one until current weekday is available
	while (!availableWeekdays.includes(weekdayName)) {
		weekday = weekday === 6 ? 0 : weekday + 1;
		weekdayName = weekdayStrings[weekday];
	}

	// get the date of the next available day
	const nextAvailableDay = nextDay(currentDay, weekday);

	return nextAvailableDay;
}