import React from 'react';
import AccountLink from '../AccountLink';

const AccountLinks = () => <ul className="account-links">
	<AccountLink
		title="Bookings"
		subtitle="View your bookings"
		icon="calendar"
		route="/bookings-calendar"
	/>
	<AccountLink
		title="Services"
		subtitle="Manage what services you offer"
		icon="scissors"
		route="/services-manager"
	/>
	<AccountLink
		title="Account Details"
		subtitle="Update your account details"
		icon="user"
		route="/account-details"
	/>
	<AccountLink
		title="Manage Staff"
		subtitle="Update staff details"
		icon="user"
		route="/staff"
	/>
	<AccountLink
		title="Billing"
		subtitle="Manage your payment details"
		icon="wallet"
		route="/billing"
	/>
	<AccountLink
		title="Settings"
		subtitle="Manage your account settings"
		icon="settings"
		route="/account-settings"
	/>
</ul>;

export default AccountLinks;
