import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import getServicesTotalPrice from '../../utils/getServicesTotalPrice';
import ActionBar from '../ActionBar';
import ClockIcon from '../Icons/Clock';
import SearchIcon from '../Icons/Search';
import Button from '../UI/Button';
import NumberPicker from '../UI/NumberPicker';
import NumberToggle from '../UI/NumberToggle';

function BarberBookingServices({ barber, booking, setBooking, handleNextButtonClick, auth }) {
	const [totalGuests, setTotalGuests] = useState(0);
	const [selectedServices, setSelectedServices] = useState({});
	const [loaded, setLoaded] = useState(false);

	const handleServiceQuantityChange = (service, quantity) => {

		const parsedPrice = parseFloat(service.price);
		const totalPrice = parsedPrice * quantity;

		const newService = {
			id: service.id,
			name: service.name,
			quantity: quantity,
			duration: service.duration,
			perPrice: parsedPrice,
			totalPrice: totalPrice.toFixed(2),
		};

		const newSelectedServices = {...selectedServices};

		delete newSelectedServices[service.id]

		if (quantity > 0) {
			newSelectedServices[service.id] = newService;
		}

		setSelectedServices(newSelectedServices);
	}

	useEffect(() => {
		setLoaded(true);
	}, []);

	useEffect(() => {
		setSelectedServices({});
	}, [totalGuests]);

	const submit = () => {
		setBooking(Object.assign(booking, { services: selectedServices, total_guests: totalGuests }));
		handleNextButtonClick();
	};

	if (auth === 'false') {
		return (
			<div className="container">
				<section className="section center-xs">
					<p className="mb-6">You must be logged in to make a booking.</p>
					<div className="button-options">
						<Button className="button-options__button" element="a" href="/login">
							Sign in
						</Button>
						<div className="button-options__divider">
							<span className="button-options__divider-text">or</span>
						</div>
						<Button className="button-options__button" element="a" href="/register">
							Create an account
						</Button>
					</div>
				</section>	
			</div>
		)
	}

	const serviceCount = Object.keys(selectedServices).length;
	const hasServices = serviceCount !== 0;

	const prices = getServicesTotalPrice(selectedServices, totalGuests);
	const selectedServiceCount = Object.values(selectedServices)
		.reduce((total, s) => total + s.quantity, 0);

	let actionBarTitle = 'Step 1 of 2';
	if (hasServices) {
		actionBarTitle = `Total: £${prices.totalPrice}`;
	}

	let actionBarSubtitle = 'Choose Your Services';
	if (hasServices && parseFloat(prices.totalDiscount) === 0) {
		actionBarSubtitle = `${serviceCount} Service${serviceCount > 1 ? 's' : ''} selected`;
	} else if (hasServices && parseFloat(prices.totalDiscount) > 0) {
		actionBarSubtitle = `You've Saved £${prices.totalDiscount}`
	}

	return (
		<div className="container pb-12">
			<section className="section">
				<fieldset className="guest-count">
					<legend className="label h5">How many guests</legend>
					<p className="muted-text mb-4">Save with each additional guest.</p>
					<NumberPicker
						onChange={value => setTotalGuests(value)}
						name="guest_count"
						showDiscountBadges={true}
					/>
				</fieldset>
			</section>
			<section className="section pt-0">
				<fieldset className="field">
					<legend className="label h5">Services</legend>
					<p className="muted-text mb-4">Pick from the services below</p>
					<ul className="service-list" key={'service_list_' + totalGuests}>
						{loaded ? barber.services.map(s => {
							const field_id = `services_${s.id}`;
							const currentQuantity = Object.values(selectedServices).find(ss => ss.id === s.id)?.quantity || 0;
							return <li className="service-list__item" key={field_id}>
								<div className="service-list__title">
									<label htmlFor={field_id} className="service-list__name">{s.name}</label>
									<div className="muted-text muted-text--light service-list__duration">
										<ClockIcon className="service-list__clock-icon" /> {s.duration} mins
									</div>
								</div>
								<div className="service-list__price">
									£{s.price}
								</div>
								<NumberToggle
									name={`services[${s.id}]`}
									id={field_id}
									max={totalGuests - (selectedServiceCount - currentQuantity)}
									onValueChange={value => handleServiceQuantityChange(s, value)}
								/>
							</li>
						}) : [...new Array(3)].map((x, i) => <li className="service-list__item" key={i}>
							<div className="service-list__title">
								<label className="service-list__name"><Skeleton width={128} /></label>
								<div className="muted-text muted-text--light service-list__duration">
									<ClockIcon className="service-list__clock-icon" />
									<Skeleton width={64} />
								</div>
							</div>
							<div className="service-list__price">
								<Skeleton width={64} />
							</div>
							<NumberToggle max={0} />
						</li>)}
					</ul>
				</fieldset>	
			</section>
			<ActionBar
				title={actionBarTitle}
				subTitle={actionBarSubtitle}
				buttonText="Next"
				disabled={!hasServices}
				onClick={submit}
			/>
		</div>
	)
}

export default BarberBookingServices