import axios from 'axios';
import React, { useState, useEffect } from 'react'
import BarberAvatar from '../UI/BarberAvatar'
import Button from '../UI/Button';
import TextField from '../UI/TextField';
import percentages from '../../utils/tippingPercentages';
import PaymentForm from '../PaymentForm';
import formatCurrency from '../../utils/formatCurrency';
import alert from '../../utils/alert';

const percentageToAmount = (percent, price) => {
	const value = ((parseFloat(price) / 100) * percent).toFixed(2);
	if (isNaN(value)) return '';
	return value;
}

function TipForm({
	booking,
	afterSubmit,
	defaultTipValue,
	defaultTipFormat,
}) {
	const [tipValue, setTipValue] = useState(percentageToAmount(booking.net_price, defaultTipValue));
	const [tipFormat, setTipFormat] = useState(defaultTipFormat || 'percentage');
	const [amountConfirmed, setAmountConfirmed] = useState(false);
	const [paymentIntentId, setPaymentIntentId] = useState('');
	const [paymentIntentSecret, setPaymentIntentSecret] = useState('');

	useEffect(() => {
		if (!amountConfirmed || !tipValue) return false;

		const data = { amount: tipValue };

		axios.post(`/api/user/customer/bookings/${booking.id}/tip/provision`, data)
			.then(response => {
				setPaymentIntentId(response.data.payment_intent_id);
				setPaymentIntentSecret(response.data.client_secret);
			})
			.catch(error => console.log(error));
	}, [amountConfirmed]);

	const completeTip = () => {
		
		const data = {
			amount: tipValue,
			payment_intent_id: paymentIntentId,
		};

		axios.post(`/api/user/customer/bookings/${booking.id}/tip`, data)
			.then(response => {
				afterSubmit();
			})
			.catch(error => {
				alert('An error occurred while processing your tip. Please contact our team.');
			});
	}

	const formattedValue = formatCurrency(tipValue);

	return (
		<div className="tip-form">
			<header className="sheet-content__header">
				<BarberAvatar barber={booking.barber} className="sheet-content__avatar" />
				<h2 className="sheet-content__title">Add a tip for {booking.barber.name}?</h2>
			</header>
			<div className="tip-form__content">
				{amountConfirmed ? <>
					<p className="py-4 text__align--center">
						<strong>Tipping {booking.barber.name} {formattedValue}</strong>
					</p>
					<PaymentForm 
						paymentIntentSecret={paymentIntentSecret}
						onComplete={completeTip}
						acceptsVouchers={false}
						actionName="Tip"
						displayDescription={`Tip ${booking.barber.name}`}
						displayAmount={tipValue * 100}
					/>
				</> : <>
					<div className="muted-text muted-text--light tip-form__tip-value">
						Tip Value {formattedValue}
					</div>
					{tipFormat === 'percentage' && 
						<div className="tip-form__percentages">
							{percentages.map(p => {
								return <label
									key={`tip_option_${p}`}
									className="tip-form__percentage-option"
								>
									<input 
										type="radio"
										name="tip_amount"
										id={`tip_amount_${p}`}
										value={p}
										defaultChecked={defaultTipValue === p}
										onChange={() => setTipValue(percentageToAmount(booking.net_price, p))}
										className="sr-only tip-form__percentage-input"
									/>
									<span className="tip-form__percentage-text">{p}%</span>
								</label>
							})}
						</div>
					}
					{tipFormat === 'custom' && 
						<TextField
							name="tip_amount"
							label="Set a custom tip"
							type="number"
							value={tipValue}
							onChange={e => setTipValue(e.target.value || 0)}
							prefix="GBP"
							inputProps={{
								min: 0,
								step: 0.01,
							}}
						/>
					}
					<div className="tip-form__format-switcher">
						{tipFormat === 'percentage' && 
							<button 
								onClick={() => setTipFormat('custom')}
								className="tip-form__format-switcher-button"
							>
								Enter a custom amount
							</button>
						}
						{tipFormat === 'custom' && 
							<button 
								onClick={() => setTipFormat('percentage')}
								className="tip-form__format-switcher-button"
							>
								Tip a percentage
							</button>
						}
					</div>
					<Button
						size="full"
						type="submit"
						onClick={() => setAmountConfirmed(true)}
						disabled={!tipValue}
					>
						Continue{tipValue > 0 && ` and tip ${formattedValue}`}
					</Button>
				</>}
			</div>
		</div>
	)
}

export default TipForm
