import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import CloseAccount from '../CustomerDashboard/CloseAccount';
import EditBarberAvailability from '../EditBarberAvailability';
import EditBarberLocation from '../EditBarberLocation';
import BackButton from '../UI/BackButton'
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import EditMerchantForm from './EditMerchantForm';
import alert from '../../utils/alert';
import EditBarberBlockedTimes from './EditBarberBlockedTimes';
import confirmAlert from '../../utils/confirmAlert';
import EditBarberImages from './EditBarberImages';

function AccountDetails({
	user,
	userLoaded,
	setUser,
}) {
	const history = useHistory();
	const { path } = useRouteMatch();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [availability, setAvailability] = useState({});
	const [blockedTimes, setBlockedTimes] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [profilePicture, setProfilePicture] = useState('');
	const [barberAvatar, setBarberAvatar] = useState('');
	const [galleryImages, setGalleryImages] = useState([]);

	const [editingLocation, setEditingLocation] = useState(false);
	const [editingAvailability, setEditingAvailability] = useState(false);
	const [editingImages, setEditingImages] = useState(false);
	const [availabilityUpdated, setAvailabilityUpdated] = useState(false);

	const [errors, setErrors] = useState({});
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		if (userLoaded) {
			setFirstName(user.first_name);
			setLastName(user.last_name);
			setEmail(user.email);
			setPhone(user.phone || '');
			setAvailability(user.availability);
			setBlockedTimes(user.blocked_times);
			setProfilePicture(user.avatar_url);
			setIsLoaded(true);
		}
	}, [userLoaded]);

	useEffect(() => {
		if (processing || !availabilityUpdated) return false;

		setProcessing(true);

		const _method = 'PUT';

		axios.post('/api/user/merchant/availability', { availability, _method })
			.then(response => {
				setUser(response.data);
				setEditingAvailability(false);
				setErrors({});
				alert('Your details have been updated.');
			})
			.catch(errors => {
				setErrors(errors);
				if (errors.response.status === 500) {
					alert('An error occurred while updating your details.');
				}
			})
			.finally(() => {
				setProcessing(false);
				setAvailabilityUpdated(false);
			});
	}, [availability])

	const handleFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		const formData = new FormData(e.target);
		formData.append('_method', 'PATCH');

		axios.post('/api/user', formData)
			.then(response => {
				setUser(response.data);
				setErrors({});
				alert('Your details have been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your details.');
			})
			.finally(() => setProcessing(false));
	}

	const handleProfilePicFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		const formData = new FormData();
		formData.append('profile_picture', profilePicture);
		formData.append('_method', 'PUT');

		axios.post('/api/user/merchant/profile-pic', formData)
			.then(response => {
				setUser(response.data);
				setErrors({});
				alert('Your profile picture has been updated.');

				const profilePicUpdatedEvent = new CustomEvent('profilePicUpdated', {
					detail: response.data.barber.media.find(m => m.collection_name === 'avatar').original_url
				});
				window.dispatchEvent(profilePicUpdatedEvent);
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your profile picture.');
			})
			.finally(() => setProcessing(false));
	}

	const handleBarberAvatarFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		const formData = new FormData();
		formData.append('barber_avatar', barberAvatar);
		formData.append('_method', 'PUT');

		axios.post('/api/user/merchant/barber-avatar', formData)
			.then(response => {
				setUser(response.data);
				setErrors({});
				alert('Your barber avatar has been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your barber avatar.');
			})
			.finally(() => setProcessing(false));
	}

	const handleGalleryPicsFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		const formData = new FormData();

		galleryImages.forEach(img => {
			formData.append('gallery_images[]', img);
		});

		axios.post('/api/user/merchant/gallery/', formData)
			.then(response => {
				setUser(response.data);
				setErrors({});
				setGalleryImages([]);
				alert('Your gallery images have been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your gallery images.');
			})
			.finally(() => setProcessing(false));
	}

	const removeGalleryImage = (e, image) => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);

		confirmAlert({
			title: 'Delete Image?',
			message: 'Are you sure you want to delete this image?',
			accept: () => {
				axios.delete(`/api/user/merchant/gallery/${image.id}`)
					.then(response => {
						setUser(response.data);
						setErrors({});
					})
					.catch(errors => {
						setErrors(errors.response.data.errors);
						alert('An error occurred while deleting this gallery image.');
					})
					.finally(() => setProcessing(false));
			},
			decline: () => setProcessing(false),
		});
	}

	const handleAvailabilityUpdate = availability => {
		setAvailabilityUpdated(true);
		setAvailability(availability);
	}

	const handleAddressFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);
		
		const formData = new FormData(e.target);
		formData.append('_method', 'PATCH');

		axios.post('/api/user/manager/address', formData)
			.then(response => {
				setUser(response.data);
				setEditingLocation(false);
				setErrors({});
				alert('Your details have been updated.');
			})
			.catch(errors => {
				setErrors(errors.response.data.errors);
				alert('An error occurred while updating your details.');
			})
			.finally(() => setProcessing(false));
	}

	const handleAddBlockedTimeFormSubmit = e => {
		e.preventDefault();
		if (processing) return false;
		setProcessing(true);
		
		const formData = new FormData(e.target);

		axios.post('/api/user/merchant/blocked-times', formData)
			.then(response => {
				setUser(response.data);
				setBlockedTimes(response.data.blocked_times);
				setErrors({});
				alert('Blocked time added.');
			})
			.catch(error => {
				setErrors({...errors, blockedTime: error.response.data.errors});
				if (error.response.status === 500) {
					alert('An error occurred while adding this time.');
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	}

	const handleBlockedTimeDeleteClick = (id) => {
		if (processing) return false;
		setProcessing(true);
		
		confirmAlert({
			title: 'Delete Blocked Time?',
			message: 'Are you sure you want to delete this time?',
			accept: () => {
				axios.post('/api/user/merchant/blocked-times/' + id, { _method: 'DELETE' })
					.then(response => {
						setUser(response.data);
						setBlockedTimes(response.data.blocked_times);
						setErrors({});
						alert('Blocked time deleted.');
					})
					.catch(error => {
						if (error.response.status === 500) {
							alert('An error occurred while deleting this time.');
						}
					})
					.finally(() => {
						setProcessing(false);
					});
			},
			decline: () => setProcessing(false),
		});
	}

	const sortedAvailability = {
		monday: availability?.monday || false,
		tuesday: availability?.tuesday || false,
		wednesday: availability?.wednesday || false,
		thursday: availability?.thursday || false,
		friday: availability?.friday || false,
		saturday: availability?.saturday || false,
		sunday: availability?.sunday || false,
	}

	return <>
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className="section">
			<div className="container">
				<Switch>
					<Route path={`${path}/close-account`}>
						<CloseAccount />
					</Route>
					<Route>
						{!isLoaded && <LoadingSpinner center />}
						{isLoaded && !editingLocation && !editingAvailability && !editingImages && <>
							<EditMerchantForm
								firstName={firstName}
								lastName={lastName}
								email={email}
								phone={phone}
								line1={user.address_line_1}
								line2={user.address_line_2}
								city={user.address_city}
								postcode={user.address_postcode}
								setFirstName={setFirstName}
								setLastName={setLastName}
								setEmail={setEmail}
								setPhone={setPhone}
								availability={sortedAvailability}
								handleFormSubmit={handleFormSubmit}
								errors={errors}
								setEditingLocation={setEditingLocation}
								setEditingAvailability={setEditingAvailability}
								setEditingImages={setEditingImages}
								isOwner={user.is_owner}
							/>
						</>}
						{isLoaded && editingLocation && <>
							<EditBarberLocation
								onSubmit={handleAddressFormSubmit}
								errors={errors}
							/>
						</>}
						{isLoaded && editingAvailability && <>
							<EditBarberAvailability
								defaultAvailability={sortedAvailability}
								formFooter={({ availability }) => {
									return <Button
										onClick={() => handleAvailabilityUpdate(availability)}
										size="full"
										className="mt-4"
									>
										Save changes
									</Button>
								}}
							/>
							<EditBarberBlockedTimes 
								blockedTimes={blockedTimes}
								handleAddBlockedTimeFormSubmit={handleAddBlockedTimeFormSubmit}
								handleBlockedTimeDeleteClick={handleBlockedTimeDeleteClick}
								errors={errors}
							/>
						</>}
						{isLoaded && editingImages && <>
							<EditBarberImages 
								user={user}
								profilePicture={profilePicture}
								setProfilePicture={setProfilePicture}
								barberAvatar={barberAvatar}
								setBarberAvatar={setBarberAvatar}
								errors={errors}
								galleryImages={galleryImages}
								setGalleryImages={setGalleryImages}
								handleProfilePicFormSubmit={handleProfilePicFormSubmit}
								handleBarberAvatarFormSubmit={handleBarberAvatarFormSubmit}
								handleGalleryPicsFormSubmit={handleGalleryPicsFormSubmit}
								removeGalleryImage={removeGalleryImage}
							/>
						</>}
					</Route>
				</Switch>
			</div>
		</section>
	</>
}

export default AccountDetails
