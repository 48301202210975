import React from 'react'

function NumberPicker({
	onChange,
	name,
	showDiscountBadges,
}) {
	const discountRates = [0, 0.1, 0.15, 0.2, 0.25];

	return (
		<div className="number-picker">
			{[...Array(5)].map((x, i) => {
				const value = i + 1;
				return (
					<label htmlFor={`${name}_${value}`} className="number-picker__option" key={`${name}_${value}`}>
						<input
							type="radio"
							name={name}
							id={`${name}_${value}`}
							value={value}
							onChange={() => onChange(value)}
							className="number-picker__input sr-only"
						/>
						<span className="number-picker__number">{value}</span>
						{showDiscountBadges && discountRates[i] > 0 && <div className="number-picker__badge">
							{discountRates[i] * 100}%
						</div>}
					</label>
				)
			})}
		</div>
	)
}

export default NumberPicker
