import React from 'react'

function LocationIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M6.99986 16C6.99986 16 13.3999 10.6677 13.3999 6.38499C13.3999 2.90225 10.4908 0 6.99986 0C3.42583 0 0.599854 2.81933 0.599854 6.38499C0.599854 10.7507 6.99986 16 6.99986 16ZM6.99986 0.8C3.86589 0.8 1.39985 3.26293 1.39985 6.38499C1.39985 7.29038 1.73545 8.30131 2.30305 9.34764C2.8665 10.3863 3.62791 11.4039 4.40607 12.3076C5.18206 13.2087 5.96046 13.9802 6.54589 14.5269C6.71391 14.6838 6.8656 14.8218 6.99626 14.9386C7.12783 14.8195 7.28085 14.6786 7.45048 14.5184C8.03643 13.9648 8.81552 13.1852 9.59221 12.2782C10.3711 11.3686 11.1333 10.3476 11.6972 9.31162C12.2659 8.26696 12.5999 7.26736 12.5999 6.38499C12.5999 3.34585 10.0507 0.8 6.99986 0.8Z" />
			<path fillRule="evenodd" clipRule="evenodd" d="M6.99985 8C7.88351 8 8.59985 7.28366 8.59985 6.4C8.59985 5.51634 7.88351 4.8 6.99985 4.8C6.1162 4.8 5.39985 5.51634 5.39985 6.4C5.39985 7.28366 6.1162 8 6.99985 8ZM6.99985 8.8C8.32534 8.8 9.39985 7.72548 9.39985 6.4C9.39985 5.07452 8.32534 4 6.99985 4C5.67437 4 4.59985 5.07452 4.59985 6.4C4.59985 7.72548 5.67437 8.8 6.99985 8.8Z" />
		</svg>
	)
}

export default LocationIcon