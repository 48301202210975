import React from 'react'

function FilterIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="40" height="35" viewBox="0 0 40 35" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M23.1708 4.50003H2.00012V2.50003H23.1708C23.5827 1.33484 24.6939 0.500031 26 0.500031C27.3062 0.500031 28.4175 1.33484 28.8295 2.50003H38V4.50003H28.8295C28.4175 5.66523 27.3062 6.50003 26 6.50003C24.6939 6.50003 23.5827 5.66523 23.1708 4.50003Z" />
			<path d="M12.8294 16.5C12.4176 15.3348 11.3063 14.5 10.0001 14.5C8.6939 14.5 7.58267 15.3348 7.17082 16.5H2.00012V18.5H7.17082C7.58267 19.6652 8.6939 20.5 10.0001 20.5C11.3063 20.5 12.4176 19.6652 12.8294 18.5H38V16.5H12.8294Z" />
			<path d="M15.1708 30.5H2.00012V32.5H15.1708C15.5827 33.6652 16.6939 34.5 18.0001 34.5C19.3063 34.5 20.4176 33.6652 20.8294 32.5H38V30.5H20.8294C20.4176 29.3347 19.3063 28.5 18.0001 28.5C16.6939 28.5 15.5827 29.3347 15.1708 30.5Z" />
		</svg>
	)
}

export default FilterIcon