import React from 'react';
import Button from '../UI/Button';

function Step0({ setStep }) {
	return (
		<div className="hero bg-white">
			{/* <img src="/images/app-home-banner.jpg" alt="" className="bg__image" /> */}
			<div className="hero__content">
				<div className="container">
					<h1 className="h3">Set up your Barbashop account</h1>
					<p className="mb-4">It looks like you're new here. Let us guide you through getting set up on Barbashop.</p>
					<Button
						variant="primary"
						size="full"
						onClick={() => setStep(1)}
						arrow
					>
						Start your setup
					</Button>
				</div>
			</div>
			<div className="hero__bottom-content">
				<div className="container">
					<a href="/faq">Got a question? - View our FAQ</a>
				</div>
			</div>
		</div>
	);
}

export default Step0;
