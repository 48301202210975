import React from 'react'
import { useHistory } from 'react-router'
import BackButton from '../UI/BackButton'
import ConfirmedBookings from './ConfirmedBookings';
import PendingBookings from './PendingBookings';

function BookingRequests({
	bookings,
	getBookings,
}) {

	const history = useHistory();

	const openBookings = bookings.filter(b => b.status == BOOKING_STATUS.open);
	const confirmedBookings = bookings.filter(b => b.status == BOOKING_STATUS.confirmed);

	return <>
		<BackButton
			text="Back to dashboard"
			onClick={() => history.push('/')}
		/>
		<section className="section background-grey-02">
			<div className="container">
				<div className="mb-4">
					<h1>Manage my bookings</h1>
					<p>Here you can see all your appointments. Pending and accepted.</p>
				</div>
				<PendingBookings
					bookings={openBookings}
					getBookings={getBookings}
				/>
			</div>
		</section>
		<section className="section">
			<div className="container">
				<ConfirmedBookings
					bookings={confirmedBookings}
					getBookings={getBookings}
				/>
			</div>
		</section>
	</>
}

export default BookingRequests
