import React from 'react'

function PlusIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path d="M7.80005 10.2V17.4H10.2V10.2H17.4V7.79998H10.2V0.599976H7.80005V7.79998H0.599976V10.2H7.80005Z" />
		</svg>
	)
}

export default PlusIcon