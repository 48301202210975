import React from 'react'
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import CrossIcon from '../Icons/Cross';
import Button from '../UI/Button';
import LoadingSpinner from '../UI/LoadingSpinner';
import TextField from '../UI/TextField';

function EditCustomerForm({
	isLoaded,
	handleFormSubmit,
	firstName,
	lastName,
	email,
	phone,
	line1,
	line2,
	city,
	postcode,
	setFirstName,
	setLastName,
	setEmail,
	setPhone,
	setEditingLocation,
	errors,
}) {
	const history = useHistory();
	const { path } = useRouteMatch();

	return <>
		{isLoaded ? <form action="" method="POST" onSubmit={handleFormSubmit}>
			<TextField
				name="first_name"
				label="First Name"
				value={firstName} 
				onChange={(e) => setFirstName(e.target.value)}
				placeholder="John"
				errors={errors.first_name}
				required
			/>
			<TextField
				name="last_name"
				label="Last Name"
				value={lastName} 
				onChange={(e) => setLastName(e.target.value)}
				placeholder="Doe"
				errors={errors.last_name}
				required
			/>
			<TextField
				name="email"
				label="Email"
				value={email} 
				onChange={(e) => setEmail(e.target.value)}
				placeholder="Email Address"
				errors={errors.email}
				required
			/>
			<TextField
				name="phone"
				label="Phone"
				value={phone} 
				onChange={(e) => setPhone(e.target.value)}
				placeholder="07123 456 789"
				errors={errors.phone}
				required
			/>
			<TextField
				name="address"
				label="Address"
				rightIcon="pencil"
				value={`${line1}, ${line2 ? line2 + ', ' : ''}${city} ${postcode}`}
				onChange={() => null}
				inputProps={{
					onClick: () => setEditingLocation(true),
					readOnly: true
				}}
			/>
			<Button
				type="submit"
				size="small-width"
				className="mr-1"
			>
				Update
			</Button>
			<Button 
				type="button"
				variant="secondary"
				size="small-width"
				onClick={() => history.push('/')}
			>
				Cancel
			</Button>
		</form> : <div className="loading-spinner__wrap"><LoadingSpinner /></div>}
		<Link to={`${path}/close-account`} className="mt-2 d-flex items-center text__color--danger">
			<span className="icon-button icon-button--red mr-1"><CrossIcon className="icon-button__icon" /></span>
			I want to close this account
		</Link>
	</>
}

export default EditCustomerForm
