import React from 'react'

function TickIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="15" height="11" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M14.0534 0.992948L5.83139 10.4799L0.575745 5.22425L1.42427 4.37572L5.76862 8.72007L13.1466 0.207031L14.0534 0.992948Z"/>
		</svg>
	)
}

export default TickIcon