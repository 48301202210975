import React from 'react'

function DashboardIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M9.5 7.5H17V2C17 1.44772 16.5523 1 16 1H9.5V7.5ZM8.5 7.5V1H2C1.44772 1 1 1.44772 1 2V7.5H8.5ZM1 8.5H8.5V15H2C1.44772 15 1 14.5523 1 14V8.5ZM0 2C0 0.89543 0.895431 0 2 0H16C17.1046 0 18 0.89543 18 2V14C18 15.1046 17.1046 16 16 16H2C0.89543 16 0 15.1046 0 14V2ZM9.5 15V8.5H17V14C17 14.5523 16.5523 15 16 15H9.5Z" />
		</svg>
	)
}

export default DashboardIcon
