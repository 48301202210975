import React from 'react'
import useVariableInputProps from '../Hooks/useVariableInputProps';
import Field from './Field';

function SelectField({
	name,
	id,
	label,
	value,
	defaultValue,
	onChange,
	required,
	hideLabel,
	helperText,
	inputProps,
	children,
}) {
	const variableInputProps = useVariableInputProps(onChange, value, defaultValue);

	return (
		<Field
			name={name}
			label={label}
			hideLabel={hideLabel}
			helperText={helperText}
		>
			<select 
				className="text-input text-input--select" 
				name={name} 
				id={id || name}
				required={required}
				{...inputProps}
				{...variableInputProps}
			>
				{children}
			</select>
		</Field>
	)
}

SelectField.defaultProps = {
	defaultValue: '',
}

export default SelectField
