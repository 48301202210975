import React from 'react'

function WalletIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="22" height="23" viewBox="0 0 22 23" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M2.5998 4.80014C1.27439 4.80021 0.199951 5.8747 0.199951 7.20014V20.4001C0.199951 21.7256 1.27447 22.8001 2.59995 22.8001H19.4C20.7254 22.8001 21.7999 21.7256 21.7999 20.4001V7.20014C21.7999 5.87465 20.7254 4.80014 19.4 4.80014H18.92L17 6.10352e-05L2.5998 4.80014ZM16.306 1.49628L17.6275 4.79999H6.39474L16.306 1.49628ZM2.59995 6.00013H19.4C20.0627 6.00013 20.6 6.53739 20.6 7.20014V12H17C16.0058 12 15.2 12.8059 15.2 13.8C15.2 14.7941 16.0058 15.6 17 15.6H20.6V20.4001C20.6 21.0629 20.0627 21.6001 19.4 21.6001H2.59995C1.93721 21.6001 1.39995 21.0629 1.39995 20.4001V7.20014C1.39995 6.53739 1.93721 6.00013 2.59995 6.00013Z"/>
		</svg>
	)
}

export default WalletIcon
