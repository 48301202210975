import React, { useEffect, useState } from 'react'
import PencilIcon from '../Icons/Pencil'
import PlusIcon from '../Icons/Plus'
import Modal from '../UI/Modal'
import ServiceForm from './ServiceForm'
import Service from './Service'
import EditCategory from './EditCategory'

const defaultNewServiceName = '';
const defaultNewServiceDuration = '';
const defaultNewServicePrice = '';
const defaultNewServiceCategory = 0;

function Category({
	services,
	categories,
	category,
	
	onCategoryEdit,
	newServiceFormOpen,
	onNewServiceFormCancel,
	onAddServiceButtonClick,

	onServiceAdd,
	onServiceEdit,
	onServiceDelete,
	
	allowCategoryUpdates,
}) {

	const [newServiceName, setNewServiceName] = useState(defaultNewServiceName);
	const [newServiceDuration, setNewServiceDuration] = useState(defaultNewServiceDuration);
	const [newServicePrice, setNewServicePrice] = useState(defaultNewServicePrice);
	const [newServiceCategory, setNewServiceCategory] = useState(defaultNewServiceCategory);

	const [editCategoryFormOpen, setEditCategoryFormOpen] = useState(false);

	useEffect(() => {
		if (categories.length > 0) {
			setNewServiceName(defaultNewServiceName);
			setNewServiceDuration(defaultNewServiceDuration);
			setNewServicePrice(defaultNewServicePrice);
			setNewServiceCategory(categories[0].id);
		}
	}, [categories, services]);

	const handleAddServiceButtonClick = e => {
		onAddServiceButtonClick(e);
		setNewServiceCategory(category.id);
	}

	const handleNewServiceFormSubmit = e => {
		e.preventDefault();
		onServiceAdd({
			name: newServiceName,
			duration: newServiceDuration,
			price: newServicePrice,
			category_id: parseInt(newServiceCategory),
		});
	}

	const handleCancelEditCategoryButtonClick = e => {
		setEditCategoryFormOpen(false);
	}

	const categoryServices = services.filter(s => s.category_id === category.id);

	return (
		<div className="services-manager__category">
			<div className="services-manager__category-header">
				<div className="services-manager__category-title">
					<h4 className="d-xs-inline-block mb-0">{category.name}</h4> <small>({categoryServices.length} Service{categoryServices.length === 1 ? '' : 's'})</small>
				</div>
				{allowCategoryUpdates &&
					<Modal 
						isOpen={editCategoryFormOpen}
						title="Edit category"
						onClick={handleCancelEditCategoryButtonClick}
						content={<EditCategory
							category={category}
							onCategoryEdit={onCategoryEdit}
							setEditFormOpen={setEditCategoryFormOpen}
						/>}
					>
						<button className="services-manager__action-button" onClick={() => setEditCategoryFormOpen(true)}>
							<PencilIcon className="services-manager__action-button-icon" />
						</button>
					</Modal>
				}
			</div>
			<ul className="service-list services-manager__service-list">
				{categoryServices.map(s => <Service 
					key={`service_${category.id}_${s.id}`}
					service={s} 
					categories={categories}
					onServiceDelete={() => onServiceDelete(s)}
					onServiceEdit={onServiceEdit}
				/>)}
				<Modal
					isOpen={newServiceFormOpen}
					title="Add your services"
					subtitle="Show customers what services you offer"
					onClick={onNewServiceFormCancel}
					content={<ServiceForm
						categories={categories}
						serviceName={newServiceName}
						serviceDuration={newServiceDuration}
						servicePrice={newServicePrice}
						serviceCategory={newServiceCategory}
						setServiceName={setNewServiceName}
						setServiceDuration={setNewServiceDuration}
						setServicePrice={setNewServicePrice}
						setServiceCategory={setNewServiceCategory}
						onSubmit={handleNewServiceFormSubmit}
						onCancel={onNewServiceFormCancel}
					/>}
				>
					<div className="service-list__item services-manager__service-list-item p-0">
						<button 
							className="d-flex items-center justify-center p-2 text__align--center w-full" 
							onClick={handleAddServiceButtonClick}
						>
							<PlusIcon className="mr-1" width="12" height="12" /> Add Service
						</button>
					</div>
				</Modal>
			</ul>
		</div>
	)
}

export default Category
