import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Step0 from './MerchantOnboarding/Step0';
import Step1 from './MerchantOnboarding/Step1';
import Step2 from './MerchantOnboarding/Step2';
import Step3 from './MerchantOnboarding/Step3';
import Step4 from './MerchantOnboarding/Step4';
import Step5 from './MerchantOnboarding/Step5';
import Step6 from './MerchantOnboarding/Step6';
import Step7 from './MerchantOnboarding/Step7';

function MerchantOnboarding({ baseRoute, authStep, authToken }) {
	const storage = window.sessionStorage;

	const [token, setToken] = useState(authToken || '');
	const [name, setName] = useState('');
	const [step, setStepInternal] = useState(authStep || 0);
	const [authed, setAuthed] = useState(false);

	const registerToken = (newToken) => {
		setToken(newToken);
		storage.setItem('token', newToken);
	};

	const setStep = (newStep) => {
		setStepInternal(newStep);
		storage.setItem('step', newStep);
	};

	useEffect(() => {
		const newToken = storage.getItem('token');

		if (authToken) {
			setAuthed(true);
		} else {
			setToken(newToken);
		}

		const newStep = parseInt(storage.getItem('step') || 0);

		setStepInternal(Math.max(newStep, (authStep || 0)));
	}, []);
	
	let redirect = '/create-account';

	switch (step) {
		case 0:
			redirect = '/';
			break;
		case 1:
			redirect = '/create-account';
			break;
		case 2:
			redirect = '/your-address';
			break;
		case 3:
			redirect = '/your-details';
			break;
		case 4:
			redirect = '/your-services';
			break;
		case 5:
			redirect = '/your-availability';
			break;
		case 6:
			redirect = '/billing';
			break;
		case 7:
			redirect = '/done';
			break;
	}

	return <Router basename={baseRoute}>
		{/* {authed && <div className="pb-2">
			<small><strong>Your profile is complete.</strong> You must complete your registration before you can use
			your account.</small>
		</div>} */}
		<Switch>
			{step === 0 && <Route path="/">
				<Step0 setStep={setStep} />
			</Route>}
			{step === 1 && <Route path="/create-account">
				<section className="section">
					<div className="container">
						<Step1 setStep={setStep} setName={setName} registerToken={registerToken} />
					</div>
				</section>
			</Route>}
			{step === 2 && <Route path="/your-address">
				<section className="section">
					<div className="container">
						<Step2 setStep={setStep} token={token} />
					</div>
				</section>
			</Route>}
			{step === 3 && <Route path="/your-details">
				<section className="section">
					<div className="container">
						<Step3 setStep={setStep} token={token} name={name} />
					</div>
				</section>
			</Route>}
			{step === 4 && <Route path="/your-services">
				<section className="section">
					<div className="container">
						<Step4 setStep={setStep} token={token} />
					</div>
				</section>
			</Route>}
			{step === 5 && <Route path="/your-availability">
				<section className="section">
					<div className="container">
						<Step5 setStep={setStep} token={token} />
					</div>
				</section>
			</Route>}
			{step === 6 && <Route path="/billing">
				<section className="section">
					<div className="container">
						<Step6 setStep={setStep} token={token} />
					</div>
				</section>
			</Route>}
			<Route path="/done">
				<Step7 setStep={setStep} token={token} />
			</Route>
			<Route path="/">
				<Redirect to={redirect} />
			</Route>
		</Switch>
	</Router>;
}

export default MerchantOnboarding;
