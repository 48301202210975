import React, { useState } from 'react'
import Button from '../UI/Button';
import RadioField from '../UI/RadioField';
import TextField from '../UI/TextField';

const reasons = [
	'Rearranged for a different day/time',
	'Change of circumstances',
	'Unwell for a different day/time',
	'Other',
];

function CancelBooking({
	updateStatus,
}) {

	const [reason, setReason] = useState('');
	const [otherReason, setOtherReason] = useState('');

	const handleFormSubmit = e => {
		e.preventDefault();
		updateStatus('cancel', reason === 'Other' ? otherReason : reason);
	}

	return (
		<form onSubmit={handleFormSubmit}>
			{reasons.map((r, i) => {
				return <RadioField
					key={`cancel_reason_${i}`}
					checked={reason === r}
					onChange={e => setReason(e.target.value)}
					name="cancel-booking-reason"
					required
					value={r}
					label={r}
				/>
			})}
			{reason === 'Other' &&
				<TextField
					value={otherReason}
					onChange={e => setOtherReason(e.target.value)}
					label="Reason not listed? Tell us here..."
					required
				/>
			}
			<Button
				type="submit"
				size="full"
			>
				Confirm this cancellation
			</Button>
		</form>
	)
}

export default CancelBooking
