import React from 'react'

function ArrowRightIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="21" height="12" viewBox="0 0 21 12" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M17.9451 5.35773L14.1778 1.62626L15.0222 0.773682L20.2485 5.95023L15.0263 11.2222L14.1737 10.3777L17.9577 6.5577L0.201434 6.59997L0.198578 5.39997L17.9451 5.35773Z" />
		</svg>
	)
}

export default ArrowRightIcon