import React from 'react'
import { useHistory, useRouteMatch } from 'react-router';
import formatDate from '../../utils/formatDate'
import timestampToDate from '../../utils/timestampToDate'
import ChevronRightIcon from '../Icons/ChevronRight'
import BarberAvatar from '../UI/BarberAvatar';

function PastBooking({
	booking,
	setSelectedBooking,
}) {
	const history = useHistory();
	const { path, url } = useRouteMatch();

	const handleBookingClick = e => {
		e.preventDefault();
		setSelectedBooking(booking);
		history.push(`${path}/past-bookings/${booking.id}`)
	}

	return (
		<li className="arrow-links__item">
			<button className="arrow-links__link text__align--left" onClick={handleBookingClick}>
				<div className="confirmed-booking-card">
					<BarberAvatar barber={booking.barber} size="large" className="confirmed-booking-card__avatar" />
					<div className="confirmed-booking-card__content">
						<p className="sml mb-1">{booking.status === BOOKING_STATUS.cancelled ? '🚫' : '✅'} {formatDate(timestampToDate(booking.date))}</p>
						<h5 className="confirmed-booking-card__customer-name">{booking.barber.name}</h5>
						<div className="muted-text muted-text--light confirmed-booking-card__services">
							{booking.services.map(s => s.name).join(' - ')}
						</div>
					</div>
				</div>
				<ChevronRightIcon className="arrow-links__icon" />
			</button>
		</li>
	)
}

export default PastBooking
