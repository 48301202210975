import React from 'react';

function PaymentMethodIcon({ type, className, iconClassName }) {
    let icon = '';

    switch (type) {
        case 'visa':
            icon = <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${iconClassName || ''}`}>
                <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#D9D9D9"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7503 15.8582H8.69056L7.146 9.79235C7.07269 9.51332 6.91703 9.26664 6.68806 9.15038C6.11664 8.85821 5.48696 8.62568 4.80005 8.50841V8.27487H8.11813C8.57607 8.27487 8.91953 8.62568 8.97677 9.0331L9.77817 13.4086L11.8369 8.27487H13.8394L10.7503 15.8582ZM14.9843 15.8582H13.039L14.6408 8.27487H16.5861L14.9843 15.8582ZM19.1028 10.3757C19.16 9.96725 19.5035 9.73372 19.9042 9.73372C20.5338 9.67508 21.2197 9.79235 21.7922 10.0835L22.1356 8.45079C21.5632 8.21725 20.9335 8.09998 20.3621 8.09998C18.4741 8.09998 17.1003 9.15038 17.1003 10.6082C17.1003 11.7173 18.0734 12.2996 18.7603 12.6504C19.5035 13.0002 19.7897 13.2337 19.7324 13.5835C19.7324 14.1082 19.16 14.3418 18.5886 14.3418C17.9017 14.3418 17.2147 14.1669 16.5861 13.8747L16.2426 15.5084C16.9295 15.7996 17.6727 15.9169 18.3596 15.9169C20.4766 15.9745 21.7922 14.9251 21.7922 13.35C21.7922 11.3664 19.1028 11.2502 19.1028 10.3757ZM28.6 15.8582L27.0555 8.27487H25.3965C25.053 8.27487 24.7095 8.50841 24.5951 8.85821L21.7349 15.8582H23.7374L24.1371 14.7502H26.5976L26.8265 15.8582H28.6ZM25.6827 10.3171L26.2541 13.1751H24.6523L25.6827 10.3171Z" fill="#172B85"/>
            </svg>;
            break;
        case 'mastercard':
            icon = <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${iconClassName || ''}`}>
                <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#D9D9D9"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.179 16.8294C15.9949 17.8275 14.459 18.43 12.7807 18.43C9.03582 18.43 6 15.4303 6 11.73C6 8.02966 9.03582 5.02997 12.7807 5.02997C14.459 5.02997 15.9949 5.63247 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5773 5.02997C25.3221 5.02997 28.358 8.02966 28.358 11.73C28.358 15.4303 25.3221 18.43 21.5773 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z" fill="#ED0006"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68801 18.6369 7.85941 17.179 6.63051C18.363 5.63247 19.8989 5.02997 21.5772 5.02997C25.3221 5.02997 28.3579 8.02966 28.3579 11.73C28.3579 15.4303 25.3221 18.43 21.5772 18.43C19.8989 18.43 18.363 17.8275 17.179 16.8294Z" fill="#F9A000"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.179 16.8294C18.6369 15.6005 19.5614 13.7719 19.5614 11.73C19.5614 9.68805 18.6369 7.85946 17.179 6.63055C15.7211 7.85946 14.7966 9.68805 14.7966 11.73C14.7966 13.7719 15.7211 15.6005 17.179 16.8294Z" fill="#FF5E00"/>
            </svg>;
            break;
        case 'amex':
            icon = <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${iconClassName || ''}`}>
                <rect width="34" height="24" rx="4" fill="#1F72CD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M6.09542 8.5L2.91431 15.7467H6.72254L7.19465 14.5913H8.27379L8.7459 15.7467H12.9377V14.8649L13.3112 15.7467H15.4795L15.8531 14.8462V15.7467H24.5709L25.6309 14.6213L26.6235 15.7467L31.1011 15.7561L27.91 12.1436L31.1011 8.5H26.6929L25.661 9.60463L24.6997 8.5H15.2159L14.4015 10.3704L13.568 8.5H9.76774V9.35186L9.34498 8.5H6.09542ZM6.83229 9.52905H8.6886L10.7986 14.4431V9.52905H12.8322L14.4619 13.0524L15.9639 9.52905H17.9873V14.7291H16.7561L16.746 10.6544L14.9511 14.7291H13.8498L12.0448 10.6544V14.7291H9.51203L9.03187 13.5633H6.43769L5.95851 14.728H4.60147L6.83229 9.52905ZM24.1198 9.52905H19.1137V14.726H24.0423L25.6309 13.0036L27.1621 14.726H28.7627L26.4362 12.1426L28.7627 9.52905H27.2315L25.651 11.2316L24.1198 9.52905ZM7.73532 10.4089L6.88064 12.4856H8.58901L7.73532 10.4089ZM20.3499 11.555V10.6057V10.6048H23.4736L24.8366 12.1229L23.4132 13.6493H20.3499V12.613H23.081V11.555H20.3499Z" fill="white"/>
            </svg>;
            break;
        default:
            icon = <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${iconClassName || ''}`}>
                <rect x="0.5" y="0.5" width="33" height="23" rx="3.5" fill="white" stroke="#D9D9D9"/>
            </svg>;
            break;
    }

    return (
        <div className={`payment-method__icon payment-method__icon--${type} ${className || ''}`}>
            {icon}
        </div>
    );
}

export default PaymentMethodIcon;