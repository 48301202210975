import React, { useEffect } from 'react';
import CalendarIcon from '../Icons/Calendar';
import CrossIcon from '../Icons/Cross';
import timestampToDate from '../../utils/timestampToDate';
import { format } from 'date-fns'
import PaymentMethodIcon from '../PaymentMethodIcon';
import * as Add2Calendar from "add2calendar"
import 'add2calendar/css/add2calendar.css'

function BarberBookingConfirmation() {

	const bookingDate = timestampToDate(booking.date);

	useEffect(() => {
		const singleEvent = new Add2Calendar({
			title: `Booking with ${booking.staff.full_name} (${booking.barber.name})`,
			start: timestampToDate(booking.date),
			end: timestampToDate(booking.end_date),
		});
		singleEvent.createWidget('#add-to-calendar-button');
	}, []);

	return <div className="booking-confirmation">
		<div className="container">
			<div className="booking-confirmation__close-button-wrap">
				<a className="icon-button booking-confirmation__close-button" href="/">
					<CrossIcon className="icon-button__icon" />
				</a>
			</div>
			<h1 className="h2 booking-confirmation__title">Thank you!</h1>
			<div className="booking-confirmation__inner">
				<h2 className="h5 booking-confirmation__subtitle">Appointment confirmed ✂️💈</h2>
				<table className="booking-confirmation-table">
					<tbody>
						<tr>
							<th scope="row">Barber</th>
							<td>
								<strong>{booking.staff.full_name}</strong>
								<div className="muted-text muted-text--light">{booking.barber.name}</div>
							</td>
						</tr>
						<tr>
							<th scope="row">No of guests</th>
							<td>{booking.total_guests}</td>
						</tr>
						<tr>
							<th scope="row">Services</th>
							<td>{booking.services.map(s => s.name).join(', ')}</td>
						</tr>
						<tr>
							<th scope="row">Date</th>
							<td>{format(bookingDate, 'EEEE do MMM')}</td>
						</tr>
						<tr>
							<th scope="row">Time</th>
							<td>{format(bookingDate, 'hh:mmaaa')}</td>
						</tr>
						<tr className="booking-confirmation-table__total">
							<th scope="row">Total</th>
							<td>£{booking.gross_price}</td>
						</tr>
					</tbody>
					<tfoot>
						{/* TODO: add calendar link */}
						<tr className="booking-confirmation-table__footer">
							<th>
								<div id="add-to-calendar-button"></div>
							</th>
							<td className="booking-payment-details">
								<PaymentMethodIcon 
									type={booking.payment.brand} 
									className="booking-payment-details__logo" 
									iconClassName="booking-payment-details__icon" 
								/>
								●●●● {booking.payment.last4}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>;
}

export default BarberBookingConfirmation;
