import React from 'react'

function MailIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="24" height="18" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M0 3.00001V15C0 16.3255 1.07452 17.4 2.4 17.4H21.6C22.9255 17.4 24 16.3255 24 15V3.00001C24 1.67452 22.9255 0.600006 21.6 0.600006H2.4C1.07452 0.600006 0 1.67452 0 3.00001ZM22.8 3.02029V3.00001C22.8 2.33726 22.2627 1.80001 21.6 1.80001H2.4C1.73726 1.80001 1.2 2.33726 1.2 3.00001V3.02029L11.0739 8.94464C11.6439 9.28665 12.3561 9.28665 12.9261 8.94464L22.8 3.02029ZM1.2 4.41972V15C1.2 15.6627 1.73726 16.2 2.4 16.2H21.6C22.2627 16.2 22.8 15.6627 22.8 15V4.41972L13.5435 9.97363C12.5934 10.5437 11.4066 10.5437 10.4565 9.97363L1.2 4.41972Z"/>
		</svg>
	)
}

export default MailIcon