import React from 'react'
import { useState } from 'react';

function Tabs({
	children,
	initialTab,
	className,
	triggersContainerClassName,
	triggerButtonClassName,
}) {

	const validTabs = children

	const [activeTab, setActiveTab] = useState(initialTab ? initialTab : validTabs[0].id);

	const renderTab = tab => {
		const { content, id } = tab.props;

		return (id === activeTab) && (
			<div key={`tab_panel_${id}`}>
				{content}
			</div>
		)
	}

	const renderTrigger = tab => {
		const { trigger, id } = tab.props;
		const activeClassName = (id === activeTab) ? 'is-active' : '';
		return (
			<button
				onClick={e => setActiveTab(id)}
				key={`tab_trigger_${id}`}
				className={triggerButtonClassName + ' ' + activeClassName}
			>
				{trigger}
			</button>
		)
	}

	const triggers = validTabs.map(renderTrigger);
	const panels = validTabs.map(renderTab);

	return (
		<div className={className}>
			<div className={triggersContainerClassName}>
				{triggers}
			</div>
			<div>
				{panels}
			</div>
		</div>
	)
}

export default Tabs
