import React, { useState } from 'react';
import SwitchField from './UI/SwitchField';
import TimeInput from './UI/TimeInput';

const defaultFrom = '08:00';
const defaultTo = '18:00';

function EditBarberAvailability({
	defaultAvailability,
	formFooter,
}) {
	const [availability, setAvailability] = useState(defaultAvailability);

	const setDay = (day, value) => {
		const data = Object.assign({}, availability);
		if (value === true) {
			data[day] = [{ from: defaultFrom, to: defaultTo }]
		} else if (typeof value === 'object') {
			data[day] = [value];
		} else {
			data[day] = false;
		}
		setAvailability(data);
	};

	const setDayFrom = (day, value) => {
		let data = Object.assign({}, availability[day][0]);

		if (data !== false) {
			if (typeof data !== 'object') {
				data = { from: defaultFrom };
			}

			data.from = value;
			setDay(day, data);
		}
	};

	const setDayTo = (day, value) => {
		let data = Object.assign({}, availability[day][0]);

		if (data !== false) {
			if (typeof data !== 'object') {
				data = { to: defaultTo };
			}

			data.to = value;
			setDay(day, data);
		}
	};

	return (
		<div className="pb-12">
			<h1>Set your working hours</h1>
			<p>When can clients book your service?</p>
			<div>
				{Object.keys(availability).map(day => <div key={day} className="day-input">
					<SwitchField
						checked={availability[day]}
						onChange={(e) => setDay(day, e.target.checked)}
						label={day.charAt(0).toUpperCase() + day.slice(1)}
					/>
					{availability[day] ? <div className="day-input__time">
						<TimeInput value={availability[day][0].from ?? defaultFrom} onChange={(e) => setDayFrom(day, e.target.value)} />
						<span>-</span>
						<TimeInput value={availability[day][0].to ?? defaultTo} onChange={(e) => setDayTo(day, e.target.value)} />
					</div> : <div>Closed</div>}
				</div>)}
			</div>
			{formFooter({ availability })}
		</div>
	);
}

EditBarberAvailability.defaultProps = {
	defaultAvailability: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false
	},
};

export default EditBarberAvailability;
