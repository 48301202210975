import React, { useState } from 'react';
import timestampToDate from '../../utils/timestampToDate';
import formatDate from '../../utils/formatDate';
import CrossIcon from '../Icons/Cross';
import TickIcon from '../Icons/Tick';
import Button from '../UI/Button';
import axios from 'axios';
import googleMapsUrl from '../../utils/googleMapsUrl';
import confirmAlert from '../../utils/confirmAlert';

function PendingBooking({
	booking,
	getBookings,
}) {

	const [acceptingBooking, setAcceptingBooking] = useState(false);
	const [rejectingBooking, setRejectingBooking] = useState(false);

	const handleAcceptBookingClick = () => {
		if (acceptingBooking || rejectingBooking) return false;
		setAcceptingBooking(true);

		confirmAlert({
			title: 'Accept Booking?',
			message: 'Are you sure you want to accept this booking?',
			accept: () => {
				axios.patch(`/api/user/merchant/bookings/${booking.id}/confirm/`)
					.then(getBookings)
					.finally(() => setAcceptingBooking(false));
			},
			decline: () => setAcceptingBooking(false),
		});
	}

	const handleRejectBookingClick = () => {
		if (acceptingBooking || rejectingBooking) return false;
		setRejectingBooking(true);

		confirmAlert({
			title: 'Decline Booking?',
			message: 'Are you sure you want to decline this booking?',
			accept: () => {
				axios.patch(`/api/user/merchant/bookings/${booking.id}/reject/`)
					.then(getBookings)
					.finally(() => setRejectingBooking(false));
			},
			decline: () => setRejectingBooking(false),
		});
	}

	const startTime = timestampToDate(booking.start_time);
	return (
		<div className="card pending-booking-card">
			<p className="mb-1">{booking.customer_full_name}</p>
			<p className="sml">Barber: {booking.staff.full_name}</p>
			<p className="sml">{formatDate(startTime)}</p>
			<p className="sml">
				<span className="pending-booking-card__meta">
					<span className="mr-1">✂️</span>
					<span>
						{booking.services.map(s => <span className="d-block" key={`${booking.id}_${s.id}`}>{s.name} x {s.quantity}</span>)}
					</span>
				</span>
				<span className="pending-booking-card__meta">
					<span className="mr-1">⏰</span>
					{booking.total_duration} mins
				</span>
			</p>
			<p className="muted-text muted-text--light">Price: £{booking.gross_price}</p>
			<div className="pending-booking-card__action-buttons">
				<Button 
					onClick={handleAcceptBookingClick} 
					variant="success" 
					className="pending-booking-card__action-button" 
					size="small"
					disabled={acceptingBooking || rejectingBooking}
				>
					Accept
					<TickIcon className="pending-booking-card__action-button-icon" />
				</Button>
				<Button 
					onClick={handleRejectBookingClick} 
					variant="error" 
					className="pending-booking-card__action-button" 
					size="small"
					disabled={acceptingBooking || rejectingBooking}
				>
					Decline
					<CrossIcon className="pending-booking-card__action-button-icon" />
				</Button>
			</div>
		</div>
	)
}

export default PendingBooking
