import React from 'react';
import ReactDOM from 'react-dom';

import componentMap from '../components';
import alert from '../utils/alert';

class ReactMounter {
	constructor() {
		this.map = componentMap;
		this.mounted = [];
	}

	unmount() {
		this.mounted.forEach(element => {
			ReactDOM.unmountComponentAtNode(element);
		});

		this.mounted = [];
	}

	mount() {
		
		// override default alert function, for compatibility in webviews
		window.alert = alert;
		
		Object.keys(this.map).forEach(selector => {
			const element = document.querySelector(selector);
		
			if (element) {
				const Component = this.map[selector];
				ReactDOM.render(<Component {...element.dataset} />, element);
				this.mounted.push(element);
			}
		});
	}
}

export default new ReactMounter();