import React from 'react'
import CrossIcon from '../Icons/Cross'
import Button from '../UI/Button'
import SelectField from '../UI/SelectField'
import TextField from '../UI/TextField'

function ServiceForm({
	onSubmit,
	onCancel,
	categories,
	serviceName,
	serviceDuration,
	servicePrice,
	serviceCategory,
	setServiceName,
	setServiceDuration,
	setServicePrice,
	setServiceCategory,
}) {
	return (
		<form className="mb-4 pt-2" onSubmit={onSubmit}>
			<TextField
				name="name"
				label="Service Name"
				placeholder="Scissor cut"
				required
				value={serviceName} 
				onChange={e => setServiceName(e.target.value)}
			/>
			<TextField
				name="duration"
				type="number"
				label="Service Duration (mins)"
				placeholder="15m"
				required
				value={serviceDuration} 
				onChange={e => setServiceDuration(e.target.value)}
				inputProps={{
					min: 1,
					step: 1,
				}}
			/>
			<TextField
				name="price"
				type="number"
				label="Price (£)"
				placeholder="20.00"
				required
				value={servicePrice} 
				onChange={e => setServicePrice(e.target.value)}
				inputProps={{
					min: 0.01,
					step: 0.01,
				}}
			/>
			<SelectField
				name="category"
				label="Category"
				required
				value={serviceCategory} 
				onChange={e => setServiceCategory(e.target.value)}
				options={categories.map(c => ({ value: c.id, text: c.name }))}
			>
				{categories.map(c => <option value={c.id} key={c.id}>{c.name}</option>)}
			</SelectField>
			<div className="d-flex justify-between items-center">
				<button className="icon-button icon-button--red" type="button" onClick={onCancel}>
					<CrossIcon className="icon-button__icon" />
				</button>
				<Button type="submit">Save</Button>
			</div>
		</form>
	)
}

export default ServiceForm
