import React, { useState } from 'react'
import FormError from './UI/FormError';
import ImagePicker from './UI/ImagePicker'

function ProfilePicturePicker({
	setCurrentImage, 
	currentImage, 
	errors,
	required,
	title,
	icon,
}) {

	return <div className="mb-4">
		<div className="profile-picture-picker">
			<ImagePicker
				currentImage={currentImage}
				onChange={file => setCurrentImage(file)}
				containerClassName="profile-picture-picker__field"
				inputName="profile-pic"
				inputId="profile-pic"
				rounded
				icon={icon}
				required={required}
			/>
			<div className="profile-picture-picker__text">
				<div className="label">{title}</div>
				<p className="muted-text muted-text--light">(This will be shown on your profile)</p>
			</div>
		</div>
		{errors.map((e, i) => <FormError key={`profile-pic_error_${i}`} message={e} />)}
	</div>}

ProfilePicturePicker.defaultProps = {
	title: "Pick your profile picture",
	errors: [],
};

export default ProfilePicturePicker
