import React from 'react'
import PlusIcon from '../Icons/Plus';

function FAB({
	onClick,
}) {
	return (
		<button className="fab icon-button icon-button--fill-black icon-button--large" onClick={onClick}>
			<PlusIcon className="icon-button__icon" />
		</button>
	)
}

export default FAB
