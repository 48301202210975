import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import FrontPage from './CustomerDashboard/FrontPage';
import AccountDetails from './CustomerDashboard/AccountDetails';
import ManageBookings from './CustomerDashboard/ManageBookings';
import PaymentDetails from './CustomerDashboard/PaymentDetails';
import AccountSettings from './AccountSettings';
import axios from 'axios';
import Nav from './CustomerDashboard/Nav';

const stripePromise = loadStripe(`${process.env.MIX_STRIPE_KEY}`);

const CustomerDashboard = ({ baseRoute }) => {
	return <Router basename={baseRoute}>
		<CustomerDashboardInner />
	</Router>
};

const CustomerDashboardInner = () => {

	const [user, setUser] = useState({});
	const [userLoaded, setUserLoaded] = useState(false);
	const [upcomingBookings, setUpcomingBookings] = useState([]);

	useEffect(() => {
		getUser();
	}, []);

	const getUser = () => {
		axios.get('/api/user')
			.then(response => {
				setUser(response.data);
				setUserLoaded(true);
			});
	}

	return <Elements stripe={stripePromise}>
		<Switch>
			<Route path="/payment-details">
				<PaymentDetails />
			</Route>
			<Route path="/account-details">
				<AccountDetails user={user} setUser={setUser} userLoaded={userLoaded} />
			</Route>
			<Route path="/manage-bookings">
				<ManageBookings />
			</Route>
			<Route path="/account-settings">
				<AccountSettings user={user} setUser={setUser} userLoaded={userLoaded} />
			</Route>
			<Route path="/">
				<FrontPage user={user} userLoaded={userLoaded} />
			</Route>
		</Switch>
		<Nav />
	</Elements>;
}

export default CustomerDashboard
