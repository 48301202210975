import React, { useState } from 'react'
import GetHelp from './GetHelp';
import GiveFeedback from './GiveFeedback';
import Button from './UI/Button';
import Icon from './UI/Icon'
import Modal from './UI/Modal'

function AccountActions() {

	const [helpModalOpen, setHelpModalOpen] = useState(false);
	const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

	return (
		<>
			<ul className="account-actions mb-2">
				<li className="account-actions__item">
					<Modal 
						isOpen={helpModalOpen}
						title="Barbashop are always here to help."
						subtitle="We’re here for 24/7 support."
						content={<GetHelp />}
						onClick={() => setHelpModalOpen(false)}
					>
						<button className="account-actions__link" onClick={() => setHelpModalOpen(true)}>
							<div className="account-actions__text">Get help</div>
							<Icon name="question" className="account-actions__icon" />
						</button>
					</Modal>
				</li>
				<li>
					<Modal 
						isOpen={feedbackModalOpen}
						title="How satisfied are you using BARBA?"
						subtitle="We’re always looking to improve."
						content={<GiveFeedback onCloseButtonClick={() => setFeedbackModalOpen(false)} />}
						onClick={() => setFeedbackModalOpen(false)}	
					>
						<button className="account-actions__link" onClick={() => setFeedbackModalOpen(true)}>
							<div className="account-actions__text">Give us feedback</div>
							<Icon name="mail" className="account-actions__icon" />
						</button>
					</Modal>
				</li>
			</ul>
			<Button size="full" onClick={() => document.getElementById('logoutForm').submit()}>Log out</Button>
		</>
	)
}

export default AccountActions
