import React from 'react'

function SortDescIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="19" height="17" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.50004 14.7929V0H5.50004V14.7929L8.64648 11.6464L9.35359 12.3536L5.00004 16.7071L0.646484 12.3536L1.35359 11.6464L4.50004 14.7929Z" />
			<path fillRule="evenodd" clipRule="evenodd" d="M19 1.5H9.00004V0.5H19V1.5ZM12 7.5H19V8.5H12V7.5ZM19 15.5H15V14.5H19V15.5Z" />
		</svg>
	)
}

export default SortDescIcon