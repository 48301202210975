import React from 'react'
import BarberRating from './BarberRating'
import BarberDistance from './BarberDistance';
import BarberAvatar from './UI/BarberAvatar';

function BarberListing({
	barber,
	onClick,
}) {
	return (
		<div>
			<button className="barber-search-results__button barber-listing" onClick={onClick}>
				<BarberAvatar barber={barber} size="xl" className="barber-listing__image" />
				<div className="barber-listing__content">
					<BarberRating rating={barber.rating} className="mb-1" />
					<h5 className="barber-listing__name">{barber.name}</h5>
					<p className="barber-listing__services">{barber.services}</p>
					{barber.hasOwnProperty('distance') && barber.distance !== false && 
						<BarberDistance distance={barber.distance} className="barber-listing__location" />
					}
				</div>
			</button>
		</div>
	)
}

export default BarberListing
