import React from 'react';

function TimeInput({
	name,
	id,
	type,
	value,
	onChange,
	required,
}) {
    let options = [];

    for (let i = 0; i < 24; i += 0.5) {
        let string = (Math.floor(i)).toString().padStart(2, '0') + ':' + (Number.isInteger(i) ? '00' : (60 * (i % 1)).toString());
        options.push(<option key={i} value={string}>{string}</option>);
    }

	return (
        <select value={value} onChange={onChange} style={{ appearance: 'none', paddingInline: '4px' }}>
            {options}
        </select>
	);
}

TimeInput.defaultProps = {
	type: 'text',
};

export default TimeInput;
