import React from 'react'
import FilterIcon from '../Icons/Filter'
import SortIcon from '../Icons/Sort'
import BarberSearchSorting from './BarberSearchSorting'

function BarberSearchActions({
	count,
	setFiltersOpen,
	sortingOpen,
	setSortingOpen,
	sortOption,
	setSortOption,
	auth,
	postcode,
	searchBoxValue,
}) {
	return (
		<div className="barber-search-actions">
			<div className="container barber-search-actions__container">
				<div className="barber-search-actions__result-count">{count} result{count !== 1 && 's'}</div>
				<div className="barber-search-actions__buttons">
					<BarberSearchSorting
						visible={sortingOpen}
						setVisible={setSortingOpen}
						sortOption={sortOption}
						setSortOption={setSortOption}
						auth={auth}
						postcode={postcode}
						searchBoxValue={searchBoxValue}
					/>
					<button className="barber-search-actions__button ml-2" onClick={setFiltersOpen}>
						Filter <div className="icon-button icon-button--grey ml-1"><FilterIcon className="icon-button__icon" /></div>
					</button>
				</div>
			</div>
		</div>
	)
}

export default BarberSearchActions
