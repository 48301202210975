import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router';
import PastBooking from './PastBooking'
import PastBookingPanel from './PastBookingPanel'
import UpcomingBooking from './UpcomingBooking';

function PastBookings({
	bookings,
	getBookings,
	cancelledBookings
}) {
	const history = useHistory();
	const { path, url } = useRouteMatch();

	const [selectedBooking, setSelectedBooking] = useState(null);

	const clearBooking = () => {
		setSelectedBooking(null);
		history.push(path);
	}

	return <>
		<div className="d-flex justify-between">
			<h2 className="h4 d-flex">Past <span className="booking-count-badge ml-1">{bookings.length}</span></h2>
		</div>
		<ul className="arrow-links">
			{bookings.map(b => <PastBooking
				key={`past_booking_${b.id}`}
				booking={b}
				setSelectedBooking={setSelectedBooking}
			/>)}
		</ul>

		<h2 className="h4 d-flex">Cancelled <span className="booking-count-badge ml-1">{cancelledBookings.length}</span></h2>
		<ul className="p-0">
			{cancelledBookings.map(b => <UpcomingBooking 
				key={`upcoming_booking_${b.id}`}
				booking={b} 
				getBookings={getBookings}
				setSelectedBooking={setSelectedBooking}
			/>)}
		</ul>
		<PastBookingPanel
			booking={selectedBooking}
			bookings={bookings}
			setSelectedBooking={setSelectedBooking}
			onClose={clearBooking}
			getBookings={getBookings}
			clearBooking={clearBooking}
		/>
	</>
}

export default PastBookings
