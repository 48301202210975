import React, { useRef, useState } from 'react';
import axios from 'axios';

function AddressLookup({
    onResolve,
    placeholder,
    inputName,
}) {
    const [results, setResults] = useState([]);
    const [postcode, setPostcode] = useState(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has('postcode') ? urlParams.get('postcode') : '';
    });

    const lookup = (postcode, isContainer) => {
        if (!isContainer) {
            setPostcode(postcode);
        }

        if (postcode) {
            const params = new URLSearchParams();

            if (isContainer) {
                params.append('container', postcode.id);
            } else {
                params.append('postcode', postcode);
            }
    
            axios.get('/api/address-lookup', { params })
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        setResults(res.data);
                    } else {
                        setResults([]);
                    }
                    if (isContainer && res.data.length < 1) {
                        setPostcode(postcode.postcode);
                        onResolve(postcode);
                    }
                })
                .catch(() => {
                    setResults([]);
                });
        } else {
            setResults([]);
        }
    };

    return <div className="address-lookup">
        <input 
            className="address-lookup__input" 
            placeholder={placeholder} 
            value={postcode} 
            type="text" 
            name={inputName} 
            required 
            onInput={(e) => lookup(e.target.value, false)}
        />
        <div className={'address-lookup__results' + (results.length > 0 ? ' address-lookup__results--visible' : '')}>
            {results.map((result, index) => <button onClick={() => lookup(result, true)} key={index} type="button">
                <strong className="mb-1">{result.label}</strong>
                <small>{result.number}</small>
            </button>)}
        </div>
    </div>;
}

AddressLookup.defaultProps = {
    placeholder: 'Enter postcode',
    inputName: 'address_lookup_postcode'
}

export default AddressLookup;