import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import Tab from './UI/Tab';
import Tabs from './UI/Tabs';
import CalendarIcon from './Icons/Calendar';
import StarIcon from './Icons/Star';
import InfoIcon from './Icons/Info';
import CrossIcon from './Icons/Cross';

import BarberDetails from './BarberDetails';
import BarberRating from './BarberRating'
import BarberBookingServices from './BarberBooking/BarberBookingServices';
import BarberDistance from './BarberDistance';
import Reviews from './Reviews';
import BarberAvatar from './UI/BarberAvatar';
import googleMapsUrl from '../utils/googleMapsUrl';

function BarberProfile({ 
	staff,
	booking,
	setBooking,
	closePanel,
	goToBookingAppointment,
	auth
}) {
	const barber = staff.barber;

	const hasImages = barber?.gallery_image_urls && barber?.gallery_image_urls.length > 0;
	return <div className={`barber-profile ${hasImages ? '' : 'barber-profile--no-images'}`}>
		<button className="icon-button barber-profile__close-button" onClick={closePanel}>
			<CrossIcon className="icon-button__icon" />
		</button>
		{hasImages &&
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				modules={[Pagination]}
				pagination={{ clickable: true, bulletElement: 'button' }}
			>
				{barber?.gallery_image_urls.map((url, i) => <SwiperSlide key={`barber_${barber?.id}_gallery_image_${i}`}>
					<div className="barber-profile__image">
						<img src={url} alt={barber?.name} />
					</div>
				</SwiperSlide>)}
			</Swiper>
		}
		<div className="container">
			<header className="section pt-4 d-flex">
				<BarberAvatar barber={staff} className="mr-4" size="large" />
				<div>
					<h2 className="h5 mb-1">{staff?.full_name || <Skeleton width={128} />}</h2>
					<h4 className="h6 mb-1">{barber?.name || <Skeleton width={128} />}</h4>
					<address className="mb-1">{barber.address}</address>
					{barber?.tagline && (
						<p className="sub-title mb-1">{barber?.tagline || <Skeleton width={256} />}</p>
					)}
					<div className="d-xs-flex items-xs-center flex-wrap">
						{barber?.rating ? <BarberRating rating={{value: barber?.rating || <Skeleton width={8} />, count: barber?.reviews.length || <Skeleton width={8} />}} className="mr-2" /> : <div className="muted-text muted-text--light barber-rating mb-0 mr-2">No reviews yet</div>}
						{barber?.distance > 0 && <div className="d-flex my-1">
							<BarberDistance distance={barber?.distance} />
							<span className="muted-text muted-text--light">
								&nbsp;(<a className="muted-text--light" href={googleMapsUrl(barber.address)} target="_blank">View on map</a>)
							</span>
						</div>}
					</div>
				</div>
			</header>
		</div>
		<Tabs 
			initialTab="booking"
			triggersContainerClassName="barber-profile-tabs__triggers-container"
			triggerButtonClassName="barber-profile-tabs__button"
		>
			<Tab
				id="booking"
				booking={booking}
				setBooking={(v) => setBooking(v)}
				trigger={<><CalendarIcon className="mr-1" /> Book Now</>}
				content={<BarberBookingServices 
					booking={booking}
					setBooking={(v) => setBooking(v)} 
					barber={barber} 
					handleNextButtonClick={goToBookingAppointment} 
					auth={auth}
				/>}
			/>
			<Tab
				id="reviews"
				trigger={<><StarIcon className="mr-1" /> Reviews</>}
				content={<Reviews reviews={barber?.reviews} />}
			/>
			<Tab
				id="details"
				trigger={<><InfoIcon className="mr-1" /> Details</>}
				content={<BarberDetails barber={barber} />}
			/>
		</Tabs>
	</div>
}

export default BarberProfile
