import React from 'react'

function AccordionItem({
	title,
	content,
	isOpen,
	onClick,
	variant,
}) {
	return (
		<div className={`accordion-item ${isOpen ? 'is-open' : ''} ${variant ? 'accordion-item--' + variant : ''}`}>
			<button type="button" className="accordion-item__header" onClick={onClick}>
				<div className="accordion-item__title">{title}</div>
				<div className="accordion-item__toggle"></div>
			</button>
			{isOpen &&
				<div className="accordion-item__content">
					{content}
				</div>
			}
		</div>
	)
}

export default AccordionItem
