import axios from 'axios';
import React, { useState } from 'react'
import BackButton from '../UI/BackButton';

import SwitchField from '../UI/SwitchField';

function NotificationSettings({
	user,
	setUser,
	goBackToSettings,
}) {
	const [processing, setProcessing] = useState();

	const handleEmailChange = e => {
		updateUserPreference(e.target.checked, 'accepts_email_notifications', 'accepts_sms_notifications');
	}

	const handleSMSChange = e => {
		updateUserPreference(e.target.checked, 'accepts_sms_notifications', 'accepts_email_notifications');
	}
	
	const updateUserPreference = (value, key, altKey) => {
		if (processing) return false;

		if (!value && !user[altKey]) {
			alert('You must select at least one notification option.');
			return false;
		}

		setProcessing(true);

		const data = {};
		data[key] = value;

		axios.put('/api/user/notifications', data)
			.then(response => {
				setProcessing(false);
				setUser(response.data);
			})
			.catch(error => {
				alert(error.response.data.errors);
			})
			.finally(() => setProcessing(false));
	}

	return (
		<>
			<BackButton
				text="Back to settings"
				onClick={goBackToSettings}
			/>
			<section className="section">
				<div className="container">
					<h1>Notifications</h1>
					<p>Barbashop only sends notifications about appointments you have booked.</p>
					<div className="pt-2">
						<SwitchField
							name="text-notifications"
							label={
								<>
									<div className="h6 mb-1">Text messages</div>
									<div className="muted-text muted-text--light pr-2">Text messages are based on providers settings</div>
								</>
							}
							value="1"
							checked={user.accepts_sms_notifications}
							onChange={handleSMSChange}
						/>
					</div>
					<div className="pt-2">
						<SwitchField
							name="email-notifications"
							label={
								<>
									<div className="h6 mb-1">Emails</div>
									<div className="muted-text muted-text--light pr-2">Emails are based on providers settings</div>
								</>
							}
							value="1"
							checked={user.accepts_email_notifications}
							onChange={handleEmailChange}
						/>
					</div>
				</div>
			</section>
		</>
	)
}

export default NotificationSettings
