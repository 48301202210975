import React from 'react'
import CrossIcon from '../Icons/Cross'
import Button from '../UI/Button'
import TextField from '../UI/TextField'

function CategoryForm({
	onSubmit,
	onCancel,
	categoryName,
	setCategoryName,
	presetCategories,
}) {
	return (
		<form onSubmit={onSubmit}>
			<TextField
				name="name"
				label="Category Name"
				placeholder="Hair"
				required
				value={categoryName} 
				onChange={e => setCategoryName(e.target.value)}
			/>
			<div className="preset-categories">
				{presetCategories.map(c => {
					return (
						<button 
							type="button" 
							key={`preset_category_${c.id}`} 
							onClick={() => setCategoryName(c.name)}
							className="preset-categories__category"
						>
							{c.name}
						</button>
					)
				})}
			</div>
			<div className="d-flex justify-between items-center">
				<button className="icon-button icon-button--red" type="button" onClick={onCancel}>
					<CrossIcon className="icon-button__icon" />
				</button>
				<Button type="submit" disabled={!categoryName}>Save</Button>
			</div>
		</form>
	)
}

CategoryForm.defaultProps = {
	presetCategories: [],
};

export default CategoryForm
