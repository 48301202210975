import React from 'react'

function SearchIcon(props) {
	const {className, ...otherProps} = props;
	return (
		<svg className={`icon ${className || ''}`} width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
			<path fillRule="evenodd" clipRule="evenodd" d="M16.56 2.00002C11.3464 2.00002 7.11998 6.22646 7.11998 11.44C7.11998 14.0816 8.20395 16.4686 9.95339 18.1829C11.6568 19.8522 13.9876 20.88 16.56 20.88C21.7736 20.88 26 16.6536 26 11.44C26 6.22646 21.7736 2.00002 16.56 2.00002ZM5.51998 11.44C5.51998 5.3428 10.4628 0.400024 16.56 0.400024C22.6572 0.400024 27.6 5.3428 27.6 11.44C27.6 17.5372 22.6572 22.48 16.56 22.48C13.8348 22.48 11.3392 21.4918 9.4139 19.8555L1.76049 27.3708L0.639465 26.2292L8.27244 18.7339C6.55988 16.7894 5.51998 14.2355 5.51998 11.44Z" />
		</svg>
	)
}

export default SearchIcon